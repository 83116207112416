export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_INITIATE_GET_USER_DATA = 'AUTH_INITIATE_GET_USER_DATA';
export const AUTH_GET_USER_DATA = 'AUTH_GET_USER_DATA';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD'; 
export const REMIND_PASSWORD_FAIL = 'REMIND_PASSWORD_FAIL';
export const REMIND_PASSWORD_SUCCESS = 'REMIND_PASSWORD_SUCCESS';
export const REMIND_PASSWORD_TRIED = 'REMIND_PASSWORD_TRIED';
export const REMIND_PASSWORD_CLEAR_STATE = 'REMIND_PASSWORD_CLEAR_STATE';

export const SETTINGS_CHANGE_MENU = 'SETTINGS_CHANGE_MENU';
export const SETTINGS_SHOW_MESSAGE = 'SETTINGS_SHOW_MESSAGE';

export const REQUEST_INITIATE_GET_REQUESTS = 'REQUEST_INITIATE_GET_REQUESTS';
export const REQUEST_GET_REQUESTS = 'REQUEST_GET_REQUESTS';
export const REQUEST_INITIATE_SEND_REQUEST = 'REQUEST_INITIATE_SEND_REQUEST';
export const REQUEST_SEND_REQUEST_FAIL = 'REQUEST_SEND_REQUEST_FAIL';
export const REQUEST_SEND_REQUEST_SUCCESS = 'REQUEST_SEND_REQUEST_SUCCESS';
export const REQUEST_PAYOUT_REQUEST = 'REQUEST_PAYOUT_REQUEST';

export const MEMBER_INITIATE_GET_MEMBERS = 'MEMBER_INITIATE_GET_MEMBERS';
export const MEMBER_GET_MEMBERS = 'MEMBER_GET_MEMBERS';
export const MEMBER_INITIATE_GET_USER_DATA = 'MEMBER_INITIATE_GET_USER_DATA';
export const MEMBER_GET_USER_DATA = 'MEMBER_GET_USER_DATA';
export const MEMBER_EDIT_USER_DATA = 'MEMBER_EDIT_USER_DATA';
export const MEMBER_GET_BESEATED_PROFILE = 'MEMBER_GET_BESEATED_PROFILE';
export const MEMBER_EDIT_BESEATED_PROFILE = 'MEMBER_EDIT_BESEATED_PROFILE';

export const LOCATION_INITIATE_GET_LOCATIONS = 'LOCATION_INITIATE_GET_LOCATIONS';
export const LOCATION_GET_LOCATIONS_FAIL = 'LOCATION_GET_LOCATIONS_FAIL';
export const LOCATION_GET_LOCATIONS_SUCCESS = 'LOCATION_GET_LOCATIONS_SUCCESS';

export const LUXURY_INITIATE_GET_LUXURIES_FOR_CONCIERGE = 'LUXURY_INITIATE_GET_LUXURIES_FOR_CONCIERGE';
export const LUXURY_GET_LUXURIES_FOR_CONCIERGE_SUCCESS = 'LUXURY_GET_LUXURIES_FOR_CONCIERGE_SUCCESS';
export const LUXURY_INITIATE_GET_MORE_LUXURIES_FOR_CONCIERGE = 'LUXURY_INITIATE_GET_MORE_LUXURIES_FOR_CONCIERGE';
export const LUXURY_GET_MORE_LUXURIES_FOR_CONCIERGE = 'LUXURY_GET_MORE_LUXURIES_FOR_CONCIERGE';
export const LUXURY_INITIATE_GET_MAX_PARAMS = 'LUXURY_INITIATE_GET_MAX_PARAMS';
export const LUXURY_GET_MAX_PARAMS_SUCCESS = 'LUXURY_GET_MAX_PARAMS_SUCCESS';
export const LUXURY_CLEAR_LUXURIES_LIST = 'LUXURY_CLEAR_LUXURIES_LIST';
export const LUXURY_GET_ALL_VILLAS_NAMES_SUCCESS = 'LUXURY_GET_ALL_VILLAS_NAMES_SUCCESS';
export const LUXURY_INITIATE_GET_ALL_VILLA_NAMES = 'LUXURY_INITIATE_GET_ALL_VILLA_NAMES';

export const CONCIERGE_SET_LUXURY = 'CONCIERGE_SET_LUXURY';
export const CONCIERGE_SET_SELECTED_DATE = 'CONCIERGE_SET_SELECTED_DATE';

export const MANAGER_INITIATE_GET_MANAGERS = 'MANAGER_INITIATE_GET_MANAGERS';
export const MANAGER_GET_MANAGERS_SUCCESS = 'MANAGER_GET_MANAGERS_SUCCESS';

export const AMENITY_INITIATE_GET_AMENITIES = 'AMENITY_INITIATE_GET_AMENITIES';
export const AMENITY_GET_AMENITIES_SUCCESS = 'AMENITY_GET_AMENITIES_SUCCESS';

export const PAYMENT_INITIATE_GET_CARDS = 'PAYMENT_INITIATE_GET_CARDS';
export const PAYMENT_GET_CARDS = 'PAYMENT_GET_CARDS';
export const PAYMENT_INITIATE_GET_BANK_ACCOUNTS = 'PAYMENT_INITIATE_GET_BANK_ACCOUNTS';
export const PAYMENT_GET_BANK_ACCOUNTS = 'PAYMENT_GET_BANK_ACCOUNTS';
export const PAYMENT_GET_STRIPE_ACCOUNT_ID = 'PAYMENT_GET_STRIPE_ACCOUNT_ID';
export const PAYMENT_INITIATE_GET_STRIPE_CUSTOMER = 'PAYMENT_INITIATE_GET_STRIPE_CUSTOMER';
export const PAYMENT_GET_STRIPE_CUSTOMER = 'PAYMENT_GET_STRIPE_CUSTOMER';
export const PAYMENT_INITIATE_GET_BALANCES = 'PAYMENT_INITIATE_GET_BALANCES';
export const PAYMENT_GET_BALANCES_SUCCESS = 'PAYMENT_GET_BALANCES_SUCCESS';

export const NOTIFICATION_INITIATE_GET_NOTIFICATIONS = 'NOTIFICATION_INITIATE_GET_NOTIFICATIONS';
export const NOTIFICATION_GET_NOTIFICATIONS_SUCCESS = 'NOTIFICATION_GET_NOTIFICATIONS_SUCCESS';
export const NOTIFICATION_INITIAITE_GET_NUMBER_OF_NEW_NOTIFICATIONS = 'NOTIFICATION_INITIAITE_GET_NUMBER_OF_NEW_NOTIFICATIONS';
export const NOTIFICATION_GET_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS = 'NOTIFICATION_GET_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS';
export const NOTIFICATION_ADD_TOASTS = 'NOTIFICATION_ADD_TOASTS';
export const NOTIFICATION_CLEAR_TOASTS = 'NOTIFICATION_CLEAR_TOASTS';