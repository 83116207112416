import React from 'react';
import updateLabelUtils from '../../../utils/updateLabelUtils';

const NewClient = props => {
    return (
        <React.Fragment>
            <div className="form-group row">
                <div className="col-md-6 input-container">
                    <label id="anonymous-member-first-name-label" htmlFor="anonymous-member-first-tname">client first name</label>
                    <input
                        id="anonymous-member-first-name"
                        className="form-control text-left"
                        type="text"
                        placeholder="client first name"
                        name="anonymous_member_first_name"
                        required="1"
                        onChange={e => updateLabelUtils(e)} />
                </div>
                <div className="col-md-6 input-container">
                    <label id="anonymous-member-last-name-label" htmlFor="anonymous-member-last-name">client last name</label>
                    <input
                        id="anonymous-member-last-name"
                        className="form-control text-left"
                        type="text"
                        placeholder="client last name"
                        name="anonymous_member_last_name"
                        required="1"
                        onChange={e => updateLabelUtils(e)} />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 input-container">
                    <label id="anonymous-member-area-code-label" htmlFor="anonymous-member-area-code">area code</label>
                    <input id="anonymous-member-area-code" className="form-control"
                        type="text" placeholder="area code" name="anonymous_member_area_code" required="1"
                        onChange={e => updateLabelUtils(e)} />
                </div>

                <div className="col-md-4 input-container">
                    <label id="anonymous-member-phone-label" htmlFor="anonymous-member-phone">mobile number</label>
                    <input id="anonymous-member-phone" className="form-control"
                        type="text" placeholder="mobile number" name="anonymous_member_phone" required="1"
                        onChange={e => updateLabelUtils(e)} />
                </div>

                <div className="col-md-6 input-container">
                    <label id="anonymous-member-email-label" htmlFor="anonymous-member-email">email</label>
                    <input id="anonymous-member-email" className="form-control"
                        type="text" placeholder="email" name="anonymous_member_email" required="1"
                        onChange={e => updateLabelUtils(e)} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default NewClient;