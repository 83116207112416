import * as actionTypes from './actionTypes';

export const initiateGetMembers = () => ({
    type: actionTypes.MEMBER_INITIATE_GET_MEMBERS
});

export const getMembers = (members, totalAmount) => ({
    type: actionTypes.MEMBER_GET_MEMBERS,
    members,
    totalAmount
});

export const initiateGetUserData = (userId) => ({
    type: actionTypes.MEMBER_INITIATE_GET_USER_DATA,
    userId
});

export const getUserData = (userData) => ({
    type: actionTypes.MEMBER_GET_USER_DATA,
    userData
});

export const editUserData = (fullName, email, phone, areaCode) => ({
    type: actionTypes.MEMBER_EDIT_USER_DATA,
    fullName, 
    email, 
    phone, 
    areaCode
});

export const  getBeseatedProfile = () => ({
    type: actionTypes.MEMBER_GET_BESEATED_PROFILE
});

export const editBeseatedProfile = (beseatedProfile) => ({
    type: actionTypes.MEMBER_EDIT_BESEATED_PROFILE,
    beseatedProfile,
});
