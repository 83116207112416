import * as actionTypes from '../actions/actionTypes';

const initialState = {
    amenities: [],
    totalAmount: false
};

const getAmenitiesSuccess = (state, action) => ({
    ...state,
    amenities: action.amenities
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AMENITY_GET_AMENITIES_SUCCESS:
            return getAmenitiesSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;