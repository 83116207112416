import React from 'react';

const ReviewsTab = props => {
    return (
        <div className="row pt-4 pl-4 pr-4">
            {props.reviews.map((review, i) => {
                return (
                    <React.Fragment>
                        <div className="col-md-2">
                            <p className="gold-info">{review.value} <i className="fa fa-star"></i></p>
                        </div>
                        <div className="col-md-3">
                            <p className="gold-info">
                                {new Intl.DateTimeFormat('en-US', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    timeZone: 'Asia/Dubai'
                                }).format(review.date * 1000)}
                            </p>
                        </div>
                        <div className="col-md-7">
                            <p className="gold-info">{review.description}</p>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>);
}

export default ReviewsTab;