import * as actionTypes from './actionTypes';

export const setLuxuryForConcierge = (luxury) => ({
    type: actionTypes.CONCIERGE_SET_LUXURY,
    luxury
});

export const setSelectedDate = (date) => ({
    type: actionTypes.CONCIERGE_SET_SELECTED_DATE,
    date
});