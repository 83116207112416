const updateLabelUtils = (e) => {
    console.log(e);
    
    if(e.target) {
        e.target.value !== ""
        ? document.getElementById(e.target.id + "-label").classList.add('floating')
        : document.getElementById(e.target.id + "-label").classList.remove('floating');
    } else {
        !e.empty
        ? document.getElementById(e.elementType + "-label").classList.add('floating')
        : document.getElementById(e.elementType + "-label").classList.remove('floating');
    }
}

/*
const updateLabelForStripeEvent = (e) => {
    console.log(e);

    !e.empty
        ? document.getElementById(e.elementType + "-label").classList.add('floating')
        : document.getElementById(e.elementType + "-label").classList.remove('floating');
}*/

export default updateLabelUtils;