import React, { useEffect, useState, useRef } from 'react';
import InputRange from 'react-input-range';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withAlert } from 'react-alert';

import ConciergeSummary from '../conciergeSummary/conciergeSummary';
import ClientForm from '../clientForm/clientForm';
import isInTheDay from '../../utils/dateUtils';

import * as actions from '../../store/actions';

import usePreviousValue from '../../hooks/usePreviousValue';

const DURATION_TO_NAME_AND_SHOULD_SHOW_MAPPER = {
    Hours: {
        name: 'HOURLY',
        shouldShow: durationType => ['drivers', 'bodyguards', 'yachts'].includes(durationType),
    },
    Days: {
        name: 'DAILY',
        shouldShow: durationType => ['drivers', 'bodyguards', 'yachts', 'villas'].includes(durationType),
    },
    Weeks: {
        name: 'WEEKLY',
        shouldShow: durationType => ['bodyguards', 'yachts', 'villas'].includes(durationType),
    },
    Months: {
        name: 'MONTHLY',
        shouldShow: durationType => [].includes(durationType),
    },
    Transfers: {
        name: 'TRANSFERS',
        shouldShow: durationType => ['drivers'].includes(durationType),
    },
};

const DAY_NAMES = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const getDatalistDurationTypeOption = durationType => (<option key={durationType} datavalue={durationType}>{durationType}</option>);

const getCurrentAvailableDurationTypes = formType =>
    Object.entries(DURATION_TO_NAME_AND_SHOULD_SHOW_MAPPER).filter(([_, { shouldShow }]) => {
        return shouldShow(formType)
    }).map(([durationType, { name }]) => ({ durationType, name }))

const getDurationTypesDatalistOptions = formType => getCurrentAvailableDurationTypes(formType).map(({ durationType }) => getDatalistDurationTypeOption(durationType))

const getFormButtons = (onClick) => (formType, currentDurationType) =>
    getCurrentAvailableDurationTypes(formType).map(({ durationType, name }) => {
        return (
            <button
                key={durationType}
                className={currentDurationType === durationType ? "btn-so col-md-3" : "btn-nso col-md-3"}
                type="button"
                onClick={onClick.bind(null, durationType)}>
                {name}
            </button>
        )
    })

const getMinutesFromTimeString = timeString => {
    const [hours, minutes] = timeString.split(':');
    try {
        return Number.parseInt(hours) * 60 + Number.parseInt(minutes);
    } catch (e) {
        return 0;
    }
};

const isTimeInOpeningHours = (time, openingHours) => {
    if (isNaN(Number.parseInt(openingHours[0]))) {
        return false;
    }
    const [startOpeningHours, endOpeningHours] = openingHours.split('-');
    const selectedMinutes = time.startsWith('0') ? getMinutesFromTimeString(time) + 24 * 60 : getMinutesFromTimeString(time);
    const normalizeTimeString = timeString => {
        const normalizedString = timeString.includes(':') ? timeString : `${timeString}:00`;
        return normalizedString.trim();
    };
    const startOpeningMinutes = getMinutesFromTimeString(
        normalizeTimeString(startOpeningHours)
    );
    const endOpeningMinutes = getMinutesFromTimeString(normalizeTimeString(endOpeningHours));
    if (endOpeningMinutes < startOpeningMinutes) {
        return selectedMinutes >= startOpeningMinutes && selectedMinutes <= endOpeningMinutes + 24 * 60;
    }
    return selectedMinutes >= startOpeningMinutes && selectedMinutes <= endOpeningMinutes;
};

const scrollToElement = (parent, offset) => el => {
    parent.scrollTo({ top: el.offsetTop - offset, behavior: 'smooth' });
}

const getVillasDatalist = luxuries => {
    if (!luxuries.length === 0) {
        return <datalist id="luxuries-list" />
    }
    return (
        <datalist id="luxuries-list">
            {luxuries.map(l => <option key={l.id}>{l.name}</option>)}
            {/*luxuries.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(l => <option key={l.id}>{l.name}</option>)*/}
        </datalist>
    )
}

const ConciergeForm = props => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const members = useSelector(state => state.member.members);
    const managers = useSelector(state => state.manager.managers);
    const locations = useSelector(state => state.location.locations);
    const cards = useSelector(state => state.payment.cards);
    const amenities = useSelector(state => state.amenity.amenities);
    const luxury = useSelector(state => state.concierge.luxury);
    const maxPrice = useSelector(state => state.luxury.maxPrice);
    const maxCapacity = useSelector(state => state.luxury.maxCapacity);
    const selectedDate = useSelector(state => state.concierge.selectedDate);
    const luxuries = useSelector(state => state.luxury.luxuries);
    const concierge = useSelector(state => state.concierge);
    const villaNames = useSelector(state => state.luxury.villaNames);

    const durationSwitch = (props.type === "events" || props.type === "venues") ? 1 : "";
    const [duration, setDuration] = useState(durationSwitch);
    const [guestRangeValues, setGuestRangeValues] = useState({ min: 0, max: maxCapacity });
    const [priceRangeValues, setPriceRangeValues] = useState({ min: 0, max: maxPrice });
    const [amount, setAmount] = useState("");
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [firstRun, setFirstRun] = useState(true);
    const [percentageMarkup, setPercentageMarkup] = useState("");
    const [markup, setMarkup] = useState("");
    const [bookingPhase, setBookingPhase] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [coords, setCoords] = useState({ lat: "", lng: "" }); //nearbylocation
    const [startCoords, setStartCoords] = useState({ lat: "", lng: "" });
    const [endCoords, setEndCoords] = useState({ lat: "", lng: "" });
    const [advancedOptions, setAdvancedOptions] = useState(false);
    const [durationType, setDurationType] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [isSelectedTimeOutsideWorkingHours, setIsSelectedTimeOutsideWorkingHours] = useState(false);

    const locationInput = React.createRef();
    const durationTypeInput = React.createRef();
    const dateInput = React.createRef();
    const timeInput = React.createRef();
    const dateEndInput = React.createRef();
    const managerInput = React.createRef();
    const latInputRef = React.createRef();
    const lngInputRef = React.createRef();
    const minPriceInputRef = React.createRef();
    const maxPriceInputRef = React.createRef();
    const minCapacityInputRef = React.createRef();
    const maxCapacityInputRef = React.createRef();
    const findAndSelectLuxuryRef = React.createRef();
    const billSummaryRef = React.createRef();
    const clientFormRef = React.createRef();
    const durationTime = React.createRef();
    const currentDate = new Date();
    const luxuryNameRef = useRef(null);
    const scrollRef = useRef(null);
    const numberOfBedsRef = useRef(null);
    const numberOfBedroomsRef = useRef(null);
    const startLocationRef = useRef(null);
    const cardHiddenInputRef = useRef(null);

    const prevLuxury = usePreviousValue(luxury);

    const { type } = props;

    const scrollToRef = scrollToElement(scrollRef.current, 30);

    const fields = {
        drivers: ["meetupLocation", "dropoffLocation", "luxurySubType", "date", "time", "durationType", "duration", "priceRange"],
        events: ["nearByLocation", "manager", "luxurySubType", "amount", "date", "time", "priceRange"],
        bodyguards: ["meetupLocation", "luxurySubType", "amount", "date", "time", "durationType", "duration", "priceRange"],
        venues: ["nearByLocation", "manager", "managerSubType", "date", "time", "priceRange", "guestRange"],
        yachts: ["embarkingLocation", "luxurySubType", "luxuryBrand", "date", "time", "durationType", "duration", "priceRange", "numberOfGuests", "numberOfBeds"],
        villas: ["nearByLocation", "luxurySubType", "date", "dateEnd", "priceRange", "numberOfGuests", "numberOfBeds", "numberOfBedrooms", "guestRange"],
    }

    const updateVillasNames = (location) => {
        dispatch(actions.initiateGetAllVillasNames(location));
    }

    useEffect(() => {
        if (type === 'villas') {
            const location = locationInput && locationInput.current && locationInput.current.value;
            updateVillasNames(location);
        }
    }, [type]);

    useEffect(() => {
        countDuration();
    }, [durationType])

    useEffect(() => {
        if (luxury && type === 'villas' && (!prevLuxury || prevLuxury.duration_type !== luxury.duration_type)) {
            setDurationType(luxury.duration_type);
        }
        if (!luxuryNameRef || !luxuryNameRef.current) {
            return;
        }
        const { name, luxury_type_name } = luxury;
        if (prevLuxury && prevLuxury.name && !name) {
            luxuryNameRef.current.value = '';
            fetchLuxuries();
            return;
        }
        if (`${luxury_type_name}s` === type) {
            luxuryNameRef.current.value = name;
        }
    }, [luxury, type, prevLuxury]);

    const trySelectLuxury = luxuryName => {
        const luxury = luxuries.find(l => l.name.trim() === luxuryName.trim());
        if (luxury) {
            dispatch(actions.setLuxuryForConcierge(luxury));
            return;
        }
        dispatch(actions.setLuxuryForConcierge({}));
    }

    const trySelectLuxuryDebounced = _.debounce(trySelectLuxury, 300);

    useEffect(() => {
        if (!luxuryNameRef || !luxuryNameRef.current || !luxuryNameRef.current.value) {
            return;
        }
        if (!Object.keys(luxury).length) {
            trySelectLuxuryDebounced(luxuryNameRef.current.value);
        }
    }, [luxuries, luxury]);

    useEffect(() => {
        dispatch(actions.initiateGetMaxParams(props.type));
        dispatch(actions.initiateGetMembers());
        dispatch(actions.initiateGetLocations());
        dispatch(actions.setSelectedDate(null));
        dispatch(actions.initiateGetCards());
        (props.type === "events" || props.type === "venues" || props.type === "villas") && dispatch(actions.initiateGetManagers(props.type, locationInput.current.value));
        (props.type === "yachts" || props.type === "villas") && dispatch(actions.initiateGetAmenities(props.type));
        dispatch(actions.setLuxuryForConcierge({}));
        dispatch(actions.initiateGetLuxuriesForConcierge(
            props.type,
            locationInput.current.value,
            durationTypeInput.current.value,
            durationTime.current.value,
            { min: 0, max: 500000000000 },
            { min: 0, max: 500000000000 },
            "",
            [],
            coords
        ));

        let startAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById('startLocation'));
        window.google.maps.event.addListener(startAutocomplete, 'place_changed', function () {
            var place = startAutocomplete.getPlace();
            setStartCoords({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        });
        let endAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById('endLocation'));
        window.google.maps.event.addListener(endAutocomplete, 'place_changed', function () {
            var place = endAutocomplete.getPlace();
            setEndCoords({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        });
        let embarkAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById('embarkingLocation'));
        window.google.maps.event.addListener(embarkAutocomplete, 'place_changed', function () {
            var place = embarkAutocomplete.getPlace();
            setStartCoords({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        });
        let nearAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById('nearByLocation'));
        window.google.maps.event.addListener(nearAutocomplete, 'place_changed', function () {
            var place = nearAutocomplete.getPlace();
            setCoords({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        });
    }, []);

    useEffect(() => {
        setGuestRangeValues({ min: 0, max: maxCapacity });
        setPriceRangeValues({ min: 0, max: maxPrice });
    }, [maxPrice, maxCapacity]);

    useEffect(() => {
        !firstRun ? fetchLuxuries() : setFirstRun(false);
    }, [coords]);

    useEffect(() => {
        setIsSelectedTimeOutsideWorkingHours(false);
        if (
            type !== 'venues' ||
            !selectedTime ||
            !luxury ||
            !luxury.manager_info ||
            !selectedDate
        ) {
            return;
        }
        const { open_hours: openHours } = luxury.manager_info;
        if (
            !isTimeInOpeningHours(
                selectedTime,
                openHours[DAY_NAMES[new Date(selectedDate).getDay()]]
            )
        ) {
            setIsSelectedTimeOutsideWorkingHours(true);
        }
    }, [type, selectedTime, luxury, selectedDate])

    const fetchLuxuries = () => {
        const numberOfBeds = _.get(numberOfBedsRef, 'current.value');
        const numberOfBedrooms = _.get(numberOfBedroomsRef, 'current.value');
        const villaName = _.get(luxuryNameRef, 'current.value');
        dispatch(actions.initiateGetLuxuriesForConcierge(
            props.type,
            locationInput.current.value,
            durationTypeInput.current.value,
            durationTime.current.value,
            priceRangeValues,
            guestRangeValues,
            managerInput.current.value,
            selectedAmenities,
            { lat: latInputRef.current ? latInputRef.current.value : "", lng: lngInputRef.current ? lngInputRef.current.value : "" },
            numberOfBeds ? numberOfBeds : null,
            numberOfBedrooms ? numberOfBedrooms : null,
            villaName ? villaName : null,
        ));
    }

    const fetchLuxuriesWithRanges = () => {
        const numberOfBeds = _.get(numberOfBedsRef, 'current.value');
        const numberOfBedrooms = _.get(numberOfBedroomsRef, 'current.value');
        const villaName = _.get(luxuryNameRef, 'current.value');
        dispatch(actions.initiateGetLuxuriesForConcierge(
            props.type,
            locationInput.current.value,
            durationTypeInput.current.value,
            durationTime.current.value,
            { min: minPriceInputRef.current.value, max: maxPriceInputRef.current.value },
            { min: minCapacityInputRef.current.value, max: maxCapacityInputRef.current.value },
            managerInput.current.value,
            selectedAmenities,
            { lat: latInputRef.current.value, lng: lngInputRef.current.value },
            numberOfBeds ? numberOfBeds : null,
            numberOfBedrooms ? numberOfBedrooms : null,
            villaName ? villaName : null,
        ));
    }

    const fetchLuxuriesWithCapacity = capacity => {
        const numberOfBeds = _.get(numberOfBedsRef, 'current.value');
        const numberOfBedrooms = _.get(numberOfBedroomsRef, 'current.value');
        const villaName = _.get(luxuryNameRef, 'current.value');
        dispatch(actions.initiateGetLuxuriesForConcierge(
            props.type,
            locationInput.current.value,
            durationTypeInput.current.value,
            durationTime.current.value,
            priceRangeValues,
            capacity,
            managerInput.current.value,
            selectedAmenities,
            { lat: latInputRef.current.value, lng: lngInputRef.current.value },
            numberOfBeds ? numberOfBeds : null,
            numberOfBedrooms ? numberOfBedrooms : null,
            villaName ? villaName : null,
        ));
    }

    const fetchLuxuriesWithAmenities = amenitiesProps => {
        const numberOfBeds = _.get(numberOfBedsRef, 'current.value');
        const numberOfBedrooms = _.get(numberOfBedroomsRef, 'current.value');
        const villaName = _.get(luxuryNameRef, 'current.value');
        dispatch(actions.initiateGetLuxuriesForConcierge(
            props.type,
            locationInput.current.value,
            durationTypeInput.current.value,
            durationTime.current.value,
            priceRangeValues,
            guestRangeValues,
            managerInput.current.value,
            amenitiesProps,
            { lat: latInputRef.current.value, lng: lngInputRef.current.value },
            numberOfBeds ? numberOfBeds : null,
            numberOfBedrooms ? numberOfBedrooms : null,
            villaName ? villaName : null,
        ));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        if (!luxury.id) {
            props.alert.error("Pick luxury!");
            //dispatch(actions.addToasts([{message:"Pick luxury!", type:"error"}])); this resets all fields!
            return;
        }

        if (props.type === "villas") {
            const dateObj = new Date(data.get("date"));
            console.log(dateObj);
            data.delete('date');
            const time = timeInput && timeInput.current.value ? timeInput.current.value : '10:00';
            dateObj.setHours(...time.split(':'));
            data.append('date', dateObj.getTime() / 1000);
        } else {
            let datetime = data.get("date") + "T" + data.get("time");
            data.delete('date');
            data.delete('time');
            let dateObj = new Date(datetime);
            let timestamp = dateObj.getTime();
            timestamp += dateObj.getTimezoneOffset() * 60 * 1000;
            if (timestamp) data.append('date', timestamp / 1000);
        }

        if (!data.get("guest_count")) {
            data.delete("guest_count");
            data.append("guest_count", 1);
        }

        let user = data.get('member-name');
        if (user) {
            data.append('user_id', user.substr(user.indexOf("#") + 1, user.indexOf(".") - 1));
        } else {
            data.append('user_id', auth.userId);
        }
        data.delete('member-name');
        data.delete('end-date');
        if (['drivers', 'bodyguards'].includes(type) && startLocationRef && startLocationRef.current) {
            data.append('from_address', startLocationRef.current.value);
        }
        dispatch(actions.initiateSendRequest(data));
    }

    const countDuration = e => {
        if (dateInput.current.value && dateEndInput.current.value) {
            const dateStart = new Date(dateInput.current.value);
            const dateEnd = new Date(dateEndInput.current.value);
            const diffInTime = dateEnd.getTime() - dateStart.getTime();
            const diffInDays = diffInTime / (1000 * 3600 * 24);
            durationType === 'Weeks' ? setDuration(diffInDays / 7 < 0.8 ? 0 : Math.round(diffInDays / 7)) : setDuration(diffInDays);
        }
        const tmpDate = new Date(dateInput.current.value);
        dateInput.current.value && dispatch(actions.setSelectedDate(tmpDate.getTime()));
    }

    const amenityClick = id => {
        let tmpAmenities = selectedAmenities.includes(id) ? [...selectedAmenities.filter(e => e !== id)] : [...selectedAmenities, id];
        setSelectedAmenities(tmpAmenities);
        fetchLuxuriesWithAmenities(tmpAmenities);
    }

    const allAmenitiesTypeClick = type => {
        let allOfTypeWithoutAlreadySelected = [...amenities.filter(item => { return (!selectedAmenities.includes(item.id) && item.type === type) })].map(item => item.id);
        let tmpAmenities = allOfTypeWithoutAlreadySelected.length !== 0
            ? [...selectedAmenities, ...allOfTypeWithoutAlreadySelected]
            : [...selectedAmenities.filter(item => {
                const found = amenities.find(obj => obj.id === item);
                return found ? found.type !== type : false;
            })];
        setSelectedAmenities(tmpAmenities);
        fetchLuxuriesWithAmenities(tmpAmenities);
    }

    const arrayContainsArray = (superset, subset) => {
        if (0 === subset.length) {
            return false;
        }
        return subset.every(function (value) {
            return (superset.indexOf(value) >= 0);
        });
    }

    const changeCity = (e) => {
        if (['events', 'venues'].includes(type)) {
            dispatch(actions.initiateGetManagers(props.type, locationInput.current.value));
        }
        if (type === 'villas') {
            dispatch(actions.setLuxuryForConcierge({}));
            updateVillasNames(locationInput.current.value);
            if (!locationInput.current.value) {
                luxuryNameRef.current.value = '';
            }
        }
        fetchLuxuries();
        updateLabel(e);
    }

    const updateLabel = (e) => {
        e.target.value !== ""
            ? document.getElementById(e.target.id + "-label").classList.add('floating')
            : document.getElementById(e.target.id + "-label").classList.remove('floating');
    }

    const getFormButtonsWithClick = getFormButtons(durationType => {
        durationTypeInput.current.value = durationType;
        setDurationType(durationType);
        fetchLuxuries();
    });

    const onLuxuryNameChange = e => {
        updateLabel(e);
        fetchLuxuries();
    };

    const fetchLuxuriesDebounced = _.debounce(fetchLuxuries, 500);

    const onBedsNumberChange = e => {
        updateLabel(e);
        fetchLuxuriesDebounced();
    }

    const onBedroomsNumberChange = e => {
        updateLabel(e);
        fetchLuxuriesDebounced();
    }

    const onTimeChange = e => {
        setSelectedTime(e.target.value);
    }

    return (

        <div className="col-md-12 col-lg-6 concierge-form-container pl-5 pr-5" ref={scrollRef}>
            <form className="form-horizontal form-style-8 well center-block" onSubmit={handleSubmit}>
                <div className="form-group row d-none">
                    <input
                        className="form-control col-md-10 text-left"
                        type="number"
                        placeholder="luxury id"
                        name="luxury_id"
                        value={luxury.id ? luxury.id : 0}
                        onChange={e => { }} />
                </div>

                <section id="findLuxury" style={{ minHeight: '90vh' }} ref={findAndSelectLuxuryRef}>
                    <div className="row align-center">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-8">
                            <p className="text-uppercase title-available text-center">find and select luxury</p>
                        </div>
                        <div className="col-md-2">
                            <p className="text-uppercase text-right next-button" onClick={e => scrollToRef(billSummaryRef.current)}>next</p>
                        </div>
                    </div>

                    <div className="form-group row mb-5 client-type-buttons-row pt-3">
                        {getFormButtonsWithClick(type, durationType)}
                    </div>

                    <div className="form-group row">
                        <div className="col-md-12 input-container">
                            <label id="city-name-label" htmlFor="city-name">city</label>
                            <input
                                id="city-name"
                                className="form-control"
                                type="text"
                                list="locations-list"
                                placeholder="city"
                                onMouseDown={e => { e.target.value = ''; updateLabel(e); changeCity(e); }}
                                onChange={changeCity}
                                ref={locationInput} />
                        </div>

                        <datalist id="locations-list">
                            {locations.map((item, i) => (
                                <option key={i} datavalue={item.name} className="text-capitalize">{item.name}</option>
                            ))}
                        </datalist>
                    </div>

                    <div className={fields[props.type].find(e => e === "manager") ? "form-group row" : "form-group row d-none"}>
                        <div className="col-md-12 input-container">
                            <label id="manager-name-label" htmlFor="manager-name">{props.type.slice(0, props.type.length - 1) + " name"}</label>
                            <input
                                className="form-control"
                                type="text"
                                list="managers-list"
                                id="manager-name"
                                placeholder={props.type.slice(0, props.type.length - 1) + " name"}
                                onMouseDown={e => { e.target.value = ''; updateLabel(e); fetchLuxuries(); }}
                                onChange={e => { fetchLuxuries(e); updateLabel(e); }}
                                ref={managerInput}
                            />
                        </div>

                        <datalist id="managers-list">
                            {managers.filter(item => selectedDate ? isInTheDay(item.date, selectedDate) === true : true).map((item, i) => (
                                <option key={i} datavalue={item.id}>{item.name}</option>
                            ))}
                        </datalist>
                    </div>

                    <div className={fields[props.type].find(e => e === "managerSubType") ? "form-group row d-none" : "form-group row d-none"}>
                        <div className="col-md-12 input-container">
                            <label id="manager-type-label" htmlFor="manager-type">venue type</label>
                            <input id="manager-type" className="form-control"
                                type="text" required="" placeholder="venue type" onChange={updateLabel} />
                        </div>
                    </div>

                    {fields[props.type].find(e => e === "meetupLocation") ?
                        <div className="form-group row">
                            <div className="col-md-12 input-container">
                                <label id="startLocation-label" htmlFor="startLocation">{props.type === "bodyguards" ? "meet-up location" : "pick-up location"}</label>
                                <input name="from_address" id="startLocation"
                                    className="form-control" type="text" required=""
                                    placeholder={props.type === "bodyguards" ? "meet-up location" : "pick-up location"} required="1"
                                    ref={startLocationRef}
                                    onChange={updateLabel} />
                                <input name="from_lat" id="from_lat" className="form-control col-md-10" type="text" placeholder="from_lat" value={startCoords.lat} onChange={e => { }} hidden />
                                <input name="from_lng" id="from_lng" className="form-control col-md-10" type="text" placeholder="from_lng" value={startCoords.lng} onChange={e => { }} hidden />
                            </div>
                        </div> : null}


                    {fields[props.type].find(e => e === "dropoffLocation") ?
                        <div className="form-group row">
                            <div className="col-md-12 input-container">
                                <label id="endLocation-label" htmlFor="endLocation">drop-off location</label>
                                <input id="endLocation" className="form-control" name="dest_address"
                                    type="text" required="" placeholder="drop-off location" required="1"
                                    onChange={updateLabel} />
                                <input name="dest_lat" id="dest_lat" className="form-control col-md-10" type="text" placeholder="dest_lat" value={endCoords.lat} onChange={e => { }} hidden />
                                <input name="dest_lng" id="dest_lng" className="form-control col-md-10" type="text" placeholder="dest_lng" value={endCoords.lng} onChange={e => { }} hidden />
                            </div>
                        </div> : null}


                    <div className={fields[props.type].find(e => e === "luxuryBrand") ? "form-group row d-none" : "form-group row d-none"}>
                        <div className="col-md-12 input-container">
                            <label id="yachtBrand-label" htmlFor="yachtBrand">yacht brand</label>
                            <input id="yachtBrand" className="form-control" type="text"
                                required="" placeholder="yacht brand" onChange={updateLabel} />
                        </div>
                    </div>

                    <div className={fields[props.type].find(e => e === "amount") /*|| fields[props.type].find(e => e === "luxurySubType")*/ ? "form-group row" : "d-none"}>
                        <div className="col-md-6 input-container d-none">
                            <label id="luxurySubType-label" htmlFor="luxurySubType">{props.type.slice(0, props.type.length - 1) + " type"}</label>
                            <input className={fields[props.type].find(e => e === "luxurySubType") ? "form-control" : "d-none"}
                                type="text" id="luxurySubType" placeholder={props.type.slice(0, props.type.length - 1) + " type"}
                                onChange={updateLabel} />
                        </div>

                        {fields[props.type].find(e => e === "amount") ?
                            <React.Fragment>
                                <div className="col-md-6 input-container">
                                    <label id="amountNumber-label" htmlFor="amountNumber">{props.type === "bodyguards" ? "number of bodyguards" : "number of tickets"}</label>
                                    <input name="guest_count" className="form-control"
                                        type="text"
                                        id="amountNumber"
                                        placeholder={props.type === "bodyguards" ? "number of bodyguards" : "number of tickets"}
                                        value={amount}
                                        required="1"
                                        onChange={e => { setAmount(e.target.value); updateLabel(e); }} />
                                </div>
                            </React.Fragment> : null}

                    </div>

                    <div className={fields[props.type].find(e => e === "date") ? "form-group row" : "form-group row d-none"} style={{ paddingBottom: '0px' }}>
                        <div className="col-md-6 input-container" style={{ paddingBottom: '20px' }}>
                            <label id="start-date-label" htmlFor="start-date">{props.type === "villas" ? "check-in" : "date"} </label>
                            <input className="form-control" type="text" id="start-date" placeholder={props.type === "villas" ? "check-in" : "date"} name="date"
                                required={props.type !== "events" ? true : false}
                                min={currentDate.getFullYear() + "-" + ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + currentDate.getDate()).slice(-2)}
                                onFocus={e => { e.target.type = "date" }}
                                onBlur={e => { return e.target.value === "" ? e.target.type = "text" : null }}
                                ref={dateInput}
                                value={startDate}
                                onChange={e => { countDuration(e); updateLabel(e); setStartDate(e.target.value); }} />
                        </div>

                        {fields[props.type].find(e => e === "time") ?
                            <React.Fragment>
                                <div className="col-md-6 input-container" style={{ paddingBottom: '20px' }}>
                                    <label id="timeInput-label" htmlFor="timeInput">time</label>
                                    <input className="form-control" type="text" placeholder="time" name="time" required={props.type !== "events" ? true : false} id="timeInput"
                                        onFocus={e => { e.target.type = "time" }}
                                        onBlur={e => { onTimeChange(e); return e.target.value === "" ? e.target.type = "text" : null }}
                                        onChange={updateLabel} />
                                    {isSelectedTimeOutsideWorkingHours && <p>Time outside working hours</p>}
                                </div>
                            </React.Fragment> : null}

                        <div className={fields[props.type].find(e => e === "dateEnd") ? "col-md-6 input-container" : "d-none"}>
                            <label id="end-date-label" htmlFor="end-date">check-out</label>
                            <input className="form-control" type="text"
                                id="end-date" placeholder="check-out" name="end-date"
                                min={currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + ("0" + currentDate.getDate()).slice(-2)}
                                onFocus={e => { e.target.type = "date" }}
                                min={startDate ? startDate : currentDate.getFullYear() + "-" + ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" + currentDate.getDate()}
                                onBlur={e => { return e.target.value === "" ? e.target.type = "text" : null }}
                                ref={dateEndInput}
                                onChange={e => { countDuration(e); updateLabel(e); }}
                                required={props.type === "villas" ? true : false} />
                        </div>
                    </div>

                    <div className={props.type === "villas" ? "form-group row" : "d-none"}>
                        <React.Fragment>
                            <div className="col-md-6 input-container" style={{ paddingBottom: '20px' }}>
                                <label id="checkin-time-label" htmlFor="checkin-time">check-in time</label>
                                <input id='checkin-time' className="form-control" type="text" placeholder="check-in time"
                                    onFocus={e => { e.target.type = "time" }}
                                    onBlur={e => { return e.target.value === "" ? e.target.type = "text" : null }}
                                    ref={timeInput}
                                    onChange={updateLabel}
                                />
                            </div>
                        </React.Fragment>
                    </div>

                    <div className={props.type === "villas" ? "form-group row" : "d-none"}>
                        <div className="col-lg-6 input-container">
                            <label id="beds-label" htmlFor="beds">beds</label>
                            <input id="beds" ref={numberOfBedsRef} className="form-control" type="number" placeholder="beds" onChange={onBedsNumberChange} />
                        </div>

                        <div className="col-lg-6 input-container">
                            <label id="bedrooms-label" htmlFor="bedrooms">bedrooms</label>
                            <input id="bedrooms" ref={numberOfBedroomsRef} className="form-control" type="number" placeholder="bedrooms" onChange={onBedroomsNumberChange} />
                        </div>
                    </div>

                    {props.type === 'villas' && <div className="form-group row">
                        <div className="col-md-12 input-container">
                            <label id="luxury-name-label" htmlFor="luxury-name">villa name</label>
                            <input
                                className="form-control"
                                type="text"
                                list="luxuries-list"
                                id="luxury-name"
                                autoComplete="no"
                                placeholder="villa name"
                                ref={luxuryNameRef}
                                onChange={onLuxuryNameChange}
                            />
                        </div>

                        {getVillasDatalist(villaNames.length ? villaNames : luxuries)}
                    </div>}

                    <div className={fields[props.type].find(e => e === "duration") ? "form-group row" : "d-none"} style={{ paddingBottom: '0px' }}>
                        <div className="col-md-6 input-container" style={{ paddingBottom: '30px' }}>
                            <label id="durationInput-label" htmlFor="durationInput">duration type</label>
                            <input className="form-control" type="text" list="duration-type-list" placeholder="duration type" id="durationInput"
                                onMouseDown={(e) => { e.target.value = '' }}
                                ref={durationTypeInput}
                                value={durationType}
                                onChange={e => { fetchLuxuries(e); updateLabel(e); setDurationType(e.target.value); }} />
                        </div>

                        <datalist id="duration-type-list">
                            {getDurationTypesDatalistOptions(type)}
                        </datalist>

                        <div className="col-md-6 input-container">
                            <label id="durationTime-label" htmlFor="durationTime">duration</label>
                            <input name="duration_time" value={duration} id="durationTime" ref={durationTime}
                                required={props.type === "villas" ? false : true} className="form-control" type="number" placeholder="duration" onChange={e => { setDuration(e.target.value); fetchLuxuries(); updateLabel(e); }} />
                        </div>
                    </div>

                    {fields[props.type].find(e => e === "nearByLocation") && (props.type === 'venues' || props.type === 'events')
                        ? <div className="form-group row">
                            <div className="col-md-12 input-container">
                                <label id="nearByLocation-label" htmlFor="nearByLocation">nearby location</label>
                                <input name="near_by" id="nearByLocation"
                                    className="form-control" type="text" required="" placeholder="nearby location"
                                    onChange={changeCity} />
                            </div>
                            <input ref={latInputRef} name="lat" id="lat" className="form-control col-md-10" type="text" placeholder="lat" value={coords.lat} onChange={e => { }} hidden />
                            <input ref={lngInputRef} name="lng" id="lng" className="form-control col-md-10" type="text" placeholder="lng" value={coords.lng} onChange={e => { }} hidden />
                        </div> : null}

                    <div className={['yachts', 'villas'].includes(type) ? "row" : "d-none"}>
                        {!advancedOptions ?
                            <div className="col-md-12 text-right">
                                <span className="text-uppercase next-button" onClick={e => { setAdvancedOptions(true) }}>advanced</span>
                            </div> :
                            <div className="col-md-12 text-right">
                                <span className="text-uppercase next-button" onClick={e => { setAdvancedOptions(false) }}>show less</span>
                            </div>}
                    </div>

                    <div className={fields[props.type].find(e => e === "embarkingLocation") && advancedOptions ? "form-group row" : "d-none"}>
                        <div className="col-md-12 input-container">
                            <label id="embarkingLocation-label" htmlFor="embarkingLocation">embarking location</label>
                            <input name="from_address" id="embarkingLocation"
                                className="form-control" type="text" required="" placeholder="embarking location"
                                onChange={updateLabel} />
                            <input name="from_lat" id="from_lat" className="form-control col-md-10" type="text" placeholder="from_lat" value={startCoords.lat} onChange={e => { }} hidden />
                            <input name="from_lng" id="from_lng" className="form-control col-md-10" type="text" placeholder="from_lng" value={startCoords.lng} onChange={e => { }} hidden />
                        </div>
                    </div>

                    {fields[props.type].find(e => e === "nearByLocation") && props.type === 'villas'
                        ? <div className={advancedOptions ? "form-group row" : "d-none"}>
                            <div className="col-md-12 input-container">
                                <label id="nearByLocation-label" htmlFor="nearByLocation">nearby location</label>
                                <input name="near_by" id="nearByLocation"
                                    className="form-control" type="text" required="" placeholder="nearby location"
                                    onChange={changeCity} />
                            </div>
                            <input ref={latInputRef} name="lat" id="lat" className="form-control col-md-10" type="text" placeholder="lat" value={coords.lat} onChange={e => { }} hidden />
                            <input ref={lngInputRef} name="lng" id="lng" className="form-control col-md-10" type="text" placeholder="lng" value={coords.lng} onChange={e => { }} hidden />
                        </div> : null}

                    {(advancedOptions && (props.type === "yachts" || props.type === "villas")) || (props.type !== "villas" && props.type !== "yachts") ?
                        <React.Fragment>

                            <div className={fields[props.type].find(e => e === "guestRange") ? "form-group row pt-3 pb-3" : "form-group row d-none"}>
                                <div className="col-md-12 col-lg-12 pt-2">
                                    <InputRange
                                        maxValue={maxCapacity}
                                        minValue={0}
                                        value={guestRangeValues}
                                        onChange={e => setGuestRangeValues({ min: e.min, max: e.max })}
                                        onChangeComplete={fetchLuxuries}
                                        formatLabel={(value, type) => ""} />
                                </div>
                            </div>

                            <div className={fields[props.type].find(e => e === "guestRange") ? "form-group row pt-2" : "d-none"} style={{ paddingBottom: '0px' }}>
                                <div className="col-lg-6 col-md-6 input-container" style={{ paddingBottom: '30px' }}>
                                    <label id="minCapacity-label" htmlFor="minCapacity">min capacity</label>
                                    <input
                                        className="form-control text-left"
                                        type="number"
                                        placeholder="min capacity"
                                        id="minCapacity"
                                        value={guestRangeValues.min ? guestRangeValues.min : ""}
                                        max={maxCapacity}
                                        ref={minCapacityInputRef}
                                        onChange={e => { setGuestRangeValues({ min: e.target.value, max: guestRangeValues.max }); fetchLuxuriesWithRanges(); updateLabel(e); }} />
                                </div>

                                <div className="col-lg-6 col-md-5 input-container" style={{ paddingBottom: '30px' }}>
                                    <label id="maxCapacity-label" htmlFor="maxCapacity">max capacity</label>
                                    <input
                                        className="form-control text-left"
                                        type="number"
                                        placeholder="max capacity"
                                        id="maxCapacity"
                                        value={guestRangeValues.max !== maxCapacity ? guestRangeValues.max : ""}
                                        max={maxCapacity}
                                        ref={maxCapacityInputRef}
                                        onChange={e => { setGuestRangeValues({ max: e.target.value, min: guestRangeValues.min }); fetchLuxuriesWithRanges(); updateLabel(e); }} />
                                </div>
                            </div>

                            <div className={fields[props.type].find(e => e === "priceRange") ? "form-group row pt-3 pb-3" : "form-group row d-none"}>
                                <div className="col-md-12 col-lg-12 pt-2">
                                    <InputRange
                                        maxValue={maxPrice}
                                        minValue={0}
                                        value={priceRangeValues}
                                        onChange={e => setPriceRangeValues({ min: e.min, max: e.max })}
                                        onChangeComplete={fetchLuxuries}
                                        formatLabel={(value, type) => ""} />
                                </div>
                            </div>

                            <div className={fields[props.type].find(e => e === "priceRange") ? "form-group row pt-2" : "d-none"} style={{ paddingBottom: '0px' }}>
                                <div className="col-lg-6 input-container" style={{ paddingBottom: '30px' }}>
                                    <label id="minPrice-label" htmlFor="minPrice">min price</label>
                                    <input
                                        className="form-control text-left"
                                        type="number"
                                        placeholder="min price"
                                        id="minPrice"
                                        value={priceRangeValues.min ? priceRangeValues.min : ""}
                                        ref={minPriceInputRef}
                                        onChange={e => { setPriceRangeValues({ min: e.target.value, max: priceRangeValues.max }); fetchLuxuriesWithRanges(); updateLabel(e); }} />
                                </div>

                                <div className="col-lg-6 input-container">
                                    <label id="maxPrice-label" htmlFor="maxPrice">max price</label>
                                    <input
                                        className="form-control text-left"
                                        type="number"
                                        placeholder="max price"
                                        id="maxPrice"
                                        value={priceRangeValues.max !== maxPrice ? priceRangeValues.max : ""}
                                        ref={maxPriceInputRef}
                                        onChange={e => { setPriceRangeValues({ max: e.target.value, min: priceRangeValues.min }); fetchLuxuriesWithRanges(); updateLabel(e); }} />
                                </div>
                            </div>

                            <div className={props.type === "yachts" ? "form-group row" : "d-none"}>
                                <div className="col-lg-6 input-container">
                                    <label id="cruising-label" htmlFor="cruising">cruising</label>
                                    <input id="cruising" className="form-control" type="number" placeholder="cruising" onChange={e => { updateLabel(e); setGuestRangeValues({ min: Number(e.target.value), max: guestRangeValues.max }); fetchLuxuriesWithCapacity({ min: Number(e.target.value), max: guestRangeValues.max }); }} />
                                </div>

                                <div className="col-lg-6 input-container d-none">
                                    <label id="sleeps-label" htmlFor="sleeps">sleeps</label>
                                    <input id="sleeps" className="form-control" type="text" placeholder="sleeps" onChange={updateLabel} />
                                </div>
                            </div>

                            <div className={props.type === "yachts" ? "form-group row d-none" : "d-none"}>
                                <div className="col-lg-6 input-container">
                                    <label id="chef-label" htmlFor="chef">chef</label>
                                    <input id="chef" className="form-control" type="text" placeholder="chef" onChange={updateLabel} />
                                </div>

                                <div className="col-lg-6 input-container">
                                    <label id="catering-label" htmlFor="catering">catering</label>
                                    <input id="catering" className="form-control" type="text" placeholder="catering" onChange={updateLabel} />
                                </div>
                            </div>

                            {props.type === "villas" ?
                                <div className="form-group" style={{ paddingTop: '0px' }}>
                                    <div className="row pb-2" style={{ paddingTop: '0px' }}>
                                        <div className="col-lg-5 col-md-12 concierge-summary mb-2" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                            <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "amenities").map(item => item.id))
                                                ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                onClick={e => allAmenitiesTypeClick("amenities")}>Amenities</h6>

                                            {amenities.filter(elem => elem.type === 'amenities').map((item, i) => (
                                                <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                            ))}
                                        </div>
                                        <div className="col-lg-5 offset-lg-2 col-md-12 concierge-summary" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                            <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "facilities").map(item => item.id))
                                                ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                onClick={e => allAmenitiesTypeClick("facilities")}>Facilities</h6>

                                            {amenities.filter(elem => elem.type === 'facilities').map((item, i) => (
                                                <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="row pb-2">
                                        <div className="col-lg-5 col-md-12 concierge-summary mb-2" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                            <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "property type").map(item => item.id))
                                                ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                onClick={e => allAmenitiesTypeClick("property type")}>Property type</h6>

                                            {amenities.filter(elem => elem.type === 'property type').map((item, i) => (
                                                <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                            ))}
                                        </div>
                                        <div className="col-lg-5 offset-lg-2 col-md-12 concierge-summary" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                            <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "house rules").map(item => item.id))
                                                ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                onClick={e => allAmenitiesTypeClick("house rules")}>House rules</h6>

                                            {amenities.filter(elem => elem.type === 'house rules').map((item, i) => (
                                                <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div> : null
                            }

                            {
                                props.type === "yachts" ?
                                    <div className="form-group">
                                        <div className="row pt-2 pb-2">
                                            <div className="col-lg-5 col-md-12 concierge-summary mb-2" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                                <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "entertainment").map(item => item.id))
                                                    ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                    : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                    onClick={e => allAmenitiesTypeClick("entertainment")}>Entertainment</h6>

                                                {amenities.filter(elem => elem.type === 'entertainment').map((item, i) => (
                                                    <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                                ))}
                                            </div>
                                            <div className="col-lg-5 col-md-12 offset-lg-2 concierge-summary" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                                <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "saloon & cabin").map(item => item.id))
                                                    ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                    : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                    onClick={e => allAmenitiesTypeClick("saloon & cabin")}>Saloon & Cabin</h6>

                                                {amenities.filter(elem => elem.type === 'saloon & cabin').map((item, i) => (
                                                    <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="row pb-2">
                                            <div className="col-lg-5 col-md-12 concierge-summary mb-2" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                                <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "navigation & safety").map(item => item.id))
                                                    ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                    : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                    onClick={e => allAmenitiesTypeClick("navigation & safety")}>Navigation & Safety</h6>

                                                {amenities.filter(elem => elem.type === 'navigation & safety').map((item, i) => (
                                                    <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                                ))}
                                            </div>
                                            <div className="col-lg-5 col-md-12 offset-lg-2 concierge-summary" style={{ height: 300, overflow: 'auto', paddingTop: '0px' }}>
                                                <h6 className={arrayContainsArray(selectedAmenities, amenities.filter(item => item.type === "extras").map(item => item.id))
                                                    ? "pb-3 text-uppercase selected-amenity amenity-subgroup"
                                                    : "pb-3 text-uppercase not-selected-amenity amenity-subgroup"}
                                                    onClick={e => allAmenitiesTypeClick("extras")}>Extras</h6>

                                                {amenities.filter(elem => elem.type === 'extras').map((item, i) => (
                                                    <p key={i} className={selectedAmenities.includes(item.id) ? "selected-amenity" : "not-selected-amenity"} datavalue={item.id} onClick={e => amenityClick(item.id)}>{item.name}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div> : null
                            }
                        </React.Fragment> : null}
                </section>

                <section id="summary" style={{ minHeight: '90vh' }}>
                    <div className="row" ref={billSummaryRef}>
                        <div className="col-md-2">
                            <p className="text-uppercase text-left next-button" onClick={e => scrollToRef(findAndSelectLuxuryRef.current)}>back</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text-uppercase title-available text-center">bill summary</p>
                        </div>
                        <div className="col-md-2">
                            <p className="text-uppercase text-right next-button" onClick={e => { scrollToRef(clientFormRef.current); setBookingPhase(3); }}>next</p>
                        </div>
                    </div>

                    <div className="form-group row pt-5">
                        <div className="col-lg-6">
                            <p className="suggestion">Suggested Margin: 10%</p>
                            <div className="row input-container pr-3">
                                <label id="percentageMarkup-label" htmlFor="percentageMarkup">percentage markup</label>
                                <input
                                    className="form-control text-left"
                                    type="number"
                                    placeholder={markup ? "disabled when amount markup used" : "percentage markup"}
                                    id="percentageMarkup"
                                    name="percentage_markup"
                                    value={markup ? "" : percentageMarkup}
                                    onChange={e => { setPercentageMarkup(e.target.value); updateLabel(e); }}
                                    disabled={markup} />
                            </div>
                        </div>

                        <div className="col-lg-6 input-container">
                            <p className="suggestion">Suggested Margin: {luxury.price ? luxury.manager_info.currency : ""} {luxury.price ? luxury.price - luxury.beseated_price : "Pick luxury"}</p>
                            <div className="row input-container pl-2">
                                <label id="amountMarkup-label" htmlFor="amountMarkup">amount markup</label>
                                <input
                                    className="form-control text-left"
                                    type="number"
                                    placeholder="amount markup"
                                    id="amountMarkup"
                                    name="markup_value"
                                    value={markup}
                                    onChange={e => { setMarkup(e.target.value); updateLabel(e); }} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-12">
                            <div className="row input-container">
                                <label id="card-label" htmlFor="card">card</label>
                                <input
                                    id="card"
                                    name="card"
                                    className="form-control"
                                    type="text"
                                    list="cards-list"
                                    placeholder="card"
                                    onChange={e => { cardHiddenInputRef.current.value = document.getElementById('cards-list').querySelectorAll("[value='" + e.target.value + "']")[0].getAttribute('data-value') }}
                                    onMouseDown={e => { e.target.value = ''; updateLabel(e); }} />
                                <input ref={cardHiddenInputRef} id="card-hidden-input" name="payment_details" type="text" hidden />
                            </div>
                        </div>

                        <datalist id="cards-list">
                            {/*This option is for client's default card*/}
                            <option value={"Client default credit card (works only with Existing Client)"} data-value={""} className="text-capitalize"></option>

                            {cards.map((item, i) => (
                                <option key={i} value={item.card.brand + " - **** **** **** " + item.card.last4} data-value={item.id + "/;/;/" + item.customer} className="text-capitalize"></option>
                            ))}
                        </datalist>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-12 col-lg-12 card-box concierge-summary">
                            <ConciergeSummary
                                luxury={luxury}
                                duration={duration}
                                amount={amount}
                                selectedDate={selectedDate}
                                percentageMarkup={percentageMarkup}
                                markup={markup}
                            />
                        </div>
                    </div>
                </section>
                <section id="clientForm" style={{ minHeight: '90vh' }}>
                    <div ref={clientFormRef}>
                        <div className="row">
                            <div className="col-md-2">
                                <p className="text-uppercase text-left next-button" onClick={e => scrollToRef(billSummaryRef.current)}>back</p>
                            </div>
                            <div className="col-md-8">
                                <p className="title-available text-uppercase text-center">client information</p>
                            </div>
                            <div className="col-md-2">
                            </div>
                        </div>

                        <ClientForm />

                        <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button disabled={isSelectedTimeOutsideWorkingHours} className="btn btn-submit col-lg-2 col-md-3 col-sm-4">BESEATED</button>
                        </div>
                    </div>
                </section>
            </form >
        </div>
    );
}

export default withRouter(withAlert()(ConciergeForm));