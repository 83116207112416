import React from 'react';
import MemberBox from './memberBox/memberBox';

const MemberList = props => {
    return (
        <div className="row">
            {props.members.map((item, i) => (<MemberBox member={item} key={i} />))}
        </div>
    );
}

export default MemberList;