import * as actionTypes from './actionTypes';

export const initiateGetLuxuriesForConcierge = (
    managerType,
    location,
    durationType,
    durationTime,
    priceRangeValues,
    guestRangeValues,
    manager,
    selectedAmenities,
    coords,
    numberOfBeds,
    numberOfBedrooms,
    villaName,
) => ({
    type: actionTypes.LUXURY_INITIATE_GET_LUXURIES_FOR_CONCIERGE,
    managerType,
    location,
    durationType,
    durationTime,
    priceRangeValues,
    guestRangeValues,
    manager,
    selectedAmenities,
    coords,
    numberOfBeds,
    numberOfBedrooms,
    villaName,
});

export const getLuxuriesForConciergeSuccess = (luxuries, totalAmount, links) => ({
    type: actionTypes.LUXURY_GET_LUXURIES_FOR_CONCIERGE_SUCCESS,
    luxuries,
    totalAmount,
    links
});

export const initiateGetMoreLuxuriesForConcierge = () => ({
    type: actionTypes.LUXURY_INITIATE_GET_MORE_LUXURIES_FOR_CONCIERGE
});

export const getMoreLuxuriesForConcierge = (luxuries, links) => ({
    type: actionTypes.LUXURY_GET_MORE_LUXURIES_FOR_CONCIERGE,
    luxuries,
    links
});

export const clearLuxuriesList = () => ({
    type: actionTypes.LUXURY_CLEAR_LUXURIES_LIST
});

export const initiateGetMaxParams = managerType => ({
    type: actionTypes.LUXURY_INITIATE_GET_MAX_PARAMS,
    managerType
});

export const getMaxParamsSuccess = (maxPrice, maxCapacity) => ({
    type: actionTypes.LUXURY_GET_MAX_PARAMS_SUCCESS,
    maxPrice,
    maxCapacity
});

export const initiateGetAllVillasNames = (location) => ({
    type: actionTypes.LUXURY_INITIATE_GET_ALL_VILLA_NAMES,
    location,
});

export const getAllVillasNamesSuccess = (villaNames) => ({
    type: actionTypes.LUXURY_GET_ALL_VILLAS_NAMES_SUCCESS,
    villaNames,
});
