import React from 'react';
import { useSelector } from 'react-redux';
import updateLabelUtils from '../../../utils/updateLabelUtils';

const ExistingClient = props => {
    const members = useSelector(state => state.member.members);

    return (
        <div className="form-group row">
            <div className="col-md-12 input-container">
                <label id="member-name-label" htmlFor="member-name">client full name</label>
                <input
                    id="member-name"
                    className="form-control text-left"
                    type="text"
                    list="members-list"
                    placeholder="client full name"
                    name="member-name"
                    required="1"
                    autoComplete="off"
                    onMouseDown={(e) => { e.target.value = '' }}
                    onChange={e => updateLabelUtils(e)} />
            </div>

            <datalist id="members-list">
                {members.map((item, i) => (
                    <option key={i} datavalue={item.id}>{item.id === null ? 'Loading members...' : '#' + item.id + '. ' + item.first_name + ' ' + item.last_name}</option>
                ))}
            </datalist>
        </div>
    );
}

export default ExistingClient;