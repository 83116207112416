import * as actionTypes from './actionTypes';

export const initiateGetRequests = (filters, orderBy) => ({
    type: actionTypes.REQUEST_INITIATE_GET_REQUESTS,
    filters,
    orderBy
});

export const getRequests = (requests, totalAmount) => ({
    type: actionTypes.REQUEST_GET_REQUESTS,
    requests,
    totalAmount
});

export const initiateSendRequest = (request) => ({
    type: actionTypes.REQUEST_INITIATE_SEND_REQUEST,
    request
});

export const sendRequestSuccess = id => ({
    type: actionTypes.REQUEST_SEND_REQUEST_SUCCESS,
    id
});

export const payoutRequest = id => ({
    type: actionTypes.REQUEST_PAYOUT_REQUEST,
    id
});