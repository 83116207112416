import React from 'react';

import ConciergeLuxuriesList from "../../components/conciergeLuxuriesList/conciergeLuxuriesList";
import ConciergeForm from "../../components/conciergeForm/conciergeForm";

const Yachts = props => {
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                            <ConciergeForm type={"yachts"} />

                        <div className="col-md-12 col-lg-6 luxury-gallery">
                            <ConciergeLuxuriesList type={"yachts"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Yachts;