import * as actionTypes from './actionTypes';

export const initiateGetCards = () => ({
    type: actionTypes.PAYMENT_INITIATE_GET_CARDS
});

export const getCards = (cards) => ({
    type: actionTypes.PAYMENT_GET_CARDS,
    cards
});

export const initiateGetBankAccounts = () => ({
    type: actionTypes.PAYMENT_INITIATE_GET_BANK_ACCOUNTS
});

export const getBankAccounts = (bankAccounts) => ({
    type: actionTypes.PAYMENT_GET_BANK_ACCOUNTS,
    bankAccounts
});

export const getStripeAccountId = (stripeAccountId) => ({
    type: actionTypes.PAYMENT_GET_STRIPE_ACCOUNT_ID,
    stripeAccountId
});

export const initiateGetStripeCustomer = () => ({
    type: actionTypes.PAYMENT_INITIATE_GET_STRIPE_CUSTOMER
});

export const getStripeCustomer = (stripeCustomer) => ({
    type: actionTypes.PAYMENT_GET_STRIPE_CUSTOMER,
    stripeCustomer
});

export const initiateGetBalances = () => ({
    type: actionTypes.PAYMENT_INITIATE_GET_BALANCES
});

export const getBalancesSuccess = (balances) => ({
    type: actionTypes.PAYMENT_GET_BALANCES_SUCCESS,
    balances
});