import React from 'react';

const PricingPlan = props => {
    return (
        <div className="content-page">
            {/* Start content */}
            <div className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                            </div>
                        </div>
                    </div>
                    {/* end row */}

                    <div className="row">
                        <div className="col-lg-9 center-page">
                            <div className="row">
                                <div className="col-md-6 col-lg-3">
                                    <div className="card-box widget-box-four">
                                        <div className="pull-left">
                                            <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Money Earned">Plan</h4>
                                            <h3 className="m-b-0 m-t-20"> <span data-plugin="counterup">FREE</span></h3>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>{/* end col */}
                                <div className="col-md-6 col-lg-3">
                                    <div className="card-box widget-box-four">
                                        <div className="pull-left">
                                            <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Money Earned">Active Users</h4>
                                            <h3 className="m-b-0 m-t-20"> <span data-plugin="counterup">3</span></h3>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>{/* end col */}
                                <div className="col-md-6 col-lg-3">
                                    <div className="card-box widget-box-four">
                                        <div className="pull-left">
                                            <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Money Earned">Billing Cycle</h4>
                                            <h3 className="m-b-0 m-t-20"> <span data-plugin="counterup">Monthly</span></h3>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>{/* end col */}
                                <div className="col-md-6 col-lg-3">
                                    <div className="card-box widget-box-four">
                                        <div className="pull-left">
                                            <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Money Earned">Next payment</h4>
                                            <h3 className="m-b-0 m-t-20"> <span data-plugin="counterup">6 Jan 2020</span></h3>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>{/* end col */}
                            </div>

                            <div className="row m-t-50">

                                {/*Pricing Column*/}
                                <article className="pricing-column col-md-4">
                                    <div className="inner-box card-box">
                                        <div className="plan-header current-plan text-center">
                                            <h3 className="plan-title">Free</h3>
                                            <h2 className="plan-price">$0</h2>
                                            <div className="plan-duration">Per Month</div>
                                        </div>
                                        <ul className="plan-stats list-unstyled text-center">
                                            <li><b>Single</b> user</li>
                                            <li><b>100</b> requests</li>
                                            <li><b>One</b> city access</li>
                                            <li><b>5%</b> Maximum markup</li>
                                            <li><b>Email</b> support</li>
                                        </ul>

                                        <div className="text-center">
                                            <a href="#" className="btn btn-success w-lg btn-md w-md btn-bordred btn-rounded waves-effect waves-light">Current plan</a>
                                        </div>
                                    </div>
                                </article>


                                {/*Pricing Column*/}
                                <article className="pricing-column col-md-4">
                                    <div className="inner-box card-box">
                                        <div className="ribbon-pricing"><span>POPULAR</span></div>
                                        <div className="plan-header text-center">
                                            <h3 className="plan-title">PRO</h3>
                                            <h2 className="plan-price">$195</h2>
                                            <div className="plan-duration">Per Month</div>
                                        </div>
                                        <ul className="plan-stats list-unstyled text-center">
                                            <li><b>5</b> users</li>
                                            <li><b>1000</b> requests</li>
                                            <li><b>Five</b> cities access</li>
                                            <li><b>10%</b> Maximum markup</li>
                                            <li><b>Office hours support</b></li>
                                        </ul>

                                        <div className="text-center">
                                            <a href="#" className="btn btn-success btn-bordered w-lg btn-md w-md btn-bordred btn-rounded waves-effect waves-light">Sign Up</a>
                                        </div>
                                    </div>
                                </article>


                                {/*Pricing Column*/}
                                <article className="pricing-column col-md-4">
                                    <div className="inner-box card-box">
                                        <div className="plan-header text-center">
                                            <h3 className="plan-title">ENTERPRISE</h3>
                                            <h2 className="plan-price">$495</h2>
                                            <div className="plan-duration">Per Month</div>
                                        </div>
                                        <ul className="plan-stats list-unstyled text-center">
                                            <li><b>Unlimited</b> user</li>
                                            <li><b>Unlimited</b> requests</li>
                                            <li><b>All</b> cities access</li>
                                            <li><b>15%</b> Maximum markup</li>
                                            <li><b>24/7 dedicated support</b></li>
                                        </ul>

                                        <div className="text-center">
                                            <a href="#" className="btn btn-success btn-bordered w-lg btn-md w-md btn-bordred btn-rounded waves-effect waves-light">Sign Up</a>
                                        </div>
                                    </div>
                                </article>

                            </div>
                        </div>{/* end col */}
                    </div>
                    {/* end row */}

                </div> {/* container */}

            </div> {/* content */}</div>
    );
}

export default PricingPlan;