import React from 'react';

const Notification = props => {
    const NotificationMessage = (props) => {
        const GetMessage = () => {
            switch (props.notification.type) {
                case "request_canceled":
                    return "Your request for " + props.notification.user_request.luxury.name + " has been canceled";
                case "user_request_canceled":
                    return "You canceled request for " + props.notification.user_request.luxury.name;
                case "user_request_canceled_by_host":
                    return props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.first_name + " canceled request for " + props.notification.user_request.luxury.name;
                case "request_review":
                    return "Your request for " + props.notification.user_request.luxury.name + " was done. You can add review to it now.";
                case "request_confirmed":
                    return "Your request for " + props.notification.user_request.luxury.name + " was confirmed. Now you can pay for it.";
                case "user_attending_inv":
                    return props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.last_name + " invited you to join " + props.notification.user_request.luxury.name;
                case "user_attending_req":
                    return props.notification.user_notificating.first_name + " " + props.notification.user_notificating.last_name + " asked to join to " + props.notification.user_request.luxury.name;
                case "user_attending_left":
                    return props.notification.user_notificating.first_name + " " + props.notification.user_notificating.last_name + " declined your invitation for " + props.notification.user_request.luxury.name;
                case "user_attending_removed":
                    return "You were removed from " + props.notification.user_request.luxury.name + " by " + props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.last_name;
                case "user_attending_accept":
                    return props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.last_name + " accepted your invitation";
                case "user_attending_accept_join":
                    return props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.last_name + " accepted your join request to " + props.notification.user_request.luxury.name;
                case "request_concierge":
                    return "Your concierge booked " + props.notification.user_request.luxury.name + " on your behalf";
                case "new_share_payment":
                    return props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.last_name + " invited you to Share Payment for " + props.notification.user_request.luxury.name;
                case "paid_share_payment":
                    return props.notification.user_notificating.first_name + " " + props.notification.user_notificating.last_name + " accepted your invitation to share amount for " + props.notification.user_request.luxury.name;
                case "cancel_share_payment":
                    return props.notification.user_notificating.first_name + " " + props.notification.user_notificating.last_name + " canceled your invitation to share amount for " + props.notification.user_request.luxury.name;
                case "request_ambassador_canceled":
                    return "Request for " + props.notification.user_request.luxury.name + " made by you as a BeSeated Ambassador for " + props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.last_name + " has been cancelled";
                case "user_guest_removed":
                    return "Your request to join " + props.notification.user_request.client_info.first_name + " " + props.notification.user_request.client_info.last_name + " at " + props.notification.user_request.luxury.name +  " has been declined";
                default:
                    return props.notification.type;
            }
        }

        return (
            <p style={{ fontSize: '12px' }}><GetMessage /></p>
        );
    }

    return (
        <div className="dropdown-item notify-item valid-anchor">
            <p style={{ fontSize: '10px', marginBottom: '0px' }}>{new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Dubai' }).format(props.notification.date * 1000)}</p>
            <NotificationMessage notification={props.notification} />
        </div>
    );
}

export default Notification;