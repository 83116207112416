import * as actionTypes from '../actions/actionTypes';

const initialState = {
    luxuriesLinks: {},
    luxuries: [],
    totalAmount: false,
    maxPrice: 1,
    maxCapacity: 1,
    luxuriesLoaded: false,
    villaNames: [],
};

const changeLuxuriesRequested = (state, luxuriesLoaded) => ({
    ...state,
    luxuriesLoaded
});

const getLuxuriesForConciergeSuccess = (state, action) => ({
    ...state,
    luxuries: action.luxuries,
    totalAmount: action.totalAmount,
    luxuriesLinks: action.links,
    luxuriesLoaded: true
});

const getMoreLuxuriesForConcierge = (state, action) => ({
    ...state,
    luxuries: [...state.luxuries, ...action.luxuries],
    luxuriesLinks: action.links
});

const clearLuxuriesList = state => ({
    ...state,
    luxuries: [],
    totalAmount: false,
    luxuriesLinks: {}
});

const getMaxParamsSuccess = (state, action) => ({
    ...state,
    maxPrice: action.maxPrice,
    maxCapacity: action.maxCapacity
});

const setAllVillasNames = (state, {villaNames}) => ({
    ...state,
    villaNames,
})

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LUXURY_GET_LUXURIES_FOR_CONCIERGE_SUCCESS:
            return getLuxuriesForConciergeSuccess(state, action);
        case actionTypes.LUXURY_GET_MORE_LUXURIES_FOR_CONCIERGE:
            return getMoreLuxuriesForConcierge(state, action);
        case actionTypes.LUXURY_CLEAR_LUXURIES_LIST:
            return clearLuxuriesList(state);
        case actionTypes.LUXURY_GET_MAX_PARAMS_SUCCESS:
            return getMaxParamsSuccess(state, action);
        case actionTypes.LUXURY_INITIATE_GET_LUXURIES_FOR_CONCIERGE: {
            return changeLuxuriesRequested(state, false);
        }
        case actionTypes.LUXURY_GET_ALL_VILLAS_NAMES_SUCCESS: {
            return setAllVillasNames(state, action);
        }
        default:
            return state;
    }
};

export default reducer;
