import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './payment.css';
import { sha256 } from 'js-sha256';
import numDigits from '../../utils/numDigits';
import axios from 'axios';

const Payment = props => {
    const auth = useSelector(state => state.auth);
    const formData = {
        access_code: "lhMC6eFNwEPwdLSX1fPf",
        language: "en",
        merchant_identifier: "iQfdpAdI",
        merchant_reference: auth.userId + "-WEB-ADD-CARD-" + Math.random().toString(36).substring(7),
        service_command: "TOKENIZATION",
        return_url: 'http://165.22.112.158/users/clients/payfort/getToken'
        ///http://165.22.112.158/users/clients/payfort/getToken
        //http://localhost:3000/payment
    }

    const calculateSignature = () => {
        //posortowanie parametrow
        const ordered = {};
        Object.keys(formData).sort().forEach(function (key) {
            ordered[key] = formData[key];
        });

        //zapisanie parametrow i wartosci do string
        let paramString = "";
        Object.keys(ordered).forEach(function (key, index) {
            paramString += (key + "=" + ordered[key]);
        });

        return sha256('ferf34f3' + paramString + 'ferf34f3');
    }

    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [expiryDate, setExpiryDate] = useState("2105");
    const [signature, setSignature] = useState(calculateSignature);

    const updateExpiryDate = (e) => {
        if (e.target.className.includes("month")) {
            setMonth(e.target.value);
            setExpiryDate(year.toString().slice(-2) + "" + (numDigits(e.target.value) < 2 ? "0" + e.target.value : e.target.value));
        } else {
            setYear(e.target.value);
            setExpiryDate(e.target.value.toString().slice(-2) + "" + (numDigits(month) < 2 ? "0" + month : month));
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        const data = new FormData(e.target);
        data.delete("month");
        data.delete("year");
        axios({
            method: 'post',
            url: 'https://sbcheckout.PayFort.com/FortAPI/paymentPage',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(function (response) {
                //handle success
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }

    const updateLabel = (e) => {
        e.target.value !== ""
            ? document.getElementById(e.target.id + "-label").classList.add('floating')
            : document.getElementById(e.target.id + "-label").classList.remove('floating');
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6">
                            <div className="page-title-box">
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row row-padding">
                        <div className="col-md-12 col-lg-12 payment-top-box">
                            <p className="block-title">Enjoy a cashless experience and never worry about cash or your credit card.</p>
                            <div className="credit-card-box">
                                <p>Personal credit card<br />{auth.cardNumber}</p>
                            </div>
                            {/*
                            <div className="credit-card-box">
                                <p>Business credit card<br/>1234 xxxx xxxx 6789</p>
                            </div>*/}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <form className="form-horizontal form-style-8" method="post" action="https://sbcheckout.PayFort.com/FortAPI/paymentPage">
                                <p className="block-title">Add more credit cards by filling the following information</p>
                                <p className="form-group-title pb-2">Credit card details</p>
                                <div className="form-group row pl-2 input-container">
                                    <label id="holdersName-label" htmlFor="holdersName">holder's name</label>
                                    <input
                                        className="form-control col-md-12 col-lg-6 text-left"
                                        type="text"
                                        placeholder="holder's name"
                                        name="card_holder_name"
                                        required="1"
                                        id="holdersName"
                                        onChange={updateLabel} />
                                </div>

                                <div className="form-group row pl-2 input-container">
                                    <label id="cardNumber-label" htmlFor="cardNumber">credit card number</label>
                                    <input
                                        className="form-control col-md-12 col-lg-6 text-left"
                                        type="text"
                                        placeholder="credit card number"
                                        name="card_number"
                                        required="1"
                                        id="cardNumber"
                                        onChange={updateLabel} />
                                </div>

                                <p className="form-group-title pb-2">Expiration date</p>
                                {/*
                                <div className="form-group row pl-2">
                                    <input
                                        className="form-control col-md-5 col-lg-2 text-left month"
                                        type="number"
                                        placeholder="month"
                                        min="0"
                                        max="12"
                                        value={month}
                                        onChange={updateExpiryDate} />

                                    <input
                                        className="form-control col-md-5 offset-2 col-lg-2 text-left year"
                                        type="number"
                                        placeholder="year"
                                        min="2019"
                                        max="2100"
                                        value={year}
                                        onChange={updateExpiryDate} />
                                </div>*/}
                                <div className="form-group row pl-2">
                                    <div className="col-md-12 col-lg-6">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-5 input-container">
                                                <label id="expiryDate-label" htmlFor="expiryDate">expiry date</label>
                                                <input
                                                    className="form-control text-left col-md-5 col-lg-5"
                                                    type="text"
                                                    placeholder="expiry date"
                                                    name="expiry_date"
                                                    id="expiryDate"
                                                    onChange={updateLabel} />
                                            </div>

                                            <div className="col-md-12 col-lg-5 offset-lg-2 input-container">
                                                <label id="cvc-label" htmlFor="cvc">CVC</label>
                                                <input
                                                    className="form-control text-left col-md-5 col-lg-5"
                                                    type="text"
                                                    placeholder="CVC"
                                                    name="card_security_code"
                                                    required="1"
                                                    id="cvc"
                                                    onChange={updateLabel} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <p className="col-md-5 col-lg-5">(two digits of year + 2 of month ex: 05.2021 -> 2105)</p>
                                            <p className="col-md-5 col-lg-5 offset-2">(3 digit code on back of card)</p>
                                        </div>
                                    </div>
                                </div>

                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="expiry date"
                                    name="expiry_date"
                                    value={expiryDate}
                                    onChange={e => { }} hidden />

                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="access_code"
                                    name="access_code"
                                    value={formData.access_code}
                                    onChange={e => { }} hidden />

                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="lang"
                                    name="language"
                                    value={formData.language}
                                    onChange={e => { }} hidden />

                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="CVC"
                                    name="merchant_identifier"
                                    value={formData.merchant_identifier}
                                    onChange={e => { }} hidden />

                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="CVC"
                                    name="merchant_reference"
                                    value={formData.merchant_reference}
                                    onChange={e => { }} hidden />

                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="CVC"
                                    name="service_command"
                                    value={formData.service_command}
                                    onChange={e => { }} hidden />

                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="CVC"
                                    name="signature"
                                    value={signature}
                                    onChange={e => { }} hidden />


                                <input
                                    className="form-control col-md-5 col-lg-2 text-left"
                                    type="text"
                                    placeholder="redirect"
                                    name="return_url"
                                    value={formData.return_url}
                                    onChange={e => { }} hidden />
                                {/*
                                <div className="form-group row pl-2">
                                    <p className="pr-5 business-card-label">Business credit card</p>
                                    <span className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </span>
                                </div>
                                */}

                                <div className="row pl-2">
                                    <div className="col-md-12 col-lg-6 text-center">
                                        <button className="btn btn-submit">SUBMIT</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Payment;