/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import './request.css';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import moment from 'moment';

import * as actions from '../../store/actions';

Modal.setAppElement('#root');

class Request extends Component {
    constructor(props) {
        super(props);
        this.state = {
            request: {
                date: 0,
                is_paid: '',
                is_commision_paid: '',
                booking_date: 0,
                client_info: [],
                luxury: {
                    manager_info: [],
                    price: 0
                },
                extras: [],
                users_attending: [],
                final_price: 0,
                total_price: 0,
                commision: {},
                not_registered_client: {}
            },
            paying: [],
            requestCanceled: false,
            requestConfirmed: false,
            requestFinished: false,
            modalIsOpen: false,
            modalAction: "cancel"
        };
        this.fetchApi = this.fetchApi.bind(this);
        this.cancelRequest = this.cancelRequest.bind(this);
        this.confirmRequest = this.confirmRequest.bind(this);
        this.payRequest = this.payRequest.bind(this);
    }

    componentDidMount() {
        this.fetchApi();

        var loadScript = function (src) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementsByTagName('body')[0].appendChild(tag);
        }
        loadScript('../plugins/custombox/js/custombox.min.js');
        loadScript('../plugins/custombox/js/legacy.min.js');
    }

    fetchApi() {
        let id = this.props.requestId;
        if (!id) {
            id = this.props.match.params.requestId;
        }

        Promise.all([
            fetch(process.env.REACT_APP_API_URL + '/requests/details/' + id,
                {
                    headers: {
                        'Accept': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    },
                    method: "GET"
                }),
            fetch(process.env.REACT_APP_API_URL + '/userRequestPayingUsers/' + id + '/list',
                {
                    headers: {
                        'Accept': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    },
                    method: "GET"
                })
        ]).then(allResponses => {
            allResponses[0].json().then(json => this.setState({
                request: json
            }));

            allResponses[1].json().then(json => this.setState({
                paying: json._embedded.list
            }));
        });
    };

    cancelRequest(requestId) {
        fetch(process.env.REACT_APP_API_URL + '/requests/' + this.state.request.id + '/cancel',
            {
                headers: {
                    'Accept': 'application/json',
                    'auth-token': localStorage.getItem("token")
                },
                method: "POST"
            }).then(response => response.json())
            .then((response) => {
                let request = Object.assign({}, this.state.request);
                request.status = 'canceled';
                this.setState({ request });
                this.setState({ requestCanceled: response.success });
                this.props.dispatch(actions.addToasts([{message:"Request has beeen canceled", type:"success"}]));
            }).catch(function (error) { console.log(error); });
        this.closeModal();
    }

    confirmRequest() {
        fetch(process.env.REACT_APP_API_URL + '/requests/' + this.state.request.id + '/userConfirm',
            {
                headers: {
                    'Accept': 'application/json',
                    'auth-token': localStorage.getItem("token")
                },
                method: "POST"
            }).then(response => response.json())
            .then((response) => {
                let request = Object.assign({}, this.state.request);
                request.status = 'upcoming';
                this.setState({ request });
                this.setState({ requestConfirmed: response.success });
                this.props.dispatch(actions.addToasts([{message:"Request has beeen confirmed", type:"success"}]));
            }).catch(function (error) { console.log(error); });

        this.closeModal();
    }

    payRequest() {
        fetch(process.env.REACT_APP_API_URL + '/requests/' + this.state.request.id + '/pay',
            {
                headers: {
                    'Accept': 'application/json',
                    'auth-token': localStorage.getItem("token")
                },
                method: "POST"
            }).then(response => response.json())
            .then((response) => {
                let request = Object.assign({}, this.state.request);
                request.status = 'reviewing';
                this.setState({ request });
                this.setState({ requestFinished: response.success });
                this.props.dispatch(actions.addToasts([{message:"Request has been paid", type:"success"}]));
            }).catch(function (error) { console.log(error); });
        this.closeModal();
    }

    openModal = (actionType) => { this.setState({ modalIsOpen: true, modalAction: actionType }); }
    closeModal = () => { this.setState({ modalIsOpen: false }); }
    afterCloseModal = () => { }

    render() {
        const modalStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'black',
                width: '50%',
                minWidth: '400px',
                textAlign: 'center',
                fontSize: '30px',
                opacity: '1'
            },
            overlay: {
                zIndex: '99999',
                backgroundColor: 'rgba(3, 3, 3, 0.75)'
            }
        };

        var noneDisplay = {
            display: 'none'
        };

        var display = {
        };

        let preWrap = {
            whiteSpace: 'pre-wrap'
        };

        function getStatusBars(status) {
            switch (status) {
                case 'pending':
                    return '../assets/images/requests/pendingStatus.png';
                case 'confirmed':
                    return '../assets/images/requests/confirmedStatus.png';
                case 'upcoming':
                    return '../assets/images/requests/upcomingStatus.png';
                case 'payment':
                    return '../assets/images/requests/paymentStatus.png';
                case 'reviewing':
                    return '../assets/images/requests/reviewingStatus.png';
                case 'finished':
                    return '../assets/images/requests/finishedStatus.png';
                case 'canceled':
                    return '../assets/images/requests/canceledStatus.png';
                default:
                    return status;
            }
        }

        const luxuryGallery = (src) => ({
            backgroundImage: 'url(' + getStatusBars(src) + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            width: 400,
            height: 20
        });

        const {duration_type, luxury_type_name} = this.state.request.luxury;
        const durationType = luxury_type_name === 'villa' && duration_type.toLowerCase() === 'days' ? 'Nights' : duration_type;

        return (
            <section>
                <div className="content-page">
                    {/* Start content */}
                    <div className="content">
                        <div className="container-fluid">

                            <div className="row pt-2">
                                <div className="col-6">
                                    <div className="page-title-box">
                                        {/*<h4 className="page-title float-left">Request</h4>
                                    <div className="clearfix"></div>*/}
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    {/*this.props.auth.userId === this.state.request.client_info.id ?*/
                                        <div className="page-title-box">
                                            <button type='submit' style={this.state.request.status === 'pending' || this.state.request.status === 'upcoming' || this.state.request.status === 'confirmed' ? display : noneDisplay} className='btn btn-danger ml-1' onClick={e => this.openModal("cancel")}>Cancel</button>
                                            <button type='submit' style={this.state.request.status === 'confirmed' ? display : noneDisplay} className='btn btn-success ml-1' onClick={e => this.openModal("confirm")}>Confirm</button>
                                            <button type='submit' style={this.state.request.status === 'payment' ? display : noneDisplay} className='btn btn-success ml-1' onClick={e => this.openModal("pay")}>Pay</button>
                                        </div>/* : <div className="page-title-box"></div>*/}

                                    <Modal
                                        isOpen={this.state.modalIsOpen}
                                        onAfterClose={this.afterCloseModal}
                                        onRequestClose={this.closeModal}
                                        style={modalStyles}
                                        contentLabel="Are you sure?"
                                    >
                                        <p>Are you sure you want to {this.state.modalAction}?</p>
                                        {this.state.modalAction === "cancel" ? <button type='submit' className='btn btn-warning' onClick={this.cancelRequest.bind(this, this.props.requestId)}>Yes</button> : null}
                                        {this.state.modalAction === "confirm" ? <button type='submit' className='btn btn-warning' onClick={this.confirmRequest.bind(this, this.props.requestId)}>Confirm</button> : null}
                                        {this.state.modalAction === "pay" ? <button type='submit' className='btn btn-warning' onClick={this.payRequest.bind(this, this.props.requestId)}>Pay</button> : null}
                                        <button type='submit' className='btn btn-danger ml-2' onClick={this.closeModal}>Dismiss</button>
                                    </Modal>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <div className="card-box widget-box-four">
                                        {this.state.request.luxury.luxury_type_name === 'villa' ?
                                            <div className="pull-left">
                                                <h3 className="m-0">
                                                    <span data-plugin="counterup">
                                                        Check-in Date: {moment(this.state.request.date * 1000).local().format('MMM, D, YYYY, h:mm A')}                                                        
                                                    </span>
                                                </h3>
                                                <h3 className="m-b-0" style={{ paddingBottom: "2px" }}>
                                                    <span data-plugin="counterup">
                                                        Check-out Date: {moment(this.state.request.date * 1000 
                                                            + (this.state.request.luxury.duration_type === "Days" 
                                                                    ? 86400 * 1000 *this.state.request.duration_time 
                                                                    : 604800* 1000 *this.state.request.duration_time))
                                                                .local().format('MMM, D, YYYY, h:mm A')}
                                                    </span>
                                                </h3>
                                            </div> :
                                            <div className="pull-left">
                                                <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Request Date">{this.state.request.luxury.luxury_type_name === 'villa' ? 'Check-in Date' : 'Request Date'}</h4>
                                                <h3 className="m-b-0 m-t-20">
                                                    <span data-plugin="counterup">
                                                        {new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Dubai' }).format(this.state.request.date * 1000)}
                                                    </span>
                                                </h3>
                                            </div>}
                                        <div className="clearfix"></div>
                                    </div>
                                </div>{/* end col */}

                                <div className="col-md-6 col-lg-3">
                                    <div className="card-box widget-box-four">
                                        <div id="dashboard-2" className="widget-box-four-chart"></div>
                                        <div className="pull-left">
                                            <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Total Price">Total Price</h4>
                                            <h3 className="m-b-0 m-t-20"><span data-plugin="counterup">
                                                <NumberFormat value={this.state.request.total_price} displayType={'text'} thousandSeparator={true} prefix={this.state.request.luxury.manager_info.currency + ' '} />
                                            </span></h3>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>{/* end col */}

                                <div className="col-md-6 col-lg-3">
                                    <div className="card-box widget-box-four">
                                        <div id="dashboard-2" className="widget-box-four-chart"></div>
                                        <div className="pull-left">
                                            <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Total Price">Final Price</h4>
                                            <h3 className="m-b-0 m-t-20"><span data-plugin="counterup">
                                                <NumberFormat value={this.state.request.final_price ? this.state.request.final_price : 0} displayType={'text'} thousandSeparator={true} prefix={this.state.request.luxury.manager_info.currency + ' '} />
                                            </span></h3>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>{/* end col */}
                            </div>
                            {/* end row */}

                            <div className="row">
                                <div className="col-md-12 col-lg-5 pull-left text-left">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card-box">
                                                <img src={this.state.request.luxury.photo_url} alt="" width="100%" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card-box">
                                                <h3 className="text-center">Extra Luxuries</h3>
                                                <div className="table-responsive pb-4">
                                                    <table className="table-beseated table-hover m-0 tickets-list table-actions-bar dt-responsive nowrap" cellSpacing="0" width="100%" id="datatable">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    ID
                                                </th>
                                                                <th>Name</th>
                                                                <th>Capacity</th>
                                                                <th>Price</th>
                                                                <th className="hidden-sm">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.request.extras.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><b>#{item.id}</b></td>
                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.name}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.capacity}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.price.toLocaleString()}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <div className="dropdown pull-center">
                                                                            <button className="dropdown-toggle card-drop arrow-none valid-anchor" data-toggle="dropdown" aria-expanded="false">
                                                                                <h3 className="m-0 text-muted"><i className="mdi mdi-dots-horizontal"></i></h3>
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                                                                                <button className="dropdown-item valid-anchor">To be specified</button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card-box">
                                                <h3 className="text-center">Attending</h3>
                                                <div className="table-responsive pb-4">
                                                    <table className="table-beseated table-hover m-0 tickets-list table-actions-bar dt-responsive nowrap" cellSpacing="0" width="100%" id="datatable">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    ID
                                                </th>
                                                                <th>Name</th>
                                                                <th>Status</th>
                                                                <th className="hidden-sm">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.request.users_attending.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><b>#</b></td>
                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.first_name} {item.last_name}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.invite_status}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <div className="dropdown pull-center">
                                                                            <button href="#" className="dropdown-toggle card-drop arrow-none valid-anchor" data-toggle="dropdown" aria-expanded="false">
                                                                                <h3 className="m-0 text-muted"><i className="mdi mdi-dots-horizontal"></i></h3>
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                                                                                <button className="dropdown-item valid-anchor">To be specified</button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card-box">
                                                <h3 className="text-center">Paying</h3>
                                                <div className="table-responsive pb-4">
                                                    <table className="table-beseated table-hover m-0 tickets-list table-actions-bar dt-responsive nowrap" cellSpacing="0" width="100%" id="datatable">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    ID
                                                </th>
                                                                <th>Name</th>
                                                                <th>Price</th>
                                                                <th>Paid</th>
                                                                <th className="hidden-sm">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.paying.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><b>#{item.user.id}</b></td>
                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.user.first_name} {item.user.last_name}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.cost}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <h5 className="m-b-0 m-t-0 font-600">{item.is_paid.toString()}</h5>
                                                                    </td>

                                                                    <td>
                                                                        <div className="dropdown pull-center">
                                                                            <button className="dropdown-toggle card-drop arrow-none valid-anchor" data-toggle="dropdown" aria-expanded="false">
                                                                                <h3 className="m-0 text-muted"><i className="mdi mdi-dots-horizontal"></i></h3>
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                                                                                <button className="dropdown-item valid-anchor">To be specified</button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12 col-lg-7">
                                    <div className="row">
                                        {this.state.request.not_registered_client ?
                                            <div className="card-box col-lg-12 p-4">
                                                <h4 className="header-title mt-0 m-b-15">Client Information</h4>
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="text-muted font-16"><i className="fa fa-user"></i> <span className="m-l-15"><strong>{this.state.request.not_registered_client.first_name} {this.state.request.not_registered_client.last_name}</strong></span></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="text-muted font-16"><i className="fa fa-envelope-o"></i> <span className="m-l-15"><strong>{this.state.request.not_registered_client.email}</strong></span></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="text-muted font-16"><i className="fa fa-phone"></i> <span className="m-l-15"><strong>{this.state.request.not_registered_client.phone}</strong></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="card-box col-lg-12 p-4">
                                                <h4 className="header-title mt-0 m-b-15">Client Information</h4>
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Link to={'/members/' + this.state.request.client_info.id}>
                                                                <p className="text-muted font-16"><i className="fa fa-user"></i> <span className="m-l-15"><strong>{this.state.request.client_info.first_name} {this.state.request.client_info.last_name}</strong></span></p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="text-muted font-16"><i className="fa fa-envelope-o"></i> <span className="m-l-15"><strong>{this.state.request.client_info.email}</strong></span></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="text-muted font-16"><i className="fa fa-phone"></i> <span className="m-l-15"><strong>{this.state.request.client_info.phone}</strong></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                    </div>

                                    <div className="row">
                                        <div className="card-box col-lg-12 p-4">
                                            <div className="row">
                                                <div className="col-md-12 mb-3" style={luxuryGallery(this.state.request.status)}>
                                                    {/*<p className="text-muted font-16">Status: </p>*/}
                                                </div>
                                            </div>
                                            {/* end row */}
                                            <h4 className="header-title pb-4">Request {this.state.request.id} Information</h4>
                                            <div className="panel-body">
                                                <form className="form-horizontal">
                                                    <div className="form-group form-group-sm row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Manager: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.luxury.manager_info.name}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Luxury: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.luxury.name}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Paid in cash: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.is_paid.toString()}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Ambassador is paid: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.is_commision_paid.toString()}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Luxury BeSeated Price: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.luxury.beseated_price}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Markup amount: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                <p className="text-muted font-16"><strong>{this.state.request.commision.commision_value} {this.state.request.luxury.manager_info.currency}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Markup %: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.commision.commision_percent}%</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Deposit %: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.deposit}%</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Discount %: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.discount}%</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Luxury amount: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.count}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className={this.state.request.luxury.luxury_type_name === "ticket" ? "form-group row no-padding d-none" : "form-group row no-padding"}>
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Duration: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.duration_time} {durationType}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Request code: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.code}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Booking date: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{moment(this.state.request.booking_date * 1000).local().format('MMM, D, YYYY, h:mm A')}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <p className={this.state.request.luxury.luxury_type_name === 'car' ? "text-muted font-16 col-sm-5 col-md-3" : "d-none"}>Pick-up location: </p>
                                                                <p className={this.state.request.luxury.luxury_type_name === 'bodyguard' ? "text-muted font-16 col-sm-5 col-md-3" : "d-none"}>Meet-up location: </p>
                                                                <p className={this.state.request.luxury.luxury_type_name === 'yacht' ? "text-muted font-16 col-sm-5 col-md-3" : "d-none"}>Embarking location: </p>
                                                                <p className={['villa', 'table'].includes(this.state.request.luxury.luxury_type_name)  ? "text-muted font-16 col-sm-5 col-md-3" : "d-none"}>Address: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.address}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className={this.state.request.luxury.luxury_type_name === 'car' ? "form-group row no-padding" : "d-none"}>
                                                                <p className="text-muted font-16 col-sm-5 col-md-3">Drop-off location: </p>
                                                                <div className="col-sm-7 col-md-9">
                                                                    <p className="text-muted font-16"><strong>{this.state.request.dest_address}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <div className="col-md-12">
                                                                    <h4 className="header-title mt-0 m-b-15">Notes</h4>
                                                                    <p className="text-muted font-16" style={preWrap}><strong>{this.state.request.notes}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row no-padding">
                                                                <div className="col-md-12">
                                                                    <h4 className="header-title mt-0 m-b-15">Manager's notes</h4>
                                                                    {this.state.request && this.state.request.confirmation_date &&
                                                                        <p className="text-muted font-16" style={preWrap}><strong>Confirmation Date: {moment(this.state.request.confirmation_date * 1000).local().format('MMM, D, YYYY, h:mm A')}</strong></p>}
                                                                    {this.state.request && this.state.request.finalization_date &&
                                                                        <p className="text-muted font-16" style={preWrap}><strong>Finalization Date: {moment(this.state.request.finalization_date * 1000).local().format('MMM, D, YYYY, h:mm A')}</strong></p>}
                                                                    <p className="text-muted font-16" style={preWrap}><strong>{this.state.request.manager_notes}</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end col */}
                            </div>
                            {/* end row */}
                        </div>
                        {/* container */}
                    </div>
                    {/* content */}

                </div>
            </section >
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth }
}

export default connect(mapStateToProps)(Request);