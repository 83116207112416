import * as actionTypes from '../actions/actionTypes';

const initialState = {
    managers: [],
    totalAmount: false
};

const getManagersSuccess = (state, action) => ({
    ...state,
    managers: action.managers,
    totalAmount: action.totalAmount
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MANAGER_GET_MANAGERS_SUCCESS:
            return getManagersSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;