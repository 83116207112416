import { push } from 'react-router-redux';
import { put, select } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions';

export function* paymentGetCardsSaga(action) {
    const state = yield select();
    
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/users/cards', {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })
        yield put(actions.getCards(res.data.data));
    } catch (err) {
        put(actions.addToasts([{message:"Failed to get credit cards", type:"error"}]));
    }

}

export function* paymentGetBankAccountsSaga(action) {
    const state = yield select();
    
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/users/accounts', {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })
        yield put(actions.getBankAccounts(res.data.data));
        if(res.data.data) yield put(actions.getStripeAccountId(res.data.data[0].account));
    } catch (err) {
        put(actions.addToasts([{message:"Failed to get bank accounts", type:"error"}]));
    }

}

export function* paymentGetStripeCustomerSaga(action) {
    const state = yield select();
    
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/users/stripe/getStripeCustomer', {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })
        yield put(actions.getStripeCustomer(res.data));
    } catch (err) {
        put(actions.addToasts([{message:"Failed to get stripe info", type:"error"}]));
    }

}

export function* paymentGetBalancesSaga(action) {
    const state = yield select();
    
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/ambassadors/getCurrenciesBalances', {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })
        yield put(actions.getBalancesSuccess(res.data._embedded.list));
    } catch (err) {
        put(actions.addToasts([{message:"Failed to get balances", type:"error"}]));
    }

}