import React from 'react';

const PricingTab = props => {
    //deposit is_autoconfirm
    const VenuePricing = () => {
        return (
            <div className="row pt-4 pl-4 pr-4">
                <div className="col-lg-12" style={{ paddingBottom: '15px' }}>
                    <table className="text-center" cellSpacing="0" width="100%" id="datatable">
                        <thead>
                            <tr>
                                <th>Sunday</th>
                                <th>Monday</th>
                                <th>Tuesday</th>
                                <th>Wednesday</th>
                                <th>Thursday</th>
                                <th>Friday</th>
                                <th>Saturday</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>{props.luxury.manager_info.currency} {props.luxury.price_details.day7.toLocaleString()}</td>
                                <td>{props.luxury.manager_info.currency} {props.luxury.price_details.day1.toLocaleString()}</td>
                                <td>{props.luxury.manager_info.currency} {props.luxury.price_details.day2.toLocaleString()}</td>
                                <td>{props.luxury.manager_info.currency} {props.luxury.price_details.day3.toLocaleString()}</td>
                                <td>{props.luxury.manager_info.currency} {props.luxury.price_details.day4.toLocaleString()}</td>
                                <td>{props.luxury.manager_info.currency} {props.luxury.price_details.day5.toLocaleString()}</td>
                                <td>{props.luxury.manager_info.currency} {props.luxury.price_details.day6.toLocaleString()}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>);
    }

    const GeneralPricing = () => {
        return (
            <div className="row pt-4 pl-4 pr-4">
                <div className="col-lg-6" style={{ paddingBottom: '15px' }}>
                    <p className={props.luxury.deposit ? "red-info" : "d-none"}>Deposit: {props.luxury.deposit ? props.luxury.deposit : "0"}%</p>
                    <p className={props.luxury.is_auto ? "red-info" : "d-none"}>Automatic confirm: {props.luxury.is_auto ? "Yes" : "No"}</p>
                </div>

                <div className="col-lg-12" style={{ paddingBottom: '15px' }}>
                    <table className="text-center" cellSpacing="0" width="100%" id="datatable">
                        <thead>
                            <tr>
                                <th className="red-info">Market</th>
                                <th className="gold-info">BeSeated</th>
                                <th className="blue-info">Margin</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="red-info">{props.luxury.manager_info.currency} {props.luxury.price.toLocaleString()}</td>

                                <td className="gold-info">{props.luxury.manager_info.currency} {props.luxury.beseated_price.toLocaleString()}</td>

                                <td className="blue-info">{props.luxury.manager_info.currency} {(props.luxury.price - props.luxury.beseated_price).toLocaleString()}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>);
    }

    return (props.luxury.luxury_type_name === "table" ? <VenuePricing /> : <GeneralPricing />);
}

export default PricingTab;