import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NotificationsDropdown from '../notificationsDropdown/notificationsDropdown';

import * as actions from '../../store/actions';

const DEFAULT_PHOTO_URL = '/assets/images/user_default_photo.png';

const TopBar = (props) => {
    const inputRef = useRef(null);
    const [searchPhrase, setSearchPhrase] = useState("");
    const auth = useSelector(state => state.auth);
    const enlargedMenu = useSelector(state => state.settings.enlargedMenu);
    const newNotifications = useSelector(state => state.notification.numberOfNew);
    const dispatch = useDispatch();
    const [photoURL, setPhotoURL] = useState(auth.photoUrl);
    const [isDropdownShown, setIsDropdownShown] = useState("");

    /*
    useEffect(() => {
        let queryParams = new URLSearchParams(props.location.search);
        queryParams.get("searchPhrase") ? setSearchPhrase(queryParams.get("searchPhrase")) : setSearchPhrase("");
    }, [props.location.search]);*/

    const handleSearch = (event) => {
        event.preventDefault();
        props.history.push({
            pathname: '/search',
            search: '?searchPhrase=' + inputRef.current.value
        })
    };

    const showNotificationsClick = () => {
        dispatch(actions.initiateGetNumberOfNewNotifications());
        dispatch(actions.initiateGetNotifications());
        isDropdownShown === "" ? setIsDropdownShown("show") : setIsDropdownShown("");
    }

    return (
        <div className="topbar">
            <nav className="navbar-custom">
                <ul className="list-inline float-right mb-0">
                    <li className="list-inline-item dropdown notification-list">
                        <button className="nav-link dropdown-toggle arrow-none waves-light waves-effect valid-anchor pt-2"
                            onClick={showNotificationsClick}>
                            <i className="fa fa-bell noti-icon"></i>
                            <span className="badge badge-pink noti-icon-badge">{newNotifications}</span>
                        </button>

                        <NotificationsDropdown isDropdownShown={isDropdownShown}/>
                    </li>

                    <li className="list-inline-item dropdown notification-list">
                        <button className="nav-link dropdown-toggle waves-effect waves-light nav-user valid-anchor" data-toggle="dropdown" href="#"
                            aria-haspopup="false" aria-expanded="false">
                            <img onError={_ => setPhotoURL(DEFAULT_PHOTO_URL)} src={photoURL} alt="user" className="rounded-circle pb-1" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right profile-dropdown " aria-labelledby="Preview">
                            <div className="dropdown-item noti-title">
                                <h5 className="text-overflow"><small>Welcome {auth.username}</small> </h5>
                            </div>

                            <Link to={"/profile"} className="dropdown-item notify-item"><i className="mdi mdi-account-circle"></i><span>Profile</span></Link>

                            <button className="dropdown-item notify-item valid-anchor" onClick={e => dispatch(actions.authLogout())}>
                                <i className="mdi mdi-power"></i> <span>Logout</span>
                            </button>

                        </div>
                    </li>

                </ul>

                <ul className="list-inline menu-left mb-0">
                    <li className="float-left">
                        <button className="button-menu-mobile open-left waves-light waves-effect" onClick={e => {
                            document.body.classList.contains('enlarged') ? document.body.classList.remove('enlarged') : document.body.classList.add('enlarged');
                        }}>
                            <i className="dripicons-menu"></i>
                        </button>
                    </li>
                    <li className="hide-phone app-search">
                        <form role="search" className="d-none" onSubmit={handleSearch}>
                            <input ref={inputRef} type="text" placeholder="Search..." value={searchPhrase} onChange={event => setSearchPhrase(event.target.value)} className="form-control" />
                            <i className="fa fa-search" onClick={handleSearch}></i>
                        </form>
                    </li>
                </ul>

            </nav>

        </div>
    );
}

export default withRouter(TopBar);