import React, { useState } from "react";
import "./login.css";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../store/actions";

const renderError = (errorCode, errorText) => {
  if (errorCode !== "NOT_AMBASSADOR") {
    return <p>{errorText}</p>;
  }
  return (
    <>
      <p>
        It seems that your email is not registered as an ambassador. Apply here:{" "}
        <a
          href="https://www.beseated.app/copy-of-partners-application-form"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.beseated.app/copy-of-partners-application-form
        </a>
      </p>
    </>
  );
};

const Login = props => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [isForgotPassword, setIsForgotPassword] = useState(
    auth.remindPasswordTried && !auth.remindPasswordSuccess
  );
  const [isMailSent, setIsMailSent] = useState(auth.remindPasswordSuccess);
  const { remindPasswordError } = auth;
  const { errorCode, errorText } = remindPasswordError || {};
  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    dispatch(actions.auth(data.get("email"), data.get("password")));
  };

  const handleForgotPasswordSubmit = event => {
    dispatch(actions.remindPasswordClearState());
    event.preventDefault();
    const data = new FormData(event.target);
    dispatch(actions.authForgotPassword(data.get("email")));
  };

  const updateLabel = e => {
    e.target.value !== ""
      ? document
        .getElementById(e.target.id + "-label")
        .classList.add("floating")
      : document
        .getElementById(e.target.id + "-label")
        .classList.remove("floating");
  };

  const MailSent = () => {
    return isMailSent ? (
      <h4 className="text-center">Email has been sent!</h4>
    ) : null;
  };

  const BadCredentials = () => {
    return auth.error && !isForgotPassword && !isMailSent ? (
      <h4 className="text-center">Wrong username or password</h4>
    ) : null;
  };

  const ForgotPasswordForm = () => {
    return (
      <form
        className="form-horizontal form-style-8"
        onSubmit={handleForgotPasswordSubmit}
      >
        <div className="form-group row">
          <div className="col-12">
            <label
              className="label-on-gray"
              id="email-label"
              htmlFor="emailaddress"
            >
              Email address
            </label>
            <input
              className="form-control"
              name="email"
              type="email"
              id="email"
              required=""
              placeholder="email address"
              onChange={updateLabel}
            />
          </div>
        </div>
        {remindPasswordError && (
          <div className="row remind-password-error">
            {renderError(errorCode, errorText)}
          </div>
        )}
        <div
          className="row client-type-buttons-row"
          style={{ marginTop: remindPasswordError ? "2rem" : "4.5rem" }}
        >
          <div className="col-lg-2"></div>
          <button
            className="btn btn-danger col-md-4 col-lg-3 ml-1"
            type="submit"
            onClick={e => setIsForgotPassword(false)}
          >
            BACK
          </button>
          <button
            className="btn btn-submit col-md-4 col-lg-3 ml-1"
            type="submit"
          >
            SEND MAGIC LINK
          </button>
        </div>
      </form>
    );
  };

  const LoginForm = () => {
    return (
      <form className="form-horizontal form-style-8" onSubmit={handleSubmit}>
        <div className="form-group row">
          <div className="col-12">
            <label
              className="label-on-gray floating"
              id="email-label"
              htmlFor="emailaddress"
            >
              Email address
            </label>
            <input
              className="form-control"
              name="email"
              type="email"
              id="email"
              required=""
              placeholder="email address"
              onChange={updateLabel}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 text-right">
            <span
              className="valid-anchor forgot-password"
              onClick={e => {
                setIsForgotPassword(true);
                setIsMailSent(false);
              }}
            >
              Forgot your password?
            </span>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label
              className="label-on-gray floating"
              id="password-label"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="form-control"
              type="password"
              required=""
              name="password"
              id="password"
              placeholder="password"
              autoComplete="off"
              onChange={updateLabel}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 text-center">
            <span className="terms-and-conditions">
              <small>By signing in, I accept the </small>
              <a
                className="terms-and-conditions"
                href="https://www.beseated.app/terms"
                target="_blank"
              >
                <strong className="valid-anchor">Terms and Conditions</strong>
              </a>
            </span>
          </div>
        </div>

        <div className="row client-type-buttons-row">
          <div className="col-lg-2"></div>
          <button className="btn btn-submit col-md-4 col-lg-3" type="submit">
            LOGIN
          </button>
        </div>
      </form>
    );
  };

  return (
    <section className="bg-accpunt-pages">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="wrapper-page">
              <div className="account-pages">
                <div className="account-box">
                  <div className="account-logo-box">
                    <h2 className="text-uppercase text-center">
                      <a href="index.html" className="text-success">
                        <span>
                          <img
                            src="/assets/images/logo_dark.png"
                            alt=""
                            height="180"
                          />
                        </span>
                      </a>
                    </h2>
                  </div>

                  <div
                    className="account-content"
                    style={{ minHeight: "550px" }}
                  >
                    {isForgotPassword ? <ForgotPasswordForm /> : <LoginForm />}
                    <BadCredentials />
                    <MailSent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
