import React from 'react';
import StarRatings from 'react-star-ratings';

const OpeningHoursTab = props => {
    return (
        <div className="row pt-4 pl-4 pr-4">
            <div className="col-3">
                <p className="">Monday</p>
                <p className="">Tuesday</p>
                <p className="">Wednesday</p>
                <p className="">Thursday</p>
                <p className="">Friday</p>
                <p className="">Saturday</p>
                <p className="">Sunday</p>
            </div>

            <div className="col-9">
                <p>{props.luxury.manager_info.open_hours.monday}</p>
                <p>{props.luxury.manager_info.open_hours.tuesday}</p>
                <p>{props.luxury.manager_info.open_hours.wednesday}</p>
                <p>{props.luxury.manager_info.open_hours.thursday}</p>
                <p>{props.luxury.manager_info.open_hours.friday}</p>
                <p>{props.luxury.manager_info.open_hours.saturday}</p>
                <p>{props.luxury.manager_info.open_hours.sunday}</p>
            </div>
        </div>
    );
}

export default OpeningHoursTab;