/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React, { Component } from 'react';
import './member.css';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import PersonalInformation from '../../components/personalInformation/personalInformation';
import RequestList from '../../components/requestList/requestList';


class Member extends Component {
    constructor(props) {
        super(props);
        this.fetchApi = this.fetchApi.bind(this);
        this.state = {
            username: "John",
            photoUrl: process.env.REACT_APP_API_URL + '/bundles/app/images/user_default_photo.png',
            description: 'We dont have anything like this in system',
            fullName: '',
            areaCode: '',
            phone: '',
            registration_date: 0,
            email: '',
            city: '?',
            hasCreditCard: false,
            loginType: 'signup',
            moneyEarned: 0,
            numberOfTransactions: 0,
            numberOfBookings: 0,
            loyaltyCoins: 0,
            bookings: [],
            is_active: '',
            profilingChart: [],
            user: {}
        };

        this.fetchApi = this.fetchApi.bind(this);
    }

    componentDidMount() {
        let id = this.props.memberId;
        if (!id && this.props.match) {
            id = this.props.match.params.memberId;
        }

        if (id) this.fetchApi(id);
    }

    fetchApi(id) {
        Promise.all([
            fetch(process.env.REACT_APP_API_URL + '/users/getUserData/' + id,
                {
                    headers: {
                        'Accept': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    },
                    method: "GET"
                }),
            fetch(process.env.REACT_APP_API_URL + '/concierge/requests/' + id + '/list',
                {
                    headers: {
                        'Accept': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    },
                    method: "GET"
                }),
            fetch(process.env.REACT_APP_API_URL + '/users/getBeseatedProfile/' + id,
                {
                    headers: {
                        'Accept': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    },
                    method: "GET"
                })
        ]).then(allResponses => {
            allResponses[0].json().then(json => this.setState({
                photoUrl: json.photo_url,
                fullName: json.first_name + ' ' + json.last_name,
                user: json,
                email: json.email,
                phone: json.phone,
                areaCode: json.area_code,
                moneyEarned: json.money_earned,
                numberOfTransactions: json.transactions,
                numberOfBookings: json.bookings,
                loyaltyCoins: json.loyalty_coins,
                hasCard: json.has_card,
                medium: json.medium,
                registration_date: json.registration_date,
                is_active: json.is_active
            }));

            allResponses[1].json().then(json => {
                let tmpBookings = [...json._embedded.list];
                for (let i = 0; i < json._embedded.list.length; i++) {
                    tmpBookings[i].client = json._embedded.list[i].client_info;
                }
                this.setState({ bookings: tmpBookings });
            });
            allResponses[2].json().then(json => this.setState({ profilingChart: json, profiliChartMaxValue: Math.max.apply(Math, json.map(function (o) { return o.value; })) }));
        });
    };

    render() {
        var style = {
            height: 300
        };

        var style2 = {
            height: 350,
            overflowWrap: 'break-word'
        };

        var imageStyle = {
            height: 130
        };

        var cardboxStyle = {
            height: 400,
            overflowY: 'scroll',
            maxHeight: 400,
            padding: '0px 20px'
        };

        function InfoIcons(props) {
            if (props.medium === 'fb') {
                if (props.hasCard === true) {
                    return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                        <li className="list-inline-item">
                            <i className="fa fa-credit-card-alt"></i>
                        </li>
                        <li className="list-inline-item">
                            <i className="fa fa-facebook-official"></i>
                        </li>
                    </ul>;
                }

                return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                    <li className="list-inline-item">
                        <i className="fa fa-facebook-official"></i>
                    </li>
                </ul>;
            } else if (props.medium === 'gmail') {
                if (props.hasCard === true) {
                    return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                        <li className="list-inline-item">
                            <i className="fa fa-credit-card-alt"></i>
                        </li>
                        <li className="list-inline-item">
                            <i className="fa fa-google-plus-official"></i>
                        </li>
                    </ul>;
                }

                return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                    <li className="list-inline-item">
                        <i className="fa fa-google-plus-official"></i>
                    </li>
                </ul>;
            }

            if (props.hasCard === true) {
                return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                    <li className="list-inline-item">
                        <i className="fa fa-credit-card-alt"></i>
                    </li>
                </ul>;
            }

            return '';
        };

        function getStatusBars(status) {
            switch (status) {
                case 'pending':
                    return '/assets/images/requests/pendingStatus.png';
                case 'confirmed':
                    return '/assets/images/requests/confirmedStatus.png';
                case 'upcoming':
                    return '/assets/images/requests/upcomingStatus.png';
                case 'payment':
                    return '/assets/images/requests/paymentStatus.png';
                case 'reviewing':
                    return '/assets/images/requests/reviewingStatus.png';
                case 'finished':
                    return '/assets/images/requests/finishedStatus.png';
                case 'canceled':
                    return '/assets/images/requests/canceledStatus.png';
                default:
                    return status;
            }
        }

        const statusBar = (src) => ({
            backgroundImage: 'url(http://' + window.location.host + getStatusBars(src) + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '100% 8px',
            width: 120
        });

        return (
            <section>
                <div className="content-page">
                    {/* Start content */}
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box">
                                    </div>
                                </div>
                            </div>
                            {/* end row */}

                            <div className="row">
                                <div className="col-md-6 col-lg-3 pull-center text-center">
                                    <div className="card-box" style={imageStyle}>
                                        <span className="pull-left m-r-15"><img src={this.state.photoUrl} alt="" className="thumb-lg rounded-circle" /></span>
                                        <div className="media-body">
                                            <h4 className="m-t-5 m-b-5 font-18 ellipsis">{this.state.fullName}</h4>
                                            <p className="text-muted m-b-0"><small>Area {this.state.areaCode}</small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="card-box widget-box-four" style={imageStyle}>
                                                <div id="dashboard-1" className="widget-box-four-chart"></div>
                                                <div className="pull-left">
                                                    <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Money Earned">Money Earned</h4>
                                                    <h3 className="m-b-0 m-t-20"> <span data-plugin="counterup"><NumberFormat value={this.state.moneyEarned} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>{/* end col */}

                                        <div className="col-sm-3">
                                            <div className="card-box widget-box-four" style={imageStyle}>
                                                <div id="dashboard-2" className="widget-box-four-chart"></div>
                                                <div className="pull-left">
                                                    <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Payments">Payments</h4>
                                                    <h3 className="m-b-0 m-t-20"> <span data-plugin="counterup"><NumberFormat value={this.state.numberOfTransactions} displayType={'text'} thousandSeparator={true} /></span></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>{/* end col */}

                                        <div className="col-sm-3">
                                            <div className="card-box widget-box-four" style={imageStyle}>
                                                <div id="dashboard-2" className="widget-box-four-chart"></div>
                                                <div className="pull-left">
                                                    <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Bookings">Bookings</h4>
                                                    <h3 className="m-b-0 m-t-20"> <NumberFormat value={this.state.numberOfBookings} displayType={'text'} thousandSeparator={true} /></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>{/* end col */}

                                        <div className="col-sm-3">
                                            <div className="card-box widget-box-four" style={imageStyle}>
                                                <div id="dashboard-2" className="widget-box-four-chart"></div>
                                                <div className="pull-left">
                                                    <h4 className="m-t-0 font-16 m-b-5 text-overflow" title="Loyalty Coins">Loyalty Coins</h4>
                                                    <h3 className="m-b-0 m-t-20"> <NumberFormat value={this.state.loyaltyCoins} displayType={'text'} thousandSeparator={true} /></h3>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>{/* end col */}
                                    </div>
                                    {/* end row */}
                                </div>
                                {/* end col */}
                            </div>
                            {/* end row */}

                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <PersonalInformation user={this.state.user} />
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <div className="card-box" style={style2}>
                                        <h4 className="font-18 text-overflow">Profiling Chart</h4>
                                        <ResponsiveContainer>
                                            <RadarChart data={this.state.profilingChart}
                                                margin={{
                                                    top: 0, right: 0, left: 0, bottom: 10,
                                                }}>
                                                <PolarGrid />
                                                <PolarAngleAxis dataKey="luxury" />
                                                <PolarRadiusAxis domain={[0, this.state.profiliChartMaxValue]} />
                                                <Radar name={this.state.fullName} dataKey="value" stroke="#8884d8" fill="#B28F28" fillOpacity={0.6} />
                                            </RadarChart>
                                        </ResponsiveContainer>

                                        <div className="chart-container">
                                            <div className="" style={style} id="beseated_profile_chart"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end row */}

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box" style={cardboxStyle}>
                                        <RequestList requests={this.state.bookings} />
                                    </div>

                                </div>
                                {/* end col */}

                            </div>
                            {/* end row */}


                        </div> {/* container */}

                    </div> {/* content */}

                </div>
            </section>
        );
    }
}

export default Member;