import * as actionTypes from "../actions/actionTypes";

const initialState = {
  token: localStorage.getItem("token"),
  userId: null,
  username: null,
  photoUrl: "/assets/images/user_default_photo.png",
  error: null,
  loading: false,
  logout: false,
  cardNumber: "",
  remindPasswordTried: false,
  remindPasswordError: "",
  remindPasswordSuccess: false
};

const authStart = state => ({
  ...state,
  error: null,
  loading: true
});

const authSuccess = (state, action) => ({
  ...state,
  token: action.token,
  error: null,
  loading: false
});

const authFail = (state, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const authLogout = state => ({
  ...state,
  token: null,
  userId: null,
  username: null,
  photoUrl: null,
  loading: false,
  logout: true
});

const authGetUserData = (state, action) => ({
  ...state,
  userId: action.userId,
  username: action.username,
  photoUrl: action.photoUrl,
  cardNumber: action.cardNumber
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    case actionTypes.AUTH_GET_USER_DATA:
      return authGetUserData(state, action);
    case actionTypes.REMIND_PASSWORD_TRIED: {
      return {
        ...state,
        remindPasswordTried: true,
      };
    }
    case actionTypes.REMIND_PASSWORD_FAIL: {
      return {
        ...state,
        remindPasswordError: action.error
      };
    }
    case actionTypes.REMIND_PASSWORD_SUCCESS: {
      return {
        ...state,
        remindPasswordSuccess: true,
        remindPasswordError: null,
        remindPasswordTried: false,
      };
    }
    case actionTypes.REMIND_PASSWORD_CLEAR_STATE: {
      return {
        ...state,
        remindPasswordError: null,
        remindPasswordSuccess: true,
        remindPasswordTried: false,
      }
    }
    default:
      return state;
  }
};

export default reducer;
