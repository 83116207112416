import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AfterStripeOnboardingModal = props => {
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'black',
            width: '70%',
            minWidth: '400px',
            textAlign: 'center',
            fontSize: '20px',
        },
        overlay: {
            zIndex: '99999',
            backgroundColor: 'rgba(3, 3, 3, 0.75)'
        }
    };

    return (
            <Modal
                onAfterClose={props.afterCloseModal}
                onRequestClose={props.closeModal}
                contentLabel="Are you sure?"
                isOpen={props.isModalOpen}
                style={modalStyles}
            >
                <h1 className="pb-2">Congratulations!</h1>
                <p>
                    You are one step away from fully setting up Stripe payouts. After your company address gets verified
                    by Stripe you need to upload Photo ID. When it gets verified too you can payout commision directly from our website!
                </p>
                <p>
                    Please head to PAYMENT page and there you can finish the process by clickin on Stripe Dashboard button.
                </p>
                <p>
                    NOTE: For now we support only USD payouts in America. More coming soon!
                </p>
                <button type='submit' className='btn btn-danger gallery-modal-button' onClick={e => props.setIsModalOpen(false)}>Dismiss</button>
            </Modal>
    );
}

export default AfterStripeOnboardingModal;