import React from 'react';

import ConciergeLuxuriesList from "../../components/conciergeLuxuriesList/conciergeLuxuriesList";
import ConciergeForm from "../../components/conciergeForm/conciergeForm";

const Drivers = props => {
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                            <ConciergeForm type={"drivers"}/>
                        <div className="col-md-12 col-lg-6 luxury-gallery">
                            <ConciergeLuxuriesList type={"drivers"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Drivers;