import React, { useState, Fragment } from 'react';
import CheckoutForm from '../../components/checkoutForm/checkoutForm';
import { Elements } from 'react-stripe-elements';
import { useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestSuccess } from '../../store/actions';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import RequestList from '../../components/requestList/requestList';

import * as actions from '../../store/actions';

const STRIPE_URL = 'https://connect.stripe.com/express/oauth/authorize?';
const REDIRECT_URL = 'https://concierge.bcted.com';
const STRIPE_CLIENT_ID = 'ca_GVhGDjHcWf7CJP4WDqbhgbzlcutBZSGp';
const STRIPE_STATE_TOKEN = 'ddad2nuD#t3asdJAF';

Modal.setAppElement('#root');

const StripePayment = props => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const currentUserEmail = useSelector(state => state.member.user.email);
    const cards = useSelector(state => state.payment.cards);
    const bankAccounts = useSelector(state => state.payment.bankAccounts);
    const stripeCustomer = useSelector(state => state.payment.stripeCustomer);
    const balances = useSelector(state => state.payment.balances);
    const stripeAccountId = useSelector(state => state.payment.stripeAccountId)
    const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false);
    const requests = useSelector(state => state.request.requests);

    useEffect(() => {
        dispatch(actions.initiateGetCards());
        dispatch(actions.initiateGetBankAccounts());
        dispatch(actions.initiateGetStripeCustomer());
        dispatch(actions.initiateGetBalances());
        dispatch(actions.initiateGetUserData(auth.userId));
    }, []);

    const sendCash = amount => {
        const data = new FormData();
        data.append("amount", amount);

        const response = axios.post(process.env.REACT_APP_API_URL + '/users/stripe/payouts/testTransfer123', data, {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                alert("sent!");
            })
            .catch(function (response) {
                console.log(response);
            });
    }

    const setDefaultCard = card => {
        const data = new FormData();
        data.append("card", card);

        const response = axios.post(process.env.REACT_APP_API_URL + '/users/stripe/setDefaultCard', data, {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                dispatch(actions.addToasts([{ message: "Default card has been changed", type: "success" }]));
                //dispatch(actions.initiateGetStripeCustomer());
            })
            .catch(function (response) {
                dispatch(actions.addToasts([{ message: "Error changing default card", type: "error" }]));
            });
    }

    const setDefaultBankAccount = bankAccount => {
        const data = new FormData();
        data.append("bank_account", bankAccount);

        const response = axios.post(process.env.REACT_APP_API_URL + '/users/stripe/setDefaultBankAccount', data, {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                dispatch(actions.addToasts([{ message: "Default bank account has been changed", type: "success" }]));
                //dispatch(actions.initiateGetBankAccounts());
            })
            .catch(function (response) {
                dispatch(actions.addToasts([{ message: "Error changing default bank account", type: "error" }]));
            });
    }

    const removeCard = (e, card) => {
        e.preventDefault();
        e.stopPropagation();
        const data = new FormData();
        data.append("card", card);

        const response = axios.post(process.env.REACT_APP_API_URL + '/users/stripe/removeCard', data, {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                dispatch(actions.addToasts([{ message: "Card has been removed", type: "success" }]));
                //dispatch(actions.initiateGetBankAccounts());
            })
            .catch(function (response) {
                dispatch(actions.addToasts([{ message: "Couldn't remove card", type: "error" }]));
            });
    }

    const removeBankAccount = (e, bankAccount) => {
        e.preventDefault();
        e.stopPropagation();
        const data = new FormData();
        data.append("bank_account", bankAccount);

        const response = axios.post(process.env.REACT_APP_API_URL + '/users/stripe/removeBankAccount', data, {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                dispatch(actions.addToasts([{ message: "Bank account has been removed", type: "success" }]));
                //dispatch(actions.initiateGetBankAccounts());
            })
            .catch(function (response) {
                dispatch(actions.addToasts([{ message: "Couldn't remove bank account", type: "error" }]));
            });
    }

    function getStripeDashboardRedirectLink() {
        const data = new FormData();

        const response = axios.get(process.env.REACT_APP_API_URL + '/users/stripe/getStripeDashboardRedirectLink', {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                console.log(response.data);
                return response.data.stripe_response.url;
            })
            .catch(function (response) {
                return "https://stripe.com";
            });
    }

    async function openStripeDashboard(e) {
        e.preventDefault();

        axios.get(process.env.REACT_APP_API_URL + '/users/stripe/getStripeDashboardRedirectLink', {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                console.log(response.data.stripe_response.url);
                window.open(response.data.stripe_response.url, "_blank");
            })
            .catch(function (response) {
                //window.open("https://stripe.com", "_blank");
            });
    }

    const openModal = currency => {
        //e.stopPropagation();        
        dispatch(actions.initiateGetRequests({ status: ["reviewing", "finished"] }, { orderBy: 'ur.id', type: 'ASC' }));
        setIsPayoutModalOpen(currency);
    }

    const closeModal = () => { setIsPayoutModalOpen(false) }
    const afterCloseModal = () => { }

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'black',
            width: '85%',
            minWidth: '400px',
            textAlign: 'center',
            overflowY: 'scroll',
            maxHeight: '80vh'
        },
        overlay: {
            zIndex: '99999',
            backgroundColor: 'rgba(3, 3, 3, 0.75)'
        }
    };

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6">
                            <div className="page-title-box">
                                <div className="clearfix">
                                    {/*<button type="button" className="btn btn-submit col-md-3 m-1" onClick={e => sendCash(100)}>send 100</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="block-title m-1">Your current balances in specified currencies</p>
                        </div>
                        {balances.map((item, i) => (
                            <div className='col-md-3 col-lg-2' key={i}>
                                <div className='card-box widget-box-four'>
                                    <div className='pull-left'>
                                        <h4 className='m-t-0 font-16 m-b-5 text-overflow' title='Payments'>{item.currency}</h4>
                                        <h3 className='m-b-0 m-t-20'>
                                            {' '}
                                            <span data-plugin='counterup'>
                                                <NumberFormat
                                                    value={item.balance}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                            </span>
                                        </h3>
                                    </div>
                                    <div className='clearfix'></div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="row payment-top-box">
                        <div className="col-md-12 col-lg-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-box" style={{ height: '320px', overflowY: 'auto' }}>
                                        <p className="block-title m-0">Credit cards</p>
                                        <table className="table-beseated table-hover table-actions-bar dt-responsive text-left" cellSpacing="0" width="100%" id="datatable">
                                            <thead>
                                                <tr>
                                                    <th>Created</th>
                                                    <th>Brand</th>
                                                    <th>Country</th>
                                                    <th>Number</th>
                                                    <th>Default</th>
                                                    <th className="hidden-sm">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {cards.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{item.created}</td>
                                                        <td>{item.card.brand}</td>
                                                        <td>{item.card.country}</td>
                                                        <td>**** **** **** {item.card.last4}</td>
                                                        <td>{item.id === stripeCustomer.default_source ? "Yes" : "No"}</td>

                                                        <td>
                                                            <div className='dropdown pull-center'>
                                                                <button
                                                                    className='dropdown-toggle card-drop arrow-none valid-anchor'
                                                                    data-toggle='dropdown'
                                                                    aria-expanded='false'
                                                                >
                                                                    <h3 className='m-0 text-muted'>
                                                                        <i className='mdi mdi-dots-horizontal'></i>
                                                                    </h3>
                                                                </button>
                                                                <div
                                                                    className='dropdown-menu dropdown-menu-right'
                                                                    aria-labelledby='btnGroupDrop1'
                                                                >
                                                                    <button className='dropdown-item valid-anchor' href="#" onClick={e => setDefaultCard(item.id)}>Set as default</button>
                                                                    <button className='dropdown-item valid-anchor' href="#" onClick={e => removeCard(e, item.id)}>Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {cards.length === 0
                                            ? <p className="block-title text-center pt-5">Add credit cards at the bottom of current page</p>
                                            : null}
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card-box" style={{ height: '320px', overflowY: 'auto' }}>
                                        <p className="block-title m-0">Bank accounts
                                            {stripeAccountId ?
                                                <a
                                                    href="#"
                                                    className="connect-button ml-5"
                                                    onClick={openStripeDashboard}>
                                                    <span>Stripe Dashboard</span>
                                                </a> : <a
                                                    target="_blank"
                                                    href={
                                                        STRIPE_URL
                                                        + 'response_type=code'
                                                        + '&redirect_uri=' + REDIRECT_URL
                                                        + '&client_id=' + STRIPE_CLIENT_ID
                                                        + '&state=' + STRIPE_STATE_TOKEN
                                                        + '&stripe_user[email]=' + currentUserEmail}
                                                    className="connect-button ml-5">
                                                    <span>Connect with Stripe</span>
                                                </a>}
                                        </p>
                                        <table className="table-beseated table-hover table-actions-bar dt-responsive text-left" cellSpacing="0" width="100%" id="datatable">
                                            <thead>
                                                <tr>
                                                    <th>Bank</th>
                                                    <th>Country</th>
                                                    <th>Currency</th>
                                                    <th>Routing number</th>
                                                    <th>Last 4</th>
                                                    <th>Default for currency</th>
                                                    <th className="hidden-sm">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {bankAccounts.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{item.bank_name}</td>
                                                        <td>{item.country}</td>
                                                        <td className="text-uppercase">{item.currency}</td>
                                                        <td>{item.routing_number}</td>
                                                        <td>{item.last4}</td>
                                                        <td>{item.default_for_currency ? "Yes" : "No"}</td>

                                                        <td>
                                                            <div className='dropdown pull-center'>
                                                                <button
                                                                    className='dropdown-toggle card-drop arrow-none valid-anchor'
                                                                    data-toggle='dropdown'
                                                                    aria-expanded='false'
                                                                >
                                                                    <h3 className='m-0 text-muted'>
                                                                        <i className='mdi mdi-dots-horizontal'></i>
                                                                    </h3>
                                                                </button>
                                                                <div
                                                                    className='dropdown-menu dropdown-menu-right'
                                                                    aria-labelledby='btnGroupDrop1'
                                                                >
                                                                    <button className='dropdown-item valid-anchor' onClick={e => openModal(item.currency)}>Payout</button>
                                                                    <button className='dropdown-item valid-anchor' href="#" onClick={e => setDefaultBankAccount(item.id)}>Set as default</button>
                                                                    <button className='dropdown-item valid-anchor' href="#" onClick={e => removeBankAccount(e, item.id)}>Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <Modal
                                            onAfterClose={afterCloseModal}
                                            onRequestClose={closeModal}
                                            contentLabel="Are you sure?"
                                            isOpen={isPayoutModalOpen}
                                            style={modalStyles}
                                        >
                                            <RequestList requests={requests.filter(request => request.commision.commision_value > 0 && !request.commision.is_ambassador_paid && request.commision.is_ready_for_payout)} />
                                            <button type='submit' className='btn btn-danger gallery-modal-button' onClick={e => setIsPayoutModalOpen(false)}>Dismiss</button>
                                        </Modal>

                                        {bankAccounts.length === 0
                                            ? <p className="block-title text-center pt-5">Add bank accounts at the bottom of current page</p>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="card-box" style={{ height: '660px', overflowY: 'auto' }}>
                                <p className="block-title m-0">Payouts history</p>
                                <table className="table-beseated table-hover table-actions-bar dt-responsive text-left" cellSpacing="0" width="100%" id="datatable">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Bank Account</th>
                                            <th>Money earned</th>
                                            <th>Bookings</th>
                                            <th className="hidden-sm">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    </tbody>
                                </table>
                                <p className="block-title text-center pt-5">Start booking and payout to fill the list!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row-padding">
                    <div className="col-md-12">
                        <Elements>
                            <CheckoutForm />
                        </Elements>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default withRouter(StripePayment);