import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './resetPassword.css';

import * as actions from '../../store/actions';

const getToken = searchString => {
    const params = searchString.substring(1, searchString.length).split('&');
    const tokenString = params.find(p => p.startsWith('token='));
    if (!tokenString) {
        return null;
    }
    const [_, token] =  tokenString.split('token=');
    return token;  
}

const ResetPassword = props => {

    const token = getToken(props.location.search);

    const dispatch = useDispatch();

    const [isPasswordDoesntMatchErrorShown, setIsPasswordDoesntMatchErrorShown] = useState(false);  

    const onResetPassword = event => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (data.get('password') !== data.get('repeatPassword')) {
            setIsPasswordDoesntMatchErrorShown(true);
            return;
        }
        dispatch(actions.resetPassword(token, data.get('password')));
    };

    return (
        <section className='bg-accpunt-pages'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='wrapper-page'>
                            <div className='account-pages'>
                                <div className='account-box'>
                                    <div className='account-logo-box'>
                                        <h2 className='text-uppercase text-center'>
                                            <a href='index.html' className='text-success'>
                                                <span>
                                                    <img
                                                        src='/assets/images/logo_dark.png'
                                                        alt=''
                                                        height='180'
                                                    />
                                                </span>
                                            </a>
                                        </h2>
                                    </div>

                                    <div className='account-content' style={{minHeight: '550px'}}>
                                        <form
                                            className='form-horizontal form-style-8'
                                            onSubmit={onResetPassword}
                                        >
                                            <div className='form-group row'>
                                                <div className='col-12'>
                                                    <label
                                                        className='label-on-gray'
                                                        id='email-label'
                                                        htmlFor='emailaddress'
                                                    >
                                                        Password
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        name='password'
                                                        type='password'
                                                        required=''
                                                        placeholder='Password'
                                                        minlength='5'
                                                        onChange={setIsPasswordDoesntMatchErrorShown.bind(null, false)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group row'>
                                                <div className='col-12'>
                                                    <label
                                                        className='label-on-gray'
                                                        id='email-label'
                                                        htmlFor='emailaddress'
                                                    >
                                                        Repeat password
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        name='repeatPassword'
                                                        type='password'
                                                        required=''
                                                        placeholder='Repeat password'
                                                        minlength='5'
                                                        onChange={setIsPasswordDoesntMatchErrorShown.bind(null, false)}
                                                    />
                                                </div>
                                            </div>
                                            {isPasswordDoesntMatchErrorShown && <div>
                                                <div
                                                    className='row client-type-buttons-row'
                                                    style={{marginTop: '2rem'}}
                                                >
                                                    <div className='col-lg-2'></div>
                                                    Passwords you entered do not match
                                                </div>
                                            </div>}
                                            <div
                                                className='row client-type-buttons-row'
                                                style={{marginTop: isPasswordDoesntMatchErrorShown ? '2.5rem' : '4.5rem'}}
                                            >
                                                <div className='col-lg-2'></div>
                                                <button
                                                    className='btn btn-submit col-md-4 col-lg-3 ml-1'
                                                    type='submit'
                                                >
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResetPassword;
