import { put, select } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions';

export function* locationGetLocationsSaga(action) {
    const state = yield select();
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/cities/list', {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })

        for (let i = 0; i < res.data._embedded.list.length; i++) {
            res.data._embedded.list[i].name = res.data._embedded.list[i].name.charAt(0).toUpperCase() + res.data._embedded.list[i].name.substring(1);
        }

        yield put(actions.getLocationsSuccess(res.data._embedded.list));
    } catch (err) {
        put(actions.addToasts([{message:"Couldn't download list of cities", type:"error"}]));
    }
}