import React from 'react';

const Logout = props => {
    return (
        <section className="bg-accpunt-pages">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">

                        <div className="wrapper-page">

                            <div className="account-pages">
                                <div className="account-box">
                                    <div className="account-content">
                                        <div className="text-center m-b-20">
                                            <div className="m-b-20">
                                                <div className="checkmark">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                         viewBox="0 0 161.2 161.2" enable-background="new 0 0 161.2 161.2">
                                                    <path className="path" fill="none" stroke="#32c861" stroke-miterlimit="10" d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                                                        c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                                                        c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"/>
                                                    <circle className="path" fill="none" stroke="#32c861" stroke-width="4" stroke-miterlimit="10" cx="80.6" cy="80.6" r="62.1"/>
                                                    <polyline className="path" fill="none" stroke="#32c861" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="113,52.8
                                                        74.1,108.4 48.2,86.4 "/>

                                                    <circle className="spin" fill="none" stroke="#32c861" stroke-width="4" stroke-miterlimit="10" stroke-dasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9"/>

                                                </svg>

                                                </div>
                                            </div>

                                            <h4 className="text-custom">See You Again!</h4>

                                            <p className="text-muted font-13 m-t-10"> You have successfully signed out. Back to <a href="/" className="text-primary m-r-5" onClick="this.loginAction"><b>Sign In</b></a></p>
                                        </div>

                                    </div>

                                </div>
                                {/* end card-box*/}
                            </div>

                        </div>
                        {/* end wrapper */}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Logout;