import * as actionTypes from './actionTypes';

export const initiateGetLocations = () => ({
    type: actionTypes.LOCATION_INITIATE_GET_LOCATIONS
});

export const getLocationsFail = (error) => ({
    type: actionTypes.LOCATION_GET_LOCATIONS_FAIL,
    error
});

export const getLocationsSuccess = (locations) => ({
    type: actionTypes.LOCATION_GET_LOCATIONS_SUCCESS,
    locations
});