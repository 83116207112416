import React, { useEffect, useState } from 'react';
import './conciergeSummary.css';

const ConciergeSummary = props => {
    const duration = props.duration < 1 ? 1 : props.duration;
    const amount = props.amount < 1 ? 1 : props.amount;
    const percentageMarkup = props.percentageMarkup ? props.percentageMarkup : 0;
    const markup = props.markup ? Number(props.markup) : 0;
    const [discount, setDiscount] = useState(0);

    const totalAmountBeforeDiscount = Math.ceil((props.luxury.beseated_price * duration * amount + (markup ? markup : percentageMarkup/100*props.luxury.beseated_price*duration*amount)));
    const totalAmountWithLuxuryDiscount = Math.ceil((props.luxury.beseated_price * duration * amount * (1 - discount / 100)  + (markup ? markup : percentageMarkup/100*props.luxury.beseated_price * duration * amount * (1 - discount / 100) )));
    const totalDeposit = Math.ceil(totalAmountWithLuxuryDiscount * props.luxury.deposit / 100);

    useEffect(() => {
        if (props.luxury.promotions) {
            setDiscount(0);
            for (let i = 0; i < props.luxury.promotions.length; i++) {
                if (Number(props.luxury.promotions[i].start_date) < Number(props.selectedDate / 1000)
                    && Number(props.selectedDate / 1000) < Number(props.luxury.promotions[i].end_date)) {
                    setDiscount(props.luxury.promotions[i].discount);
                }
            }
        } else {
            setDiscount(0);
        }
    }, [props.luxury, props.selectedDate]);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-6">
                    <p className="text-left">
                        {!props.luxury.luxury_type_name ? "Price" : null}
                        {props.luxury.luxury_type_name === "table" ? "Minimum spend" : null}
                        {props.luxury.luxury_type_name === "ticket" ? "Event price" : null}
                        {props.luxury.luxury_type_name === "villa" ? `Price per ${props.luxury.duration_type.toLowerCase() === 'days' ? 'night' : 'week'}` : null}
                        {props.luxury.luxury_type_name === "car"
                            || props.luxury.luxury_type_name === "bodyguard" || props.luxury.luxury_type_name === "yacht"
                            ? "Price per " + props.luxury.duration_type.slice(0, props.luxury.duration_type.length - 1)
                            : null}
                    </p>
                </div>
                <div className="col-6 text-right">
                    <p className="">{props.luxury.beseated_price !== undefined ? props.luxury.manager_info.currency + " " + props.luxury.beseated_price : "US$ 0"}</p>
                </div>
            </div>

            <div className={props.luxury.luxury_type_name === "bodyguard"
                || props.luxury.luxury_type_name === "ticket" ? "row" : "d-none"}>
                <div className="col-6">
                    {!props.luxury.luxury_type_name ? "Amount" : null}
                    {"Number of " + props.luxury.luxury_type_name + "s"}
                </div>
                <div className="col-6 text-right">
                    <p>{amount !== "" ? amount : "0"}</p>
                </div>
            </div>

            <div className={props.luxury.luxury_type_name === "table" || props.luxury.luxury_type_name === "ticket" ? "d-none" : "row concierge-summary-row"}>
                <div className="col-6">
                    {!props.luxury.luxury_type_name ? "Duration" : null}
                    {props.luxury.luxury_type_name === "villa" ? `Number of ${props.luxury.duration_type.toLowerCase() === 'days' ? 'nights' : 'weeks'}` : null}
                    {props.luxury.luxury_type_name === "car" || props.luxury.luxury_type_name === "yacht"
                        || props.luxury.luxury_type_name === "bodyguard" ? "Number of " + props.luxury.duration_type : null}
                </div>
                <div className="col-6 text-right">
                    <p>{duration !== "" ? duration : "0"}</p>
                </div>
            </div>

            <div className={props.luxury.luxury_type_name === "ticket" ? "row concierge-summary-row" : "d-none"}>
            </div>

            <div className={props.luxury.luxury_type_name === "table" ? "row concierge-summary-row" : "d-none"}>
                <div className="col-6">
                    {"Taxes and surcharges"}
                </div>
                <div className="col-6 text-right">
                    <p>0%</p>
                </div>
            </div>

            <div className={props.luxury.luxury_type_name === "car" || props.luxury.luxury_type_name === "yacht" || props.luxury.luxury_type_name === "villa" || props.luxury.luxury_type_name === "bodyguard" ? "row" : "d-none"}>
                <div className="col-6">
                    <p>Sub-total</p>
                </div>
                <div className="col-6 text-right">
                    <p>{props.luxury.beseated_price !== undefined ? props.luxury.manager_info.currency + " " + props.luxury.beseated_price * duration * amount : "US$ 0"}</p>
                </div>
            </div>
            <div className={props.luxury.luxury_type_name === "car" || props.luxury.luxury_type_name === "yacht" || props.luxury.luxury_type_name === "villa" || props.luxury.luxury_type_name === "bodyguard" ? "row concierge-summary-row" : "d-none"}>
                <div className="col-6">
                    <p>Taxes and surcharges</p>
                </div>
                <div className="col-6 text-right">
                    <p>0%</p>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <p>Total Amount (Before discount)</p>
                </div>
                <div className="col-6 text-right">
                    <p>{props.luxury.beseated_price !== undefined ? props.luxury.manager_info.currency + " " + totalAmountBeforeDiscount : "US$ 0"}</p>
                </div>
            </div>
            <div className="row concierge-summary-row">
                <div className="col-6">
                    <p>Discount</p>
                </div>
                <div className="col-6 text-right">
                    <p>{discount + "%"}</p>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <p>Total Amount (After discount)</p>
                </div>
                <div className="col-6 text-right">
                    <p>{props.luxury.beseated_price !== undefined ? props.luxury.manager_info.currency + " " + totalAmountWithLuxuryDiscount: "US$ 0"}</p>
                </div>
            </div>
            <div className="row concierge-summary-row">
                <div className="col-6">
                    <p>Deposit %</p>
                </div>
                <div className="col-6 text-right">
                    <p>{props.luxury.beseated_price !== undefined ? props.luxury.deposit + "%" : "0%"}</p>
                </div>
            </div>

            <div className={props.luxury.luxury_type_name === "yacht" ? "row" : "d-none"}>
                <div className="col-6">
                    <p>Total Amount</p>
                </div>
                <div className="col-6 text-right">
                    <p>{props.luxury.beseated_price !== undefined ? props.luxury.manager_info.currency + " " + totalAmountWithLuxuryDiscount : "US$ 0"}</p>
                </div>
            </div>
            <div className={props.luxury.luxury_type_name === "yacht" ? "row concierge-summary-row" : "d-none"}>
                <div className="col-6">
                    <p>APA</p>
                </div>
                <div className="col-6 text-right">
                    <p>{props.luxury.beseated_price !== undefined ? props.luxury.manager_info.currency + " 0" : "0%"}</p>
                </div>
            </div>

            <div className="row pt-1">
                <div className="col-6">
                    <p>Total Amount (To be paid)</p>
                </div>
                <div className="col-6 text-right">
                    <p>{props.luxury.beseated_price !== undefined ? props.luxury.manager_info.currency + " " + totalDeposit : "US$ 0"}</p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ConciergeSummary;