import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import AwesomeSlider from 'react-awesome-slider';
import axios from 'axios';
import 'react-awesome-slider/dist/styles.css';
import './luxury.css';

import Car from './luxuriesByTypes/car';
import Ticket from './luxuriesByTypes/ticket';
import Table from './luxuriesByTypes/table';
import Yacht from './luxuriesByTypes/yacht';
import Villa from './luxuriesByTypes/villa';

import GeneralTab from './generalTab/generalTab';
import PricingTab from './pricingTab/pricingTab';
import ReviewsTab from './reviewsTab/reviewsTab';
import OpeningHoursTab from './openingHoursTab/openingHoursTab';
import AmenitiesTab from './amenitiesTab/amenitiesTab';

import * as actions from '../../../store/actions';

Modal.setAppElement('#root');

const Luxury = props => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const choosenLuxury = useSelector(state => state.concierge.luxury);
    const selectedDate = useSelector(state => state.concierge.selectedDate);
    const [selectedTab, setSelectedTab] = useState("general"); //general, description, pricing, reviews
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reviews, setReviews] = useState([]);

    const LuxuryPicker = () => {
        switch (props.luxury.luxury_type_name) {
            case "car": return <Car luxury={props.luxury} selectedTab={selectedTab} reviews={reviews} />
            case "ticket": return <Ticket luxury={props.luxury} selectedTab={selectedTab} />
            case "bodyguard": return <Car luxury={props.luxury} selectedTab={selectedTab} />
            case "table": return <Table luxury={props.luxury} selectedTab={selectedTab} />
            case "yacht": return <Yacht luxury={props.luxury} selectedTab={selectedTab} />
            case "villa": return <Villa luxury={props.luxury} selectedTab={selectedTab} />
            default: return null;
        }
    }

    useEffect(() => {
        if (selectedTab === 'reviews') {
            axios
                .get(process.env.REACT_APP_API_URL + '/ratings/' + props.luxury.manager_info.id + '/list', {
                    headers: {
                        Accept: 'application/json',
                        'auth-token': auth.token
                    }
                })
                .then(function (response) {
                    setReviews(response.data._embedded.list);
                })
                .catch(function (response) {
                    console.log(response);
                });
        }
    }, [selectedTab]);

    const selectLuxury = () => {
        if (props.luxury.id === choosenLuxury.id) {
            dispatch(actions.setLuxuryForConcierge({}));
        } else {
            dispatch(actions.setLuxuryForConcierge(props.luxury));
        }
    }

    const DiscountBlock = (props) => {
        for (let i = 0; i < props.promotions.length; i++) {
            if (Number(props.promotions[i].start_date) < Number(props.selectedDate / 1000) && Number(props.selectedDate / 1000) < Number(props.promotions[i].end_date)) {
                return (
                    <div className="discount-block">
                        <span>Discount {props.promotions[i].discount}%</span>
                    </div>
                );
            }
        }

        return null;
    }

    const openGalleryModal = e => {
        e.stopPropagation();
        setIsModalOpen(true);
    }

    const Slider = () => {
        return (
            <AwesomeSlider >
                {props.luxury.photos.map((item, i) => <div key={i} data-src={item.path} />)}
            </AwesomeSlider>);
    }

    const closeModal = () => { setIsModalOpen(false) }
    const afterCloseModal = () => { }

    const ShowTab = () => {
        switch (selectedTab) {
            case "general": return <GeneralTab luxury={props.luxury} />
            case "pricing": return <PricingTab luxury={props.luxury} />
            case "opening_hours": return <OpeningHoursTab luxury={props.luxury} />
            case "amenities": return <AmenitiesTab luxury={props.luxury} reviews={reviews} />
            case "reviews": return <ReviewsTab luxury={props.luxury} reviews={reviews} />
            default: return null;
        }
    }

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'black',
            width: '70%',
            minWidth: '400px',
            textAlign: 'center',
            fontSize: '30px',
        },
        overlay: {
            zIndex: '99999',
            backgroundColor: 'rgba(3, 3, 3, 0.75)'
        }
    };

    const choosenLuxuryStyle = {
        borderStyle: 'solid',
        borderColor: 'gold',
        borderWidth: 1.5,
        padding: '0px',
        paddingLeft: '10px',
        paddingRight: '11px',
        boxSizing: 'border-box'
    }

    return (
        <React.Fragment>
            <div className="col-12 card-box" style={props.luxury.id === choosenLuxury.id ? choosenLuxuryStyle : { padding: '0px' }} onClick={selectLuxury}>
                <div className="row luxury-image-container" style={{ backgroundImage: "url('" + props.luxury.photo_url + "')", backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                    <DiscountBlock
                        promotions={props.luxury.promotions}
                        selectedDate={selectedDate} />

                    <div className="gallery-block" onClick={props.luxury.photos.length ? openGalleryModal : e => e.stopPropagation()}>
                        <i className="fa fa-image mr-3"></i><span>{props.luxury.photos.length}</span>
                    </div>
                </div>

                <div className='row client-type-buttons-row'>
                    <button
                        className={selectedTab === "general" ? 'btn-so col-md-3' : 'btn-nso col-md-3'}
                        type='button'
                        onClick={e => { setSelectedTab("general"); e.stopPropagation() }}
                    >
                        GENERAL
                    </button>

                    <button
                        className={selectedTab === "pricing" ? 'btn-so col-md-3' : 'btn-nso col-md-3'}
                        type='button'
                        onClick={e => { setSelectedTab("pricing"); e.stopPropagation() }}
                    >
                        { props.luxury.luxury_type_name === "table" ? "MINIMUM SPENDS" : "PRICING" }
                    </button>

                    {props.luxury.luxury_type_name === "table" ? 
                    <button
                        className={selectedTab === "opening_hours" ? 'btn-so col-md-3' : 'btn-nso col-md-3'}
                        type='button'
                        onClick={e => { setSelectedTab("opening_hours"); e.stopPropagation() }}
                    >
                        OPENING HOURS
                    </button> : null}
                    
                    {props.luxury.luxury_type_name === "yacht" || props.luxury.luxury_type_name === "villa" ? 
                    <button
                        className={selectedTab === "amenities" ? 'btn-so col-md-3' : 'btn-nso col-md-3'}
                        type='button'
                        onClick={e => { setSelectedTab("amenities"); e.stopPropagation() }}
                    >
                        AMENITIES
                    </button> : null}

                    <button
                        className={selectedTab === "reviews" ? 'btn-so col-md-3' : 'btn-nso col-md-3'}
                        type='button'
                        onClick={e => { setSelectedTab("reviews"); e.stopPropagation() }}
                    >
                        REVIEWS
                    </button>
                </div>

                <ShowTab />
            </div>

            <Modal
                onAfterClose={afterCloseModal}
                onRequestClose={closeModal}
                contentLabel="Are you sure?"
                isOpen={isModalOpen}
                style={modalStyles}
            >
                <Slider />
                <button type='submit' className='btn btn-danger gallery-modal-button' onClick={e => setIsModalOpen(false)}>Dismiss</button>
            </Modal>
        </React.Fragment >
    );
}

export default Luxury;