import { takeEvery, takeLatest, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import {
    authLogoutSaga,
    checkAuthTimeoutSaga,
    authUserSaga,
    authCheckStateSaga,
    authGetUserDataSaga,
    authForgotPasswordSaga,
    authResetPasswordSaga,
} from './auth';

import {
    changeMenu,
} from './settings';

import {
    requestGetRequestsSaga,
    requestSendRequestSaga,
    requestPayoutRequestSaga
} from './request';

import {
    memberGetMembersSaga,
    memberGetUserDataSaga,
    memberEditUserSaga,
    memberGetBeseatedProfile
} from './member';

import {
    locationGetLocationsSaga,
} from './location';

import {
    luxuryGetLuxuriesForConciergeSaga,
    luxuryGetMaxParamsSaga,
    luxuryGetMoreLuxuriesForConciergeSaga,
    luxuryGetAllVillaNamesSaga
} from './luxury';

import {
    managerGetManagersSaga,
} from './manager';

import {
    amenityGetAmenitiesSaga,
} from './amenity';

import {
    paymentGetCardsSaga,
    paymentGetBankAccountsSaga,
    paymentGetStripeCustomerSaga,
    paymentGetBalancesSaga
} from './payment';

import {
    notificationGetNotificationsSaga,
    notificationGetNewNotificationsNumberSaga
} from './notification';

const watchAuth = [
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, authLogoutSaga),
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_RESET_PASSWORD, authResetPasswordSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
    takeEvery(actionTypes.AUTH_INITIATE_GET_USER_DATA, authGetUserDataSaga),
    takeLatest(actionTypes.AUTH_FORGOT_PASSWORD, authForgotPasswordSaga)
];

const watchSettings = [
    takeEvery(actionTypes.SETTINGS_CHANGE_MENU, changeMenu)
];

const watchRequest = [
    takeLatest(actionTypes.REQUEST_INITIATE_GET_REQUESTS, requestGetRequestsSaga),
    takeLatest(actionTypes.REQUEST_INITIATE_SEND_REQUEST, requestSendRequestSaga),
    takeLatest(actionTypes.REQUEST_PAYOUT_REQUEST, requestPayoutRequestSaga)
    
];

const watchMember = [
    takeLatest(actionTypes.MEMBER_INITIATE_GET_MEMBERS, memberGetMembersSaga),
    takeLatest(actionTypes.MEMBER_INITIATE_GET_USER_DATA, memberGetUserDataSaga),
    takeLatest(actionTypes.MEMBER_EDIT_USER_DATA, memberEditUserSaga),
    takeLatest(actionTypes.MEMBER_GET_BESEATED_PROFILE, memberGetBeseatedProfile),
];

const watchLocation = [
    takeLatest(actionTypes.LOCATION_INITIATE_GET_LOCATIONS, locationGetLocationsSaga)
];

const watchLuxury = [
    takeLatest(actionTypes.LUXURY_INITIATE_GET_LUXURIES_FOR_CONCIERGE, luxuryGetLuxuriesForConciergeSaga),
    takeLatest(actionTypes.LUXURY_INITIATE_GET_MAX_PARAMS, luxuryGetMaxParamsSaga),
    takeLatest(actionTypes.LUXURY_INITIATE_GET_MORE_LUXURIES_FOR_CONCIERGE, luxuryGetMoreLuxuriesForConciergeSaga),
    takeLatest(actionTypes.LUXURY_INITIATE_GET_ALL_VILLA_NAMES, luxuryGetAllVillaNamesSaga),
];

const watchManager = [
    takeLatest(actionTypes.MANAGER_INITIATE_GET_MANAGERS, managerGetManagersSaga)
];

const watchAmenity = [
    takeLatest(actionTypes.AMENITY_INITIATE_GET_AMENITIES, amenityGetAmenitiesSaga)
];

const watchPayment = [
    takeLatest(actionTypes.PAYMENT_INITIATE_GET_CARDS, paymentGetCardsSaga),
    takeLatest(actionTypes.PAYMENT_INITIATE_GET_BANK_ACCOUNTS, paymentGetBankAccountsSaga),
    takeLatest(actionTypes.PAYMENT_INITIATE_GET_STRIPE_CUSTOMER, paymentGetStripeCustomerSaga),
    takeLatest(actionTypes.PAYMENT_INITIATE_GET_BALANCES, paymentGetBalancesSaga)
];

const watchNotification = [
    takeLatest(actionTypes.NOTIFICATION_INITIATE_GET_NOTIFICATIONS, notificationGetNotificationsSaga),
    takeLatest(actionTypes.NOTIFICATION_INITIAITE_GET_NUMBER_OF_NEW_NOTIFICATIONS, notificationGetNewNotificationsNumberSaga)
];

export default function* watchAll() {
    yield all([...watchAuth, ...watchSettings, ...watchRequest, ...watchMember, ...watchLocation, ...watchLuxury, ...watchManager, ...watchAmenity, ...watchPayment, ...watchNotification]);
}