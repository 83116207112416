import { push } from 'react-router-redux';
import { put, select } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions';

export function* requestGetRequestsSaga(action) {
    const state = yield select();
    const {orderBy} = action;
    let tmp = action.filters.status;
    let queryParams = '';
    queryParams += action.filters.limit ? 'limit=' + action.filters.limit : 'limit=' + 2000;
    for (let i = 0; i < tmp.length; i++) {
        queryParams += '&status[' + i + ']=' + tmp[i];
    }
    if (orderBy) {
        const {orderBy: order, type} = orderBy;
        queryParams = `${queryParams}&orderBy=${order}&type=${type}`;
    }

    try {
        const res = yield axios.get(
            process.env.REACT_APP_API_URL +
                '/ambassadors/' +
                state.auth.userId +
                '/requests/list?' +
                queryParams,
            {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                }
            }
        );
        yield put(actions.getRequests(res.data._embedded.list, res.data._embedded.size));
    } catch (err) {
        put(actions.authFail(err.response.data.error));
    }
}

export function* requestSendRequestSaga(action) {
    const state = yield select();

    try {
        const res = yield axios.post(
            process.env.REACT_APP_API_URL + '/requests/sendRequest',
            action.request,
            {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                }
            }
        );

        res.data.success === true
            ? yield put(push('/requests/' + res.data.id))
            : yield put(actions.addToasts([{message:res.data.error_text, type:"error"}]));
    } catch (err) {
        put(actions.authFail(err.response.data.error));
    }
}

export function* requestPayoutRequestSaga(action) {
    const state = yield select();
    const id = action.id;

    try {
        const res = yield axios.post(
            process.env.REACT_APP_API_URL +
                '/requests/' +
                id +
                '/payAmbassador',
            {},
            {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                }
            }
        );

        res.data.success === true
            ? yield put(actions.addToasts([{message:"Request has been paid", type:"success"}]))
            : yield put(actions.addToasts([{message:res.data.error_text, type:"error"}]));

        //yield put(actions.getRequests(res.data._embedded.list, res.data._embedded.size));
    } catch (err) {
        yield put(actions.addToasts([{message:"Remember you can only payout USD", type:"error"}]));
    }
}
