import * as actionTypes from './actionTypes';

export const initiateGetManagers = (managerType, location) => ({
    type: actionTypes.MANAGER_INITIATE_GET_MANAGERS,
    managerType,
    location
});

export const getManagersSuccess = (managers, totalAmount) => ({
    type: actionTypes.MANAGER_GET_MANAGERS_SUCCESS,
    managers,
    totalAmount
});