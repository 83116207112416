import React, {useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const MemberTableRow = props => {
    const [locationName, setLocationName] = useState("");

    useEffect(() => {
        if (props.member.area_code) {
            axios.get('https://restcountries.eu/rest/v2/callingcode/' + props.member.area_code)
                .then(response => {
                    setLocationName(response.data[0].name);
                })
                .catch(function (response) {
                    console.log(response);
                });
        }
    }, [props.member.area_code]);

    return (
        <tr>
            <td><b>#{props.member.id}</b></td>

            <td>
                <Link to={'/members/' + props.member.id}>
                    <h5 className="m-b-0 m-t-0 font-600">{props.member.first_name + " " + props.member.last_name}</h5>
                </Link>
            </td>

            <td>
                <a href={"tel:" + props.member.phone} title="" data-placement="top" data-toggle="tooltip" className="tooltips" data-original-title="phone">
                    <h5 className="m-b-0 m-t-0 font-600">{props.member.phone}</h5>
                </a>
            </td>

            <td>
                <h5 className="m-b-0 m-t-0 font-600">{locationName}</h5>
            </td>

            <td>
                <h5 className="m-b-0 m-t-0 font-600">${props.member.money_earned}</h5>
            </td>

            <td>
                <h5 className="m-b-0 m-t-0 font-600">{props.member.bookings}</h5>
            </td>

            <td>
                <div className="dropdown pull-center">
                    <button className="dropdown-toggle card-drop arrow-none valid-anchor" data-toggle="dropdown" aria-expanded="false">
                        <h3 className="m-0 text-muted"><i className="mdi mdi-dots-horizontal"></i></h3>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                        <Link className="dropdown-item" to={'/members/' + props.member.id}>Show</Link>
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default MemberTableRow;