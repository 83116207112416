
import * as actionTypes from './actionTypes';

export const initiateGetNotifications = () => ({
    type: actionTypes.NOTIFICATION_INITIATE_GET_NOTIFICATIONS
});

export const getNotificationsSuccess = (notifications) => ({
    type: actionTypes.NOTIFICATION_GET_NOTIFICATIONS_SUCCESS,
    notifications
});

export const initiateGetNumberOfNewNotifications = () => ({
    type: actionTypes.NOTIFICATION_INITIAITE_GET_NUMBER_OF_NEW_NOTIFICATIONS
});

export const getNewNotificationsNumberSuccess = numberOfNew => ({
    type: actionTypes.NOTIFICATION_GET_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS,
    numberOfNew
});

export const addToasts = toasts => ({
    type: actionTypes.NOTIFICATION_ADD_TOASTS,
    toasts
});

export const clearToasts = () => ({
    type: actionTypes.NOTIFICATION_CLEAR_TOASTS,
});