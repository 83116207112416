import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MemberList from '../../components/memberList/memberList';
import MemberTableList from '../../components/memberTableList/memberTableList';

import * as actions from '../../store/actions';

const Members = props => {
    const members = useSelector(state => state.member.members);
    //const totalAmount = useSelector(state => state.member.totalAmount);
    const [filters, setFilters] = useState({tableView:false});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.initiateGetMembers());
    }, [dispatch]);

    const style = {
        paddingLeft: 25,
        color: '#745d1a'
    };

    const styleOp = {
        paddingLeft: 25,
        color: '#745d1a',
        opacity: 0.2
    };

    const tableFilterClick = () => {
        setFilters({...filters, tableView:!filters.tableView});
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="page-title-box">
                            </div>
                        </div>
                    </div>

                    <div className="text-left m-b-30">
                        <div className="row">
                            <h3>
                                <span id="request-filter-pending" className="valid-anchor" style={filters.tableView ? style : styleOp} onClick={e => tableFilterClick()}>TABLE VIEW</span>
                            </h3>
                        </div>
                    </div>

                    {!filters.tableView ? <MemberList members={members} /> : <div className="card-box"><MemberTableList members={members} /></div>}
                    
                </div>
            </div>
        </div>
    );
}

export default Members;