import * as actionTypes from '../actions/actionTypes';

const initialState = {
    toasts: [],
    notifications: [],
    numberOfNew: 0
};

const clearToasts = state => ({
    ...state,
    toasts: []
});

const addToasts = (state, action) => ({
    ...state,
    toasts: [...action.toasts]
});

const getNotificationsSuccess = (state, action) => ({
    ...state,
    notifications: [...action.notifications]
});

const getNumberOfNewNotificationsSuccess = (state, action) => ({
    ...state,
    numberOfNew: action.numberOfNew
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_ADD_TOASTS:
            return addToasts(state, action);
        case actionTypes.NOTIFICATION_CLEAR_TOASTS:
            return clearToasts(state);
        case actionTypes.NOTIFICATION_GET_NOTIFICATIONS_SUCCESS:
            return getNotificationsSuccess(state, action);
        case actionTypes.NOTIFICATION_GET_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS:
            return getNumberOfNewNotificationsSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
