import React from 'react';
import MemberTableRow from './memberTableRow/memberTableRow';

const MemberTableList = props => {
    return (
        <table className="table-beseated table-hover table-actions-bar dt-responsive text-left" cellSpacing="0" width="100%" id="datatable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Member name</th>
                    <th>Phone Number</th>
                    <th>Area</th>
                    <th>Money earned</th>
                    <th>Number of bookings</th>
                    <th className="hidden-sm">Action</th>
                </tr>
            </thead>

            <tbody>
                {props.members.map((item, i) => <MemberTableRow member={item} key={i}/>)}
            </tbody>
        </table>
    );
}

export default MemberTableList;