import { put, all, delay, select } from "redux-saga/effects";
import axios from "axios";

import * as actions from "../actions";

const BASE_URL = "https://www.googleapis.com/identitytoolkit/v3/relyingparty";
const API_KEY = process.env.REACT_APP_API_KEY;

export function* authLogoutSaga(action) {
  try {
    const state = yield select();
    const res = yield axios.post(
      process.env.REACT_APP_API_URL + "/users/logout",
      {},
      {
        headers: {
          Accept: "application/json",
          'auth-token': state.auth.token
        }
      }
    );

    if (res.data.success === true) {
      yield window.intercomSettings = { ...window.intercomSettings, name: '', email: '', "hide_default_launcher": true};
      yield window.Intercom('update', window.intercomSettings);
      yield localStorage.clear();
      yield put(actions.authLogoutSucceed());
    } else {
      put(actions.authFail(res.data.error_text));
    }
  } catch (err) {
    put(actions.authFail(err.response.data.error));
  }
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.authLogout());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());
  const data = new FormData();
  data.append("username", action.email);
  data.append("password", action.password);

  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_URL + "/users/login",
      data
    );

    if (res.data.success === true && res.data.is_ambassador === true) {
      yield localStorage.setItem("token", res.data.auth_token);
      yield put(actions.authSuccess(res.data.auth_token));
    } else {
      yield put(actions.authFail("Wrong username or password"));
    }
  } catch (err) {
    put(actions.authFail(err.response.data.error));
  }
}

export function* authCheckStateSaga(action) {
  const token = localStorage.getItem("token");
  if (!token) yield put(actions.authLogout());
  else {
    const expirationDate = new Date(localStorage.getItem("expirationDate"));
    if (expirationDate > new Date()) {
      try {
        const res = yield axios.post(
          `${BASE_URL}/getAccountInfo?key=${API_KEY}`,
          {
            idToken: token
          }
        );
        const localId = res.data.users[0].localId;
        yield all([
          put(actions.authSuccess(token, localId)),
          put(actions.checkAuthTimeout((expirationDate - new Date()) / 1000))
        ]);
      } catch (err) {
        return;
      }
    } else yield put(actions.authLogout());
  }
}

export function* authGetUserDataSaga(action) {
  const state = yield select();
  try {
    const res = yield axios.get(
      process.env.REACT_APP_API_URL + "/users/startData",
      {
        headers: {
          Accept: "application/json",
          'auth-token': state.auth.token
        }
      }
    );
    yield put(
      actions.authGetUserData(
        res.data.id,
        res.data.username,
        res.data.photo_url,
        res.data.card_number
      )
    );
  } catch (err) {
    put(actions.authFail(err.response.data.error));
  }
}

export function* authForgotPasswordSaga(action) {
  const data = new FormData();
  data.append("email", action.email);

  try {
    yield put(actions.remindPasswordTried());
    yield axios.post(
      process.env.REACT_APP_API_URL + "/concierge/remindPassword",
      data
    );
    yield put(actions.remindPasswordSuccess());
  } catch (err) {
    const {error_text: errorText, error_code: errorCode} = err.response.data;
    yield put(actions.remindPasswordFail({errorText, errorCode}));
    yield console.log("ERROR");
  }
}

export function* authResetPasswordSaga(action) {
  const data = new FormData();
  data.append("token", action.token);
  data.append("password", action.password);

  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_URL + "/concierge/changePassword",
      data
    );
    if (res.data.success) {
      yield localStorage.setItem("token", res.data.auth_token);
      yield put(actions.authSuccess(res.data.auth_token));
    }
  } catch (err) {
    yield console.log("ERROR");
  }
}
