import {put, select} from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions';

function getLuxuryType(managerType) {
    switch (managerType) {
        case 'drivers':
            return 'car';
        case 'events':
            return 'ticket';
        case 'venues':
            return 'table';
        case 'bodyguards':
            return 'bodyguard';
        case 'yachts':
            return 'yacht';
        case 'villas':
            return 'villa';
        default:
            return 'default';
    }
}

export function* luxuryGetLuxuriesForConciergeSaga(action) {
    const state = yield select();
    const {numberOfBeds: number_of_beds, numberOfBedrooms: number_of_bedrooms, villaName: luxury_name} = action;
    let queryParams = {
        location: action.location,
        min_price: action.priceRangeValues.min,
        max_price: action.priceRangeValues.max,
        duration_type: action.durationType,
        duration_time: action.durationTime,
        limit: 20,
        min_capacity: action.guestRangeValues.min,
        max_capacity: action.guestRangeValues.max,
        manager: action.manager,
        lat: action.coords.lat,
        lng: action.coords.lng,
        number_of_beds,
        number_of_bedrooms,
        luxury_name,
        with_ratings: true
    };

    for (let i = 0; i < action.selectedAmenities.length; i++) {
        queryParams['amenities[' + i + ']'] = action.selectedAmenities[i];
    }

    try {
        const res = yield axios.get(
            process.env.REACT_APP_API_URL + '/luxuries/list/' + getLuxuryType(action.managerType),
            {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                },
                params: queryParams
            }
        );
        yield put(
            actions.getLuxuriesForConciergeSuccess(
                res.data._embedded.list,
                res.data.size,
                res.data._links
            )
        );
    } catch (err) {
        put(actions.addToasts([{message:err.response.data.error, type:"error"}]));
    }
}

export function* luxuryGetMoreLuxuriesForConciergeSaga(action) {
    const state = yield select();

    if (state.luxury.luxuriesLinks.next.href) {
        try {
            const res = yield axios.get(state.luxury.luxuriesLinks.next.href, {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                }
            });
            yield put(
                actions.getMoreLuxuriesForConcierge(res.data._embedded.list, res.data._links)
            );
        } catch (err) {
            put(actions.addToasts([{message:err.response.data.error, type:"error"}]));
        }
    }
}

export function* luxuryGetMaxParamsSaga(action) {
    const state = yield select();
    try {
        const res = yield axios.get(
            process.env.REACT_APP_API_URL +
                '/luxuries/' +
                getLuxuryType(action.managerType) +
                '/getMaxPriceAndCapacity',
            {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                }
            }
        );

        yield put(actions.getMaxParamsSuccess(res.data.max_price, res.data.max_capacity));
    } catch (err) {
        put(actions.addToasts([{message:err.response.data.error, type:"error"}]));
    }
}

export function* luxuryGetAllVillaNamesSaga(action) {
    const state = yield select();
    const {location} = action;
    const requestString = location ? `/luxuries/villas/names?location=${location}` : '/luxuries/villas/names';
    try {
        const res = yield axios.get(
            process.env.REACT_APP_API_URL +
                requestString,
            {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                },
            }
        );

        yield put(actions.getAllVillasNamesSuccess(res.data.villa_names));
    } catch (err) {
        put(actions.addToasts([{message:err.response.data.error, type:"error"}]));
    }
}
