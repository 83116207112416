import * as actionTypes from '../actions/actionTypes';

const initialState = {
    luxury: {
        duration_type: "Hours",
        manager_info: {}
    },
    selectedDate: 0
};

const setLuxuryForConcierge = (state, action) => ({
    ...state,
    luxury: action.luxury
});

const setSelectedDate = (state, action) => ({
    ...state,
    selectedDate: action.date
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONCIERGE_SET_LUXURY:
            return setLuxuryForConcierge(state, action);
        case actionTypes.CONCIERGE_SET_SELECTED_DATE:
            return setSelectedDate(state, action);
        default:
            return state;
    }
};

export default reducer;