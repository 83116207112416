import React, { useEffect, useState } from 'react';
import RequestList from '../../components/requestList/requestList';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '../../store/actions';

const Requests = props => {
    const requests = useSelector(state => state.request.requests);
    //const totalAmount = useSelector(state => state.request.totalAmount);
    //const filters = useSelector(state => state.request.filters);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({ status: [] });
    const [currentOrder, setCurrentOrder] = useState({ orderBy: 'ur.id', type: 'ASC' });

    useEffect(() => {
        dispatch(
            actions.initiateGetRequests({
                status: [
                    'pending',
                    'confirmed',
                    'upcoming',
                    'payment',
                    'reviewing',
                    'finished',
                    'canceled'
                ]
            })
        );
    }, [dispatch]);
    const getCurrentFilters = () =>
        filters.status.length > 0
            ? filters
            : {
                status: [
                    'pending',
                    'confirmed',
                    'upcoming',
                    'payment',
                    'reviewing',
                    'finished',
                    'canceled'
                ]
            };
    const onOrderBy = orderBy => {
        setCurrentOrder(orderBy);
        dispatch(actions.initiateGetRequests(getCurrentFilters(), orderBy));
    };

    const statusFilterOnClick = status => {
        const tmpFilters = filters.status.find(item => item === status)
            ? { status: [...filters.status.filter(item => item !== status)] }
            : { ...filters, status: [...filters.status, status] };

        setFilters(tmpFilters);
        dispatch(actions.initiateGetRequests(tmpFilters.status.length > 0 ? tmpFilters : { status: ["pending", "confirmed", "upcoming", "payment", "reviewing", "finished", "canceled"] }, currentOrder));
    };

    const style = {
        paddingLeft: 25,
        color: '#745d1a',
        display: 'inline-block'
    };

    const styleOp = {
        paddingLeft: 25,
        color: '#745d1a',
        opacity: 0.2,
        display: 'inline-block'
    };

    return (
        <div className='content-page'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='page-title-box'>
                                <div className='clearfix'></div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div
                                className='card-box full-height'
                                style={{
                                    overflowY: 'scroll',
                                    padding: '20px 20px'
                                }}
                            >
                                <div className='text-left m-b-30'>
                                    <div className='row'>
                                        <div className='col-md-12'
                                            style={{
                                            }}>
                                            <h3>
                                                <span
                                                    id='request-filter-pending'
                                                    className='valid-anchor'
                                                    style={
                                                        filters.status.find(
                                                            item => item === 'pending'
                                                        ) && filters.status.length < 7
                                                            ? style
                                                            : styleOp
                                                    }
                                                    onClick={e => statusFilterOnClick('pending')}
                                                >
                                                    PENDING
                                                </span>

                                                <span
                                                    id='request-filter-confirmed'
                                                    className='valid-anchor'
                                                    style={
                                                        filters.status.find(
                                                            item => item === 'confirmed'
                                                        ) && filters.status.length < 7
                                                            ? style
                                                            : styleOp
                                                    }
                                                    onClick={e => statusFilterOnClick('confirmed')}
                                                >
                                                    CONFIRMED
                                                </span>

                                                <span
                                                    id='request-filter-upcoming'
                                                    className='valid-anchor'
                                                    style={
                                                        filters.status.find(
                                                            item => item === 'upcoming'
                                                        ) && filters.status.length < 7
                                                            ? style
                                                            : styleOp
                                                    }
                                                    onClick={e => statusFilterOnClick('upcoming')}
                                                >
                                                    UPCOMING
                                                </span>

                                                <span
                                                    id='request-filter-payment'
                                                    className='valid-anchor'
                                                    style={
                                                        filters.status.find(
                                                            item => item === 'payment'
                                                        ) && filters.status.length < 7
                                                            ? style
                                                            : styleOp
                                                    }
                                                    onClick={e => statusFilterOnClick('payment')}
                                                >
                                                    PAYMENT
                                                </span>

                                                <span
                                                    id='request-filter-reviewing'
                                                    className='valid-anchor'
                                                    style={
                                                        filters.status.find(
                                                            item => item === 'reviewing'
                                                        ) && filters.status.length < 7
                                                            ? style
                                                            : styleOp
                                                    }
                                                    onClick={e => statusFilterOnClick('reviewing')}
                                                >
                                                    REVIEWING
                                                </span>

                                                <span
                                                    id='request-filter-finished'
                                                    className='valid-anchor'
                                                    style={
                                                        filters.status.find(
                                                            item => item === 'finished'
                                                        ) && filters.status.length < 7
                                                            ? style
                                                            : styleOp
                                                    }
                                                    onClick={e => statusFilterOnClick('finished')}
                                                >
                                                    FINISHED
                                                </span>

                                                <span
                                                    id='request-filter-canceled'
                                                    className='valid-anchor'
                                                    style={
                                                        filters.status.find(
                                                            item => item === 'canceled'
                                                        ) && filters.status.length < 7
                                                            ? style
                                                            : styleOp
                                                    }
                                                    onClick={e => statusFilterOnClick('canceled')}
                                                >
                                                    CANCELED
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <RequestList
                                    requests={requests}
                                    currentOrder={currentOrder}
                                    onOrderBy={onOrderBy}
                                    sortable
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Requests;
