import React from 'react';
import {useSelector} from 'react-redux';

import Notification from './notification/notification';

const NotificationsDropdown = props => {
    const notifications = useSelector(state => state.notification.notifications);

    return (
        <div className={"dropdown-menu dropdown-menu-right dropdown-arrow dropdown-lg "+props.isDropdownShown} aria-labelledby="Preview" style={{maxHeight:'395px', overflowY: 'auto', overflowX: 'hidden'}}>
            <div className="dropdown-item noti-title">
                <h5>Notifications</h5>
            </div>

            {notifications.map((item, i) => <Notification notification={item} key={i}/>)}
        </div>
    );
}

export default NotificationsDropdown;