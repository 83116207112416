import React from 'react';

const Page404 = props => {
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="clearfix">ASDASD</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Page404;