import * as actionTypes from '../actions/actionTypes';

const initialState = {
  enlargedMenu: window.innerWidth < 1000 ? false : true,
  loading: false,
  message: false
};

const changeMenu = state => ({
  ...state,
  enlargedMenu: !state.enlargedMenu
});

const showMessage = (state, action) => ({
  ...state,
  message: action.message
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_CHANGE_MENU:
      return changeMenu(state);
    case actionTypes.SETTINGS_SHOW_MESSAGE:
      return showMessage(state,action);
    default:
      return state;
  }
};

export default reducer;