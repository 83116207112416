import React, { useState } from 'react';
import updateLabelUtils from '../../../utils/updateLabelUtils';

const BankAccountElement = props => {
    const [accountHolder, setAccountHolder] = useState("");
    const [accountType, setAccountType] = useState("");
    const [currency, setCurrency] = useState("EUR");
    const [routingNumber, setRoutingNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [sortCode, setSortCode] = useState("");
    const IBan = props.iban;

    /** 
     *  Bank accounts from usa or gb need hooking up custom forms like:
     *  [0] - https://stripe.com/docs/js/tokens_sources/create_token?type=bank_account -usage of js lib, not react one
     *  [1] - https://jsfiddle.net/ywain/8nobq41n/ -form example
    */

    return (
        <React.Fragment>
            <div className="form-group row pt-2 d-none">
                <div className="col-lg-12">
                    <div className="row input-container">
                        <label className="floating" id="accountType-label" htmlFor="accountType">account type</label>
                        <select id="accountType" className="field" onChange={e => { updateLabelUtils(e); }}>
                            <option value="individual">individual</option>
                            <option value="company">company</option>
                        </select>
                    </div>
                </div>
            </div>
            
            <div className="form-group row pt-2">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-12 input-container">
                            <label className="floating" id="currency-label" htmlFor="currency">currency</label>
                            <select id="currency" className="field" value={currency} onChange={e => { setCurrency(e.target.value); updateLabelUtils(e); }}>
                                <option value="CHF">CHF - Swiss Franc</option>
                                <option value="DKK">DKK - Danish Krone</option>
                                <option value="EUR">EUR - Euro</option>
                                <option value="GBP">GBP - British Pound</option>
                                <option value="NOK">NOK - Norwegian Krone</option>
                                <option value="SEK">SEK - Swedish Krona</option>
                                <option value="USD">USD - US Dollar</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group row pt-2">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 input-container">
                            <label id="accountHolder-label" htmlFor="accountHolder">account holder</label>
                            <input
                                className="form-control text-left"
                                type="text"
                                placeholder="account holder"
                                id="accountHolder"
                                name="account_holder"
                                value={accountHolder}
                                onChange={e => { setAccountHolder(e.target.value); updateLabelUtils(e); }} />
                        </div>
                    </div>
                </div>
            </div>

            {currency === "USD" ?
                <React.Fragment>
                    <div className="form-group row pt-2">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-12 input-container">
                                    <label id="routingNumber-label" htmlFor="routingNumber">routing number</label>
                                    <input
                                        className="form-control text-left"
                                        type="text"
                                        placeholder="routing number"
                                        id="routingNumber"
                                        name="routing_number"
                                        value={routingNumber}
                                        size="9"
                                        onChange={e => { setRoutingNumber(e.target.value); updateLabelUtils(e); }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row pt-2">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-12 input-container">
                                    <label id="accountNumber-label" htmlFor="accountNumber">account number</label>
                                    <input
                                        className="form-control text-left"
                                        type="text"
                                        placeholder="account number"
                                        id="accountNumber"
                                        name="account_number"
                                        value={accountNumber}
                                        size="12"
                                        onChange={e => { setAccountNumber(e.target.value); updateLabelUtils(e); }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment> : null}

            {currency === "GBP" ?
                <React.Fragment>
                    <div className="form-group row pt-2">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-12 input-container">
                                    <label id="sortCode-label" htmlFor="sortCode">sort code</label>
                                    <input
                                        className="form-control text-left"
                                        type="text"
                                        placeholder="sort code"
                                        id="sortCode"
                                        name="sort_code"
                                        value={sortCode}
                                        size="6"
                                        onChange={e => { setSortCode(e.target.value); updateLabelUtils(e); }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row pt-2">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-12 input-container">
                                    <label id="accountNumber-label" htmlFor="accountNumber">account number</label>
                                    <input
                                        className="form-control text-left"
                                        type="text"
                                        placeholder="account number"
                                        id="accountNumber"
                                        name="account_number"
                                        value={accountNumber}
                                        size="8"
                                        onChange={e => { setAccountNumber(e.target.value); updateLabelUtils(e); }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment> : null}

            {currency !== "USD" && currency !== "GBP" ?
                <IBan supportedCountries={["SEPA"]} className="form-control-stripe text-left" style={{ base: { fontSize: '16px', color: '#29abe2', "InputElement::placeholder": { color: '#29abe2' } } }} />
                : null}

        </React.Fragment>
    );
}

export default BankAccountElement;