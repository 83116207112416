import React, { useState, useEffect } from 'react';
import updateLabelUtils from '../../utils/updateLabelUtils';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import * as actions from '../../store/actions';

const PersonalInformation = props => {
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const auth = useSelector(state => state.auth);
    const [locationName, setLocationName] = useState("");

    useEffect(() => {
        if (props.user.area_code) {
            axios.get('https://restcountries.eu/rest/v2/callingcode/' + props.user.area_code)
                .then(response => {
                    setLocationName(response.data[0].name);
                })
                .catch(function (response) {
                    console.log(response);
                });
        }
    }, [props.user.area_code]);

    const style2 = {
        minHeight: 350,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    };

    const InfoIcons = (props) => {
        if (props.medium === 'fb') {
            if (props.hasCard === true) {
                return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                    <li className="list-inline-item">
                        <i className="fa fa-credit-card-alt gold-icons"></i>
                    </li>
                    <li className="list-inline-item">
                        <i className="fa fa-facebook-official gold-icons"></i>
                    </li>
                </ul>;
            }

            return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                <li className="list-inline-item">
                    <i className="fa fa-facebook-official gold-icons"></i>
                </li>
            </ul>;
        } else if (props.medium === 'gmail') {
            if (props.hasCard === true) {
                return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                    <li className="list-inline-item">
                        <i className="fa fa-credit-card-alt gold-icons"></i>
                    </li>
                    <li className="list-inline-item">
                        <i className="icon-gmail gold-icons"></i>
                    </li>
                </ul>;
            }

            return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                <li className="list-inline-item">
                    <i className="icon-gmail gold-icons"></i>
                </li>
            </ul>;
        }

        if (props.hasCard === true) {
            return <ul className="social-links list-inline m-t-0 m-b-0 font-16">
                <li className="list-inline-item">
                    <i className="fa fa-credit-card-alt gold-icons"></i>
                </li>
            </ul>;
        }

        return '';
    };

    const ShowMode = () => {
        return (
            <div className="panel-body">
                <div className="row m-b-20">
                    <div className="col-md-6">
                        <p className="text-muted font-16"><i className="icon-member gold-icons"></i> <span className="m-l-15"><strong>{props.user.first_name + " " + props.user.last_name}</strong></span></p>
                    </div>
                    <div className="col-md-6">
                        <a href={"mailto:" + props.useremail} title="" data-placement="top" data-toggle="tooltip" className="tooltips" data-original-title="email">
                            <p className="text-muted font-16"><i className="icon-email gold-icons"></i> <span className="m-l-15"><strong>{props.user.email}</strong></span></p>
                        </a>
                    </div>
                </div>
                <div className="row m-b-20">
                    <div className="col-md-6">
                        <a href={"tel:" + props.user.phone} title="" data-placement="top" data-toggle="tooltip" className="tooltips" data-original-title="phone">
                            <p className="text-muted font-16"><i className="icon-phone gold-icons"></i> <span className="m-l-15"><strong>{props.user.phone}</strong></span></p>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <p className="text-muted font-16"><i className="icon-city gold-icons"></i> <span className="m-l-15"><strong>{locationName}</strong></span></p>
                    </div>
                </div>
                {/*
                <div className="row m-b-20">
                    <div className="col-md-6">
                        <InfoIcons medium={props.usermedium} hasCard={props.userhas_card} />
                    </div>
                    <div className="col-md-6">
                        <p className="text-muted font-16"><strong>Commision: <span className="m-l-15">{props.usercommision} %</span></strong></p>
                    </div>
                </div>*/}
            </div>
        );
    }

    const handleEditSubmit = e => {
        e.preventDefault();
        const data = new FormData(e.target);
        dispatch(actions.editUserData(data.get("full_name"), data.get("email"), data.get("phone"), data.get("area_code")));
    }

    const EditMode = () => {
        return (
            <form className="panel-body form-style-8" style={{ padding: '0px' }} onSubmit={handleEditSubmit}>
                <div className="row m-b-20">
                    <div className="col-md-6" style={{ marginBottom: '1rem' }}>
                        <label className="label-on-gray" id="fullName-label" htmlFor="fullName">Full name</label>
                        <input className="form-control" name="full_name" type="text" id="fullName" required="" min="6" defaultValue={props.user.first_name + " " + props.user.last_name} placeholder={props.user.first_name + " " + props.user.last_name} onChange={updateLabelUtils} />
                    </div>
                    <div className="col-md-6" style={{ marginBottom: '1rem' }}>
                        <label className="label-on-gray" id="email-label" htmlFor="email">Email address</label>
                        <input className="form-control" name="email" type="email" id="email" required="" min="5" defaultValue={props.user.email} placeholder={props.user.email} onChange={updateLabelUtils} />
                    </div>
                    <div className="col-md-6">
                        <label className="label-on-gray" id="phone-label" htmlFor="phone">Phone number</label>
                        <input className="form-control" name="phone" type="phone" id="phone" required="" min="9" defaultValue={props.user.phone} placeholder={props.user.phone} onChange={updateLabelUtils} />
                    </div>
                    <div className="col-md-6">
                        <label className="label-on-gray" id="areaCode-label" htmlFor="areaCode">Area code</label>
                        <input className="form-control" name="area_code" type="number" id="areaCode" required="" defaultValue={props.user.area_code} placeholder={props.user.area_code} onChange={updateLabelUtils} />
                    </div>
                </div>
                <div className="row client-type-buttons-row">
                    <div className="col-lg-2"></div>
                    <button className="btn btn-danger col-md-4 col-lg-3" type="submit" onClick={e => setIsEditMode(false)}>BACK</button>
                    <button className="btn btn-submit col-md-4 col-lg-3 ml-1" type="submit">SAVE</button>
                </div>
            </form>
        );
    }

    return (
        <div className="card-box" style={style2}>
            <h4 className="font-18 text-overflow">Contact information
                {auth.userId === props.user.id ? <i className="fa fa-pencil gold-icons ml-2 valid-anchor" onClick={e => setIsEditMode(!isEditMode)}></i> : null }
            </h4>
            <hr />
            {isEditMode ? <EditMode /> : <ShowMode />}
        </div>
    );
}

export default PersonalInformation;