import React from 'react';

const AmenitiesTab = props => {
    const VillaAmenities = () => {
        return (
            <div className="row pt-4 pl-4 pr-4">
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>Amenities</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'amenities').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'amenities').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name}</span>
                    ))}
                </div>
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>Facilities</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'facilities').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'facilities').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name}</span>
                    ))}
                </div>
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>Property type</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'property type').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'property type').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name}</span>
                    ))}
                </div>
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>House rules</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'house rules').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'house rules').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name} </span>
                    ))}
                </div>
            </div>
        );
    }

    const YachtAmenities = () => {
        return (
            <div className="row pt-4 pl-4 pr-4">
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>Entertainment</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'entertainment').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'entertainment').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name}</span>
                    ))}
                </div>
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>Saloon & cabin</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'saloon & cabin').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'saloon & cabin').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name}</span>
                    ))}
                </div>
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>Navigation & safety</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'navigation & safety').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'navigation & safety').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name}</span>
                    ))}
                </div>
                <div className="col-lg-12" style={{ paddingBottom: '15px', paddingTop: '15px' }}>
                    <p>Extras</p>

                    {props.luxury.amenities.filter(elem => elem.type === 'extras').length === 0 ? <span>None</span> : null}
                    {props.luxury.amenities.filter(elem => elem.type === 'extras').map((item, i) => (
                        <span key={i} style={{marginRight:'15px'}}>{item.name} </span>
                    ))}
                </div>
            </div>
        );
    }

    return (props.luxury.luxury_type_name === 'yacht' ? <YachtAmenities /> : <VillaAmenities />);
}

export default AmenitiesTab;