import * as actionTypes from '../actions/actionTypes';

const initialState = {
    cards: [],
    bankAccounts: [],
    stripeCustomer: {},
    balances: [],
    stripeStateToken: ''
};

const getCards = (state, action) => ({
    ...state,
    cards: action.cards
});

const getBankAccounts = (state, action) => ({
    ...state,
    bankAccounts: action.bankAccounts
});

const getStripeAccountId = (state, action) => ({
    ...state,
    stripeAccountId: action.stripeAccountId
});

const getStripeCustomer = (state, action) => ({
    ...state,
    stripeCustomer: action.stripeCustomer
});

const getBalancesSuccess = (state, action) => ({
    ...state,
    balances: [...action.balances]
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAYMENT_GET_CARDS:
            return getCards(state, action);
        case actionTypes.PAYMENT_GET_BANK_ACCOUNTS:
            return getBankAccounts(state, action);
        case actionTypes.PAYMENT_GET_STRIPE_CUSTOMER:
            return getStripeCustomer(state, action);
        case actionTypes.PAYMENT_GET_BALANCES_SUCCESS:
            return getBalancesSuccess(state, action);
        case actionTypes.PAYMENT_GET_STRIPE_ACCOUNT_ID:
            return getStripeAccountId(state, action);
        default:
            return state;
    }
};

export default reducer;