import { put, select } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions';

export function* managerGetManagersSaga(action) {
    const state = yield select();

    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/users/managers/list/' + action.managerType + '?limit=10000&location=' + action.location, {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })
        yield put(actions.getManagersSuccess(res.data._embedded.list, res.data.size));
    } catch (err) {
        put(actions.addToasts([{message:err.response.data.error, type:"error"}]));
    }
}