import { put, select } from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions';

export function* notificationGetNotificationsSaga(action) {
    const state = yield select();
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/notifications/list', {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })

        yield put(actions.getNotificationsSuccess(res.data._embedded.list));
    } catch (err) {
        put(actions.addToasts([{message:"Couldn't download notifications list", type:"error"}]));
    }
}

export function* notificationGetNewNotificationsNumberSaga(action) {
    const state = yield select();
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/notifications/getNewNumber', {
            headers: {
                "Accept": 'application/json',
                'auth-token': state.auth.token
            }
        })

        yield put(actions.getNewNotificationsNumberSuccess(res.data.count));
    } catch (err) {
        put(actions.addToasts([{message:"Couldn't get new notifications number", type:"error"}]));
    }
}