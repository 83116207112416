import React from 'react';
import StarRatings from 'react-star-ratings';

const GeneralTab = props => {
    return (
        <div className="row pt-4 pl-4 pr-4">
            <div className="col-12">
                <p className="main-info">{props.luxury.name} {props.luxury.luxury_additional_type}</p>
                <p className="main-info">Prices start at {props.luxury.beseated_price.toLocaleString()} {props.luxury.manager_info.currency}</p>

                <StarRatings
                    rating={props.luxury.manager_info.rating.avg}
                    starDimension="20px"
                    starSpacing="15px"
                    starRatedColor="rgb(223,176,74)"
                />
                <span style={{ fontSize: '20px' }}> ({props.luxury.manager_info.rating.count})</span>

                <p className="pt-3">{props.luxury.description ? props.luxury.description : "No description"}</p>
                <p className="">Address: {props.luxury.address}</p>
            </div>

            <div className="col-lg-6">
                <p className="red-info">Minimum {props.luxury.duration_min} {props.luxury.duration_type}</p>
                <p className="red-info">Capacity: {props.luxury.capacity}</p>
            </div>

            <div className="col-lg-6">
                <p className={props.luxury.number_of_bedrooms ? "blue-info" : "d-none"}>Beds: {props.luxury.number_of_bedrooms}</p>
                <p className={props.luxury.number_of_bedrooms ? "blue-info" : "d-none"}>Bathrooms: {props.luxury.number_of_bedrooms}</p>
            </div>
        </div>
    );
}

export default GeneralTab;