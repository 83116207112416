import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { routerActions } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import * as actions from '../../store/actions';

const changeOrderBy = (currentOrder, onOrderBy) => order => {
    const { orderBy, type } = currentOrder;
    if (orderBy === order) {
        const newOrder = {
            orderBy,
            type: type === 'DESC' ? 'ASC' : 'DESC',
        };
        onOrderBy(newOrder);
        return;
    }
    const newOrder = {
        orderBy: order,
        type: 'ASC'
    };
    onOrderBy(newOrder);
};

const getTableHeader = (sortable, type, orderBy, name, sortableField, onHeaderClick) => {
    if (!sortable) {
        return (<th key={name}>{name}</th>);
    }
    return (
        <th
            key={name}
            className={classnames(
                sortable && 'sortable',
                orderBy === sortableField && `sorted-${type.toLowerCase()}`
            )}
            onClick={onHeaderClick.bind(null, sortableField)}
        >
            {name}
        </th>
    );
};

const RequestList = props => {
    const dispatch = useDispatch();
    const { onOrderBy, currentOrder, sortable } = props;
    const { orderBy, type } = currentOrder;
    const onHeaderClick = changeOrderBy(currentOrder, onOrderBy);
    const getStatusBars = status => {
        switch (status) {
            case 'pending':
                return 'assets/images/requests/pendingStatus.png';
            case 'confirmed':
                return 'assets/images/requests/confirmedStatus.png';
            case 'upcoming':
                return 'assets/images/requests/upcomingStatus.png';
            case 'payment':
                return 'assets/images/requests/paymentStatus.png';
            case 'reviewing':
                return 'assets/images/requests/reviewingStatus.png';
            case 'finished':
                return 'assets/images/requests/finishedStatus.png';
            case 'canceled':
                return 'assets/images/requests/canceledStatus.png';
            default:
                return status;
        }
    };

    const luxuryGallery = src => ({
        backgroundImage: 'url(/' + getStatusBars(src) + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100% 20px',
        width: 200
    });

    const tableHeaders = [
        { sortableField: 'ur.id', name: 'ID' },
        { sortableField: 'l.name', name: 'Luxury' },
        { sortableField: 'ci.lastName,ci.firstName', name: 'Client name' },
        { sortableField: 'ur.bookingDate', name: 'Booking Date' },
        { sortableField: 'ur.date', name: 'Request Date' },
        { sortableField: 'ur.status', name: 'Status' },
        { sortableField: 'lt.name', name: 'Luxury type' },
        { sortableField: 'ur.deposit', name: 'Deposit' },
        { sortableField: 'ur.totalPrice', name: 'Total price' },
        { sortableField: 'c.commisionValue', name: 'Commision' },
        { sortableField: 'c.isReadyForPayout', name: 'Payout ready' },
        { sortableField: 'c.isAmbassadorPaid', name: 'Paid out' },
        { sortableField: 'ur.requestCode', name: 'Request code' },
    ];

    return (
        <table
            className='table-beseated table-hover table-actions-bar dt-responsive text-left'
            cellSpacing='0'
            width='100%'
            id='datatable'
        >
            <thead>
                <tr>
                    {tableHeaders.map(({ sortableField, name }) => getTableHeader(sortable, type, orderBy, name, sortableField, onHeaderClick))}
                    <th className='hidden-sm'>Action</th>
                </tr>
            </thead>

            <tbody>
                {props.requests.map((item, i) => (
                    <tr key={i}>
                        <td>
                            <b>#{item.id}</b>
                        </td>
                        <td>
                            <h5 className='m-b-0 m-t-0 font-600'>{item.luxury.name}</h5>
                        </td>

                        <td>
                            <Link to={'/members/' + item.client.id}>
                                <h5 className='m-b-0 m-t-0 font-600'>
                                    {item.client.first_name + ' ' + item.client.last_name}
                                </h5>
                            </Link>
                        </td>

                        <td>
                            <h5 className='m-b-0 m-t-0 font-600'>
                                {new Intl.DateTimeFormat('en-US', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                    timeZone: 'Asia/Dubai'
                                }).format(item.booking_date * 1000)}
                            </h5>
                            <h5 className='m-b-0 m-t-0 font-600'>
                                {new Intl.DateTimeFormat('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    timeZone: 'Asia/Dubai'
                                }).format(item.booking_date * 1000)}
                            </h5>
                        </td>

                        <td>
                            <h5 className='m-b-0 m-t-0 font-600'>
                                {new Intl.DateTimeFormat('en-US', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                    timeZone: 'Asia/Dubai'
                                }).format(item.date * 1000)}
                            </h5>
                            <h5 className='m-b-0 m-t-0 font-600'>
                                {new Intl.DateTimeFormat('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    timeZone: 'Asia/Dubai'
                                }).format(item.date * 1000)}
                            </h5>
                        </td>

                        <td style={luxuryGallery(item.status)}>
                            <strong>{item.status}</strong>
                        </td>

                        <td>
                            <strong>{item.luxury.luxury_type_name}</strong>
                        </td>

                        <td>
                            <strong>{item.deposit}</strong>
                        </td>

                        <td>
                            <strong><NumberFormat value={item.total_price} displayType={'text'} thousandSeparator={true} /> {item.luxury.currency}</strong>
                        </td>

                        <td>
                            <strong><NumberFormat value={item.commision.commision_value} displayType={'text'} thousandSeparator={true} /> {item.luxury.currency}</strong>
                        </td>

                        <td>
                            <strong>{item.commision.is_ready_for_payout ? "Yes" : "No"}</strong>
                        </td>

                        <td>
                            <strong>{item.commision.is_ambassador_paid ? "Yes" : "No"}</strong>
                        </td>

                        <td>
                            <strong>{item.code}</strong>
                        </td>


                        <td>
                            <div className='dropdown pull-center'>
                                <button
                                    className='dropdown-toggle card-drop arrow-none valid-anchor'
                                    data-toggle='dropdown'
                                    aria-expanded='false'
                                >
                                    <h3 className='m-0 text-muted'>
                                        <i className='mdi mdi-dots-horizontal'></i>
                                    </h3>
                                </button>
                                <div
                                    className='dropdown-menu dropdown-menu-right'
                                    aria-labelledby='btnGroupDrop1'
                                >
                                    <Link className='dropdown-item' to={'/requests/' + item.id}>
                                        Show
                                    </Link>
                                    {item.commision.commision_value > 0 && !item.commision.is_ambassador_paid && item.commision.is_ready_for_payout ?
                                        <button className='dropdown-item valid-anchor' onClick={e => dispatch(actions.payoutRequest(item.id))}>Payout</button>
                                        : null}
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const propDependsOnAnotherProp = anotherPropName => {
    return (props, propName, componentName) => {
        if (props[anotherPropName] && props[propName] === undefined) {
            return new Error(`${componentName} should have ${propName}`);
        }
    };
};

RequestList.propTypes = {
    sortable: propTypes.bool,
    currentOrder: propTypes.object,
    onOrderBy: propDependsOnAnotherProp('sortable')
}

RequestList.defaultProps = {
    currentOrder: {},
}

export default RequestList;
