import * as actionTypes from './actionTypes';

export const initiateGetAmenities = (managerType) => ({
    type: actionTypes.AMENITY_INITIATE_GET_AMENITIES,
    managerType
});

export const getAmenitiesSuccess = (amenities, totalAmount) => ({
    type: actionTypes.AMENITY_GET_AMENITIES_SUCCESS,
    amenities,
    totalAmount
});