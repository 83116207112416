import * as actionTypes from "./actionTypes";

export const authStart = () => ({
  type: actionTypes.AUTH_START
});

export const authSuccess = token => ({
  type: actionTypes.AUTH_SUCCESS,
  token: token
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error
});

export const authLogout = () => ({
  type: actionTypes.AUTH_INITIATE_LOGOUT
});

export const authLogoutSucceed = () => ({
  type: actionTypes.AUTH_LOGOUT
});

export const checkAuthTimeout = expirationTime => ({
  type: actionTypes.AUTH_CHECK_TIMEOUT,
  expirationTime
});

export const auth = (email, password) => ({
  type: actionTypes.AUTH_USER,
  email,
  password
});

export const authForgotPassword = email => ({
  type: actionTypes.AUTH_FORGOT_PASSWORD,
  email
});

export const authCheckState = () => ({
  type: actionTypes.AUTH_CHECK_STATE
});

export const authInitiateGetUserData = () => ({
  type: actionTypes.AUTH_INITIATE_GET_USER_DATA
});

export const authGetUserData = (userId, username, photoUrl, cardNumber) => ({
  type: actionTypes.AUTH_GET_USER_DATA,
  userId,
  username,
  photoUrl,
  cardNumber
});

export const resetPassword = (token, password) => ({
  type: actionTypes.AUTH_RESET_PASSWORD,
  token,
  password
});

export const remindPasswordFail = error => ({
  type: actionTypes.REMIND_PASSWORD_FAIL,
  error
});

export const remindPasswordSuccess = () => ({
  type: actionTypes.REMIND_PASSWORD_SUCCESS
});

export const remindPasswordTried = () => ({
  type: actionTypes.REMIND_PASSWORD_TRIED
});

export const remindPasswordClearState = error => ({
  type: actionTypes.REMIND_PASSWORD_CLEAR_STATE,
  error
});
