import * as actionTypes from '../actions/actionTypes';

const initialState = {
    members: [],
    totalAmount: false,
    user: {},
    beseatedProfile: [],
};

const getMembers = (state, action) => ({
    ...state,
    members: action.members,
    totalAmount: action.totalAmount
});

const getUserData = (state, action) => ({
    ...state,
    user: action.userData
});

const editBeseatedProfile = (state, action) => {
    const {beseatedProfile} = action;
    return {
        ...state,
        beseatedProfile
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MEMBER_GET_MEMBERS:
            return getMembers(state, action);
        case actionTypes.MEMBER_GET_USER_DATA:
            return getUserData(state, action);
        case actionTypes.MEMBER_EDIT_BESEATED_PROFILE: {
            return editBeseatedProfile(state, action);
        }
        default:
            return state;
    }
};

export default reducer;
