import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import axios from 'axios';

import * as actions from '../../../store/actions';

Modal.setAppElement('#root');

const MemberBox = props => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [locationName, setLocationName] = useState('');

    useEffect(() => {
        if (props.member.area_code) {
            axios.get('https://restcountries.eu/rest/v2/callingcode/' + props.member.area_code)
                .then(response => {
                    setLocationName(response.data[0].name);
                })
                .catch(function (response) {
                    console.log(response);
                });
        }
    }, [props.member.area_code]);

    const IsAmbassador = props => {
        return props.isAmbassador ? <i className="mdi mdi-star-circle member-star text-success" title="ambassador"></i> : null;
    }

    const removeClient = userId => {
        axios.delete(process.env.REACT_APP_API_URL + '/ambassadors/clients/' + userId, {
            headers: {
                "Accept": 'application/json',
                'auth-token': auth.token
            }
        })
            .then(function (response) {
                //handle success
                dispatch(actions.initiateGetMembers());
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });

        setIsModalOpen(false);
    }

    const openModal = () => { setIsModalOpen(true) };
    const closeModal = () => { setIsModalOpen(false) };
    const afterCloseModal = () => { };

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'black',
            width: '50%',
            minWidth: '400px',
            textAlign: 'center',
            fontSize: '30px',
            opacity: '1'
        },
        overlay: {
            zIndex: '99999',
            backgroundColor: 'rgba(3, 3, 3, 0.75)'
        }
    };
    return (
        <div className="col-md-4">
            <div className="text-center card-box">
                <div className="dropdown pull-right">
                    <button className="dropdown-toggle card-drop arrow-none valid-anchor" data-toggle="dropdown" aria-expanded="false">
                        <h3 className="m-0 text-muted"><i className="mdi mdi-dots-horizontal"></i></h3>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                        <Link className="dropdown-item" to={'/members/' + props.member.id}>Show</Link>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="member-card">
                    <div className="thumb-xl member-thumb m-b-10 mx-auto">
                        <img src={props.member.photo_url} alt="" className="thumb-lg rounded-circle" />
                        <IsAmbassador isAmbassador={props.member.is_ambassador} />
                    </div>

                    <div className="">
                        <h4 className="m-b-5 font-18">{props.member.first_name + " " + props.member.last_name}</h4>
                        <h4 className="m-b-5 font-18">{props.member.email}</h4>
                        <p className="text-muted"><i className="fa fa-map-marker"></i> {locationName} <br />
                            <i className="fa fa-birthday-cake"></i> {new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'long', year: 'numeric', timeZone: 'Asia/Dubai' }).format(props.member.birthday * 1000)} </p>
                    </div>

                    <ul className="social-links list-inline m-t-20">
                        <li className="list-inline-item">
                            <a href={"mailto:" + props.member.email} title="" data-placement="top" data-toggle="tooltip" className="tooltips" data-original-title="email"><i className="gold-icons fa fa-envelope-o mt-1 pt-1"></i></a>
                        </li>
                        <li className="list-inline-item">
                            <a href={"tel:" + props.member.phone} title="" data-placement="top" data-toggle="tooltip" className="tooltips" data-original-title="phone"><i className="gold-icons fa fa-phone mt-1 pt-1"></i></a>
                        </li>
                        <li className="list-inline-item" onClick={openModal}>
                            <span className="tooltips"><i className={props.member.is_banned === true ? "gold-icons fa fa-check mt-1 pt-1" : "gold-icons fa fa-remove mt-1 pt-1"}></i></span>
                        </li>
                    </ul>

                    <Modal
                        isOpen={isModalOpen}
                        style={modalStyles}
                        onRequestClose={closeModal}
                        contentLabel="Are you sure?"
                    >
                        <p>Are you sure you want to remove {props.member.first_name}?</p>
                        <button type='submit' className='btn btn-warning' onClick={e => removeClient(props.member.id)}>Yes</button>
                        <button type='submit' className='btn btn-danger ml-2' onClick={e => setIsModalOpen(false)}>Dismiss</button>
                    </Modal>

                    <div className="m-t-20">
                        <div className="row">
                            <div className="col-4">
                                <div className="m-t-20 m-b-10">
                                    <h4 className="m-b-5"><NumberFormat value={props.member.bookings} displayType={'text'} thousandSeparator={true} /></h4>
                                    <p className="m-b-0 text-muted">Bookings</p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="m-t-20">
                                    <h4 className="m-b-5"><NumberFormat value={props.member.money_earned} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h4>
                                    <p className="m-b-0 text-muted">Money earned</p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="m-t-20">
                                    <h4 className="m-b-5"><NumberFormat value={props.member.cancellations} displayType={'text'} thousandSeparator={true} /></h4>
                                    <p className="m-b-0 text-muted">Cancellations</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default MemberBox;