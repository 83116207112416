import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RequestList from '../../components/requestList/requestList';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
//import { addQuery, removeQuery } from '../../utils/utilsRouter';

import * as actions from '../../store/actions';

const filters = {
    status: ['pending', 'confirmed', 'upcoming', 'payment', 'reviewing', 'finished', 'canceled'],
    limit: 15
};

const Dashboard = props => {
    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.member.user);
    const requests = useSelector(state => state.request.requests);
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth.userId) {
            dispatch(actions.initiateGetUserData(auth.userId));
            dispatch(actions.initiateGetRequests(filters, { orderBy: 'ur.id', type: 'DESC' }));
            dispatch(actions.initiateGetMembers());
            dispatch(actions.getBeseatedProfile());
        }
    }, [auth.userId]);

    const imageStyle = {
        height: 130
    };

    return (
        <section>
            <div className='content-page'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='page-title-box'></div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 col-lg-3 pull-center text-center'>
                                <div className='card-box' style={imageStyle}>
                                    <span className='pull-left m-r-15'>
                                        <img
                                            src={user.photo_url}
                                            alt=''
                                            className='thumb-lg rounded-circle'
                                        />
                                    </span>
                                    <div className='media-body'>
                                        <h4 className='m-t-5 m-b-5 font-18 ellipsis'>
                                            {user.first_name + ' ' + user.last_name}
                                        </h4>
                                        <p className='text-muted m-b-0'>
                                            <strong>Invitation Code: {user.invitation_code}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 col-lg-9'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-1'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Money Spent'
                                                >
                                                    Money Spent
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <span data-plugin='counterup'>
                                                        <NumberFormat
                                                            value={user.money_spent}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                        />
                                                    </span>
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-2'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Payments'
                                                >
                                                    Payments
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <span data-plugin='counterup'>
                                                        <NumberFormat
                                                            value={user.number_of_transactions}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                        />
                                                    </span>
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-2'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Bookings'
                                                >
                                                    Bookings
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <NumberFormat
                                                        value={user.total_bookings}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-2'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Loyalty Coins'
                                                >
                                                    Loyalty Coins
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <NumberFormat
                                                        value={user.loyalty_coins}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div
                                    className='card-box'
                                    style={{
                                        overflowY: 'scroll',
                                        maxHeight: 400,
                                        padding: '0px 20px'
                                    }}
                                >
                                    <RequestList requests={requests} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withRouter(Dashboard);
