import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import RequestList from '../../components/requestList/requestList';
import NumberFormat from 'react-number-format';
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer
} from 'recharts';

import * as actions from '../../store/actions';
import PersonalInformation from '../../components/personalInformation/personalInformation';

const getFullName = user => {
    if (!user) {
        return '';
    }
    const {first_name = '', second_name = ''} = user;
    return `${first_name} ${second_name}`;
};

const Profile = props => {
    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.member.user);
    const requests = useSelector(state => state.request.requests);
    const beseatedProfile = useSelector(state => state.member.beseatedProfile);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({
        status: [
            'pending',
            'confirmed',
            'upcoming',
            'payment',
            'reviewing',
            'finished',
            'canceled'
        ],
        limit: 15
    });
    const [profilingChart, setProfilingChart] = useState([]);
    const [profilingChartMaxValue, setProfilingChartMaxValue] = useState(0);

    useEffect(() => {
        if (auth.userId) {
            dispatch(actions.initiateGetUserData(auth.userId));
            dispatch(actions.initiateGetRequests(filters));
            dispatch(actions.initiateGetMembers());
            dispatch(actions.getBeseatedProfile());
        }
    }, []);

    useEffect(() => {
        if (beseatedProfile.length) {
            const beseatedProfileWithNumbers = beseatedProfile.map(e => ({
                ...e,
                value: Number.parseInt(e.value)
            }));
            setProfilingChart(beseatedProfileWithNumbers);
            setProfilingChartMaxValue(Math.max(...beseatedProfileWithNumbers.map(e => e.value)));
        }
    }, [beseatedProfile]);

    const imageStyle = {
        height: 130
    };

    return (
        <section>
            <div className='content-page'>
                {/* Start content */}
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='page-title-box'></div>
                            </div>
                        </div>
                        {/* end row */}

                        <div className='row'>
                            <div className='col-md-12 col-lg-3 pull-center text-center'>
                                <div className='card-box' style={imageStyle}>
                                    <span className='pull-left m-r-15'>
                                        <img
                                            src={user.photo_url}
                                            alt=''
                                            className='thumb-lg rounded-circle'
                                        />
                                    </span>
                                    <div className='media-body'>
                                        <h4 className='m-t-5 m-b-5 font-18 ellipsis'>
                                            {user.first_name + ' ' + user.last_name}
                                        </h4>
                                        <p className='text-muted m-b-0'>
                                            <strong>Invitation Code: {user.invitation_code}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 col-lg-9'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-1'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Money Spent'
                                                >
                                                    Money Spent
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <span data-plugin='counterup'>
                                                        <NumberFormat
                                                            value={user.money_spent}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                        />
                                                    </span>
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                    {/* end col */}

                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-2'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Payments'
                                                >
                                                    Payments
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <span data-plugin='counterup'>
                                                        <NumberFormat
                                                            value={user.number_of_transactions}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                        />
                                                    </span>
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                    {/* end col */}

                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-2'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Bookings'
                                                >
                                                    Bookings
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <NumberFormat
                                                        value={user.total_bookings}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                    {/* end col */}

                                    <div className='col-sm-3'>
                                        <div
                                            className='card-box widget-box-four'
                                            style={imageStyle}
                                        >
                                            <div
                                                id='dashboard-2'
                                                className='widget-box-four-chart'
                                            ></div>
                                            <div className='pull-left'>
                                                <h4
                                                    className='m-t-0 font-16 m-b-5 text-overflow'
                                                    title='Loyalty Coins'
                                                >
                                                    Loyalty Coins
                                                </h4>
                                                <h3 className='m-b-0 m-t-20'>
                                                    {' '}
                                                    <NumberFormat
                                                        value={user.loyalty_coins}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </h3>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                    {/* end col */}
                                </div>
                                {/* end row */}
                            </div>
                            {/* end col */}
                        </div>
                        {/* end row */}

                        <div className='row'>
                            <div className='col-md-12 col-lg-6'>
                                <PersonalInformation user={user} />
                            </div>

                            <div className='col-md-12 col-lg-6'>
                                <div
                                    className='card-box'
                                    style={{height: 350, overflowWrap: 'break-word'}}
                                >
                                    <h4 className='font-18 text-overflow'>Profiling Chart</h4>
                                    <ResponsiveContainer>
                                        <RadarChart
                                            data={profilingChart}
                                            margin={{top: 0, right: 0, left: 0, bottom: 10}}
                                        >
                                            <PolarGrid />
                                            <PolarAngleAxis dataKey='luxury' />
                                            <PolarRadiusAxis domain={[0, profilingChartMaxValue]} />
                                            <Radar
                                                name={getFullName(user)}
                                                dataKey='value'
                                                stroke='#8884d8'
                                                fill='#B28F28'
                                                fillOpacity={0.6}
                                            />
                                            
                                        </RadarChart>
                                    </ResponsiveContainer>
                                    <div className='chart-container'>
                                        <div
                                            className=''
                                            style={{height: 300}}
                                            id='beseated_profile_chart'
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end row */}

                        <div className='row'>
                            <div className='col-12'>
                                <div
                                    className='card-box'
                                    style={{
                                        overflowY: 'scroll',
                                        maxHeight: 400,
                                        padding: '0px 20px'
                                    }}
                                >
                                    <RequestList requests={requests} />
                                    {/*<RequestList requests={requests.filter(item => item.client.id !== auth.userId)} />*/}
                                </div>
                            </div>
                        </div>
                        {/* end row */}
                    </div>
                    {/* container */}
                </div>
                {/* content */}
            </div>
        </section>
    );
};

export default Profile;
