import * as actionTypes from '../actions/actionTypes';

const initialState = {
  requests: [],
  totalAmount: false
};

const getRequests = (state, action) => ({
  ...state,
  requests: action.requests,
  totalAmount: action.totalAmount
});

const sendRequestSuccess = (state,action) => ({
  ...state,
  request: {
    id: action.id
  }
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_GET_REQUESTS:
      return getRequests(state, action);
    case actionTypes.REQUEST_SEND_REQUEST_SUCCESS:
      return sendRequestSuccess(state,action);
    default:
      return state;
  }
};

export default reducer;