import * as actionTypes from '../actions/actionTypes';

const initialState = {
    locations: [],
    totalAmount: false
};

const getLocationsFail = (state, action) => ({
    ...state,
    error: action.error
});

const getLocationsSuccess = (state, action) => ({
    ...state,
    locations: action.locations
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOCATION_GET_LOCATIONS_FAIL:
            return getLocationsFail(state, action);
        case actionTypes.LOCATION_GET_LOCATIONS_SUCCESS:
            return getLocationsSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;