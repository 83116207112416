import React, { useState } from 'react';
import ExistingClient from './existingClient/existingClient';
import NewClient from './newClient/newClient';
import Guest from './guest/guest';

import './clientForm.css';

const GUEST = "guest";
const NEW_MEMBER = "new_member";
const EXISTING_MEMBER = "existing_member";

const ClientForm = props => {
    const [selectedMemberType, setSelectedMemberType] = useState("guest");

    const FormPicker = () => {
        switch(selectedMemberType) {
            case GUEST: return <Guest />
            case NEW_MEMBER: return <NewClient />
            case EXISTING_MEMBER: return <ExistingClient />
            default: return <NewClient />
        }
    }

    return (
        <React.Fragment>
            <div className="form-group row mb-5 client-type-buttons-row pt-3">
                <button
                    className={selectedMemberType === GUEST ? "btn-so col-md-4" : "btn-nso col-md-4"}
                    type="button"
                    onClick={e => setSelectedMemberType(GUEST)}>
                    GUEST
                </button>

                <button
                    className={selectedMemberType === NEW_MEMBER ? "btn-so col-md-4" : "btn-nso col-md-4"}
                    type="button"
                    onClick={e => setSelectedMemberType(NEW_MEMBER)}>
                    NEW CLIENT
                </button>

                <button
                    className={selectedMemberType === EXISTING_MEMBER ? "btn-so col-md-4" : "btn-nso col-md-4"}
                    type="button"
                    onClick={e => setSelectedMemberType(EXISTING_MEMBER)}>
                    EXISTING CLIENT
                </button>
            </div>

            <FormPicker />

            <div className="form-group row">
                <div className="col-md-12 col-lg-12 input-container">
                    <label id="notes-label" htmlFor="notes">notes</label>
                    <textarea id="notes" className="form-control text-left p-3" placeholder="Special requests" name="notes"></textarea>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ClientForm;