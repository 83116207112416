import {put, select} from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions';

export function* memberGetMembersSaga(action) {
    const state = yield select();
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + '/ambassadors/clients/list', {
            headers: {
                Accept: 'application/json',
                'auth-token': state.auth.token
            }
        });
        yield put(actions.getMembers(res.data._embedded.list, res.data._embedded.size));
    } catch (err) {
        put(actions.authFail(err.response.data.error));
    }
}

export function* memberGetUserDataSaga(action) {
    const state = yield select();
    try {
        const res = yield axios.get(
            process.env.REACT_APP_API_URL + '/users/getUserData/' + action.userId,
            {
                headers: {
                    Accept: 'application/json',
                    'auth-token': state.auth.token
                }
            }
        );
        const name = `${res.data.first_name} ${res.data.last_name}`;
        const email = `${res.data.email}`;
        const isIntercomOpen = localStorage.getItem('intercomOpen');
        if (!isIntercomOpen) {
            localStorage.setItem('intercomOpen', true);
            window.intercomSettings = { ...window.intercomSettings, name, email, "hide_default_launcher": false};
            window.Intercom('update', window.intercomSettings);
        }
        localStorage.setItem('name', name)
        localStorage.setItem('email', email)
        yield put(actions.getUserData(res.data));
    } catch (err) {
        put(actions.authFail(err.response.data.error));
    }
}

export function* memberEditUserSaga(action) {
    const state = yield select();
    const data = new FormData();
    const [firstName, lastName] = action.fullName.split(' ');
    data.append('first_name', firstName);
    data.append('last_name', lastName);
    data.append('email', action.email);
    data.append('phone', action.phone);
    data.append('area_code', action.areaCode);
    data.append('show_big_spender', state.member.user.privacy.show_big_spender);
    data.append('show_friends_only', state.member.user.privacy.show_friends_only);
    data.append('birthday', state.member.user.birthday);

    try {
        const res = yield axios.post(process.env.REACT_APP_API_URL + '/users/updateInfo', data, {
            headers: {
                Accept: 'application/json',
                'auth-token': state.auth.token
            }
        });

        if (res.data.success === true) {
            yield put(actions.authInitiateGetUserData());
        } else {
            put(actions.addToasts([{message:"There was problem with saving, try later", type:"error"}]));
        }
    } catch (err) {
        put(actions.addToasts([{message:err.response.data.error, type:"error"}]));
    }
}

export function* memberGetBeseatedProfile(action) {
    const state = yield select();
    const {token: auth_token, userId} = state.auth;
    try {
        const res = yield axios.get(process.env.REACT_APP_API_URL + `/users/getBeseatedProfile/${userId}`, {
            headers: {
                Accept: 'application/json',
                'auth-token': auth_token,
            }
        });
        yield put(actions.editBeseatedProfile(res.data));
    } catch (err) {
        console.log(err);
    }
}
