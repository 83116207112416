import React, { useEffect } from 'react';
import "./conciergeLuxuriesList.css";
import { useSelector, useDispatch } from 'react-redux';

import isInTheDay from '../../utils/dateUtils';
import Luxury from './luxury/luxury';
import * as actions from '../../store/actions';

const getLuxuries = (props, luxuries, luxuriesLoaded, selectedDate, handleScroll) => {
    if (luxuriesLoaded && !luxuries.length) {
        return (<p className="luxuries-dont-found">We did not find any results for your search. Please try again or re-adjust your filters</p>);
    }
    const {type} = props;
    if (type === 'tickets') {
        return (
            <div className="row luxury-gallery-scroll" onScroll={handleScroll}>
                {luxuries.filter(item => selectedDate ? isInTheDay(item.manager_info.date, selectedDate) === true : true).map(luxury => <Luxury key={luxury.id} luxury={luxury} />)}
            </div>
        )
    }
    return (
        <div className="row luxury-gallery-scroll" onScroll={handleScroll}>
            {luxuries.map(luxury => <Luxury key={luxury.id} luxury={luxury} />)}
        </div>
    )
}

const ConciergeLuxuriesList = props => {
    const luxuries = useSelector(state => state.luxury.luxuries);
    const totalAmount = useSelector(state => state.luxury.totalAmount);
    const selectedDate = useSelector(state => state.concierge.selectedDate);
    const luxuriesLoaded = useSelector(state => state.luxury.luxuriesLoaded);
    const dispatch = useDispatch();

    useEffect(props => {
        dispatch(actions.clearLuxuriesList());
    }, [dispatch]);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) dispatch(actions.initiateGetMoreLuxuriesForConcierge());
    };

    return (
        <React.Fragment>
            {/*
            <div className="row">
                {totalAmount === 0 ? <p className="col-lg-12 text-center title-available text-uppercase">NO {props.type} AVAILABLE</p> : null}
                {(totalAmount !== false && totalAmount !== 0) ? 
                <p className="col-lg-12 text-center title-available text-uppercase">
                    {props.type === 'tickets' ? luxuries.filter(item => selectedDate ? isInTheDay(item.manager_info.date, selectedDate) === true : true).length : totalAmount} {props.type} AVAILABLE</p> : null}
                </div>*/}
            {getLuxuries(props, luxuries, luxuriesLoaded, selectedDate, handleScroll)}
        </React.Fragment>
    );
}

export default ConciergeLuxuriesList;