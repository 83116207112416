import React, { useEffect, useState } from 'react';
import {
    injectStripe,
    IbanElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from 'react-stripe-elements';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import './checkoutForm.css';

import * as actions from '../../store/actions';
import BankAccountElement from './bankAccountElement/bankAccountElement';
import updateLabelUtils from '../../utils/updateLabelUtils';

const CheckoutForm = props => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [clientSecret, setClientSecret] = useState('');
    const [isAddingCard, setIsAddingCard] = useState(1);
    const [accountHolder, setAccountHolder] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasErrorWhileSubmiting, setHasErrorWhileSubmiting] = useState(false);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + '/users/cards/getClientSecret', {
                headers: {
                    Accept: 'application/json',
                    'auth-token': auth.token
                }
            })
            .then(function (response) {
                setClientSecret(response.data);
            })
            .catch(function (response) {
                console.log(response);
            });
    }, [auth]);

    async function submit(ev) {
        setIsSubmitting(true);
        let { token } = await props.stripe.createToken({ name: accountHolder });
        if (!token) {
            setIsSubmitting(false);
            dispatch(actions.addToasts([{message:"Something went wrong, contact support", type:"error"}]));
            return -1;
        }

        const data = new FormData();
        data.append('token_id', token.id);

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/users/cards', data, {
                headers: {
                    Accept: 'application/json',
                    'auth-token': auth.token
                }
            });
            if (response.data.success) {
                dispatch(actions.addToasts([{message:"Succesfully added card", type:"success"}]));
                //dispatch(actions.initiateGetCards());
                //dispatch(actions.initiateGetStripeCustomer());
            }
            setIsSubmitting(false);
        } catch (e) {
            setHasErrorWhileSubmiting(true);
        }
    }

    async function submitAccount(ev) {
        setIsSubmitting(true);
        let { token } = await props.stripe.createToken({ name: accountHolder, currency: 'EUR' });
        if (!token) {
            setIsSubmitting(false);
            dispatch(actions.addToasts([{message:"Something went wrong, contact support", type:"error"}]));
            return -1;
        }

        const data = new FormData();
        data.append('token_id', token.id);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/users/accounts', data, {
                headers: {
                    Accept: 'application/json',
                    'auth-token': auth.token
                }
            });

            if (response.data.success) {
                dispatch(actions.addToasts([{message:"Succesfully added bank account", type:"success"}]));
                //dispatch(actions.initiateGetBankAccounts());
                //dispatch(actions.initiateGetStripeCustomer());
            } else {
                console.log(response);
            }
            setIsSubmitting(false);
        } catch (e) {
            setHasErrorWhileSubmiting(true);
        }
    }

    const onDataChange = e => {
        updateLabelUtils(e);
        if (!hasErrorWhileSubmiting && !isSubmitting) {
            return;
        }
        setIsSubmitting(false);
    };

    const onAccountHolderDataChange = e => {
        setAccountHolder(e.target.value);
        onDataChange(e);
    };

    return (
        <div className='form-style-8'>
            <div className='form-group row mb-2 client-type-buttons-row pt-3'>
                <button
                    className={isAddingCard ? 'btn-so col-md-6' : 'btn-nso col-md-6'}
                    type='button'
                    onClick={e => setIsAddingCard(true)}
                >
                    ADD CARD
                </button>

                {/*
                <button
                    className={!isAddingCard ? 'btn-so col-md-6' : 'btn-nso col-md-6'}
                    type='button'
                    onClick={e => setIsAddingCard(false)}
                >
                    ADD ACCOUNT
                </button>*/}
            </div>

            <div className='row'>
                {isAddingCard ? (
                    <div className='checkout col-md-12 col-lg-6 form-style-8'>
                        <p className='block-title'>
                            Add more credit cards by filling the following information
                        </p>

                        <div className='form-group row pt-2'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12 input-container'>
                                        <label id='accountHolder-label' htmlFor='accountHolder'>
                                            account holder
                                        </label>
                                        <input
                                            className='form-control text-left'
                                            type='text'
                                            placeholder='account holder'
                                            id='accountHolder'
                                            name='account_holder'
                                            value={accountHolder}
                                            required="1"
                                            onChange={onAccountHolderDataChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-group row pt-2'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12 input-container'>
                                        <label id='cardNumber-label' htmlFor='cardNumber'>
                                            card number
                                        </label>
                                        <CardNumberElement
                                            className='form-control-stripe text-left'
                                            onChange={onDataChange}
                                            style={{
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#29abe2',
                                                    'InputElement::placeholder': { color: '#29abe2' }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className='form-group-title pb-2 pt-5'>expiration date</p>
                        <div className='form-group row'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-5 input-container'>
                                        <label id='cardExpiry-label' htmlFor='cardExpiry'>
                                            MM/YY
                                        </label>
                                        <CardExpiryElement
                                            onChange={onDataChange}
                                            className='form-control-stripe text-left'
                                            style={{
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#29abe2',
                                                    'InputElement::placeholder': { color: '#29abe2' }
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className='col-md-12 col-lg-5 offset-lg-2 input-container'>
                                        <label id='cardCvc-label' htmlFor='cardCvc'>
                                            CVC
                                        </label>
                                        <CardCvcElement
                                            onChange={onDataChange}
                                            className='form-control-stripe text-left'
                                            style={{
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#29abe2',
                                                    'InputElement::placeholder': { color: '#29abe2' }
                                                }
                                            }}
                                        />
                                        <p className='col-md-12'>(3 digit code on back of card)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type='button'
                            className='btn btn-submit col-md-12 text-capitalize mt-3'
                            data-secret={clientSecret}
                            onClick={submit}
                            disabled={isSubmitting || !accountHolder}
                        >
                            save card
                        </button>
                    </div>
                ) : null}

                {!isAddingCard ? (
                    <form className='checkout col-md-12 col-lg-6 form-style-8'>
                        <p className='block-title'>
                            Enter a valid bank account. The first payout usually takes longer than
                            the rest
                        </p>
                        <BankAccountElement iban={IbanElement} onChange={onDataChange} />
                        <p className='pt-2'>
                            By registering your account, you agree to our{' '}
                            <a href='https://www.beseated.app/terms'>Services Agreement</a> and the{' '}
                            <a target='_blank' rel="noopener noreferrer" href='https://stripe.com/connect-account/legal'>
                                Stripe Connected Account Agreement
                            </a>
                            .
                        </p>
                        <button
                            type='button'
                            className='btn btn-submit col-md-12 text-capitalize mt-3'
                            data-secret={clientSecret}
                            onClick={submitAccount}
                            disabled={isSubmitting}
                        >
                            save account
                        </button>
                    </form>
                ) : null}
            </div>
        </div>
    );
};

export default injectStripe(CheckoutForm);
